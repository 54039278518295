const ModalWrapper = ({ children }) => {
  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    zIndex: '99999',
    outline: 'none',
    transition: 'all 1000ms ease',
    backdropFilter: 'blur(2px)',
  };

  const contentStyles = {
    position: 'relative',
    width: 'auto',
    margin: '6px auto',
    maxWidth: '99%',
  };

  const innerContentStyles = {
    border: 'none',
    borderRadius: '16px',
    boxShadow:
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    outline: 'none',
    padding: '3px 0',
    margin: '0 30px',
    // paddingBottom: '3px',
  };

  return (
    <div style={modalStyles}>
      <div style={contentStyles}>
        <div style={innerContentStyles}>{children}</div>
      </div>
    </div>
  );
};

export default ModalWrapper;
