import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import img1 from '../pics/img4.png';
import './css/header.css';

function Header({ backgroundColor, opacity, marginBottom }) {
  return (
        <Navbar
          className="mt-1 vra-navbar"
          data-bs-theme="dark"
          style={{ backgroundColor: backgroundColor }}
        >
          <Container>
            <Col xl={10}>
              <Navbar.Brand
                as={Link}
                to="/"
                className="nav-link"
                style={{ marginLeft: '20px'}}
              >
                <img
                  src={img1}
                  className="d-inline-block align-top nav-logo"
                  alt="Your Logo"
                />
              </Navbar.Brand>
            </Col>
            <Col xl={2}>
              <Nav className="me-auto">
                <Nav.Item>
                  <Link
                    to="/login"
                    className="nav-link vra-nav-link"
                  >
                    Login
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <Link
                    to="/Signup"
                    className="nav-link vra-nav-link"
                  >
                    Sign up
                  </Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Container>
        </Navbar>
  );
}

export default Header;
