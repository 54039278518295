import { useState, useEffect } from 'react';
import { signal } from '@preact/signals-core';

const userInfo = signal(null);

export default () => {
  const [state, setState] = useState(userInfo.peek());

  useEffect(() => {
    const unsub = userInfo.subscribe(setState);
    return () => unsub();
  }, []);

  return state;
};

export const setUserInfo = (updatedValues) => {
  // Get the current user info
  const currentUserInfo = userInfo.peek();
  // Merge the current user info with the updated values
  const newUserInfo = { ...currentUserInfo, ...updatedValues };
  // Set the merged user info
  userInfo.value = newUserInfo;
};

export const clearUserInfo = () => (userInfo.value = null);

export const peekUserInfo = () => userInfo.peek();
