import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useState } from "react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Settings = ({ calculatorHandler, setQueryModalOpen }) => {
  const [file, setFile] = useState(null);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flex: 1,
        gap: 10,
        padding: "10px 20px",
      }}
    >
      <div
        style={{
          width: "80%",
          padding: "10px 10px",
          fontWeight: "bold",
          color: "#17256E",
          fontSize: 15,
          marginTop: 20,
          textAlign: "center",
        }}
      >
        Select Folder for Output
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 10,
        }}
        class="browse-button-wrap"
      >
        <input
          style={{
            width: "80%",
            padding: "10px 15px",
            borderRadius: "5px",
            border: "1px solid #CBCBCB",
            outline: "none",
          }}
          value={file}
          disabled
          placeholder="Select Folder"
        />
        <Button
          component="label"
          variant=""
          size="small"
          style={{
            backgroundColor: "rgb(23, 37, 110)",
            opacity: 0.9,
            color: "white",
          }}
        >
          Browse
          <VisuallyHiddenInput
            type="file"
            onChange={(e) => {
              setFile(e.target.value);
            }}
          />
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 50,
          marginTop: "40px",
        }}
      >
        <button
          style={{
            backgroundColor: "#CBCBCB",
            color: "black",
            border: "none",
            borderRadius: "5px",
            padding: "10px 20px",
            marginBottom: "10px",
            cursor: "pointer",
          }}
          className="select-tool-button"
          onClick={calculatorHandler}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default Settings;
