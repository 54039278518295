import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Button } from "react-bootstrap";
import ModalWrapper from "./ModalProvider";
import { api_admin_add_animal } from "../api";

const useStyles = makeStyles((theme) => ({
  userBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 30px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  updateBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "220px",
    padding: "8px 10px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  cancelBtn: {
    textTransform: "none",
    borderRadius: "10px",
    color: "#17256e",
    backgroundColor: "#f2f2f2",
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59",
    },
    width: "fit-content",
    padding: "8px 87px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  input: {
    borderRadius: "10px",
    padding: "6px 10px",
    width: "210px",
    height: "40px",
    outline: "none",
    border: "none",
    background: "#eee",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
}));

export default function AddNewAnimalForm({
  setAddUser: setAddAnimal,
  setConfirmAddUser: setConfirmAddAnimal,
}) {
  const classes = useStyles();
  const [animalData, setAnimalData] = useState({
    name: "",
    length: 0,
    beam: 0,
    velocity_ne: 0,
    velocity_ma: 0,
    velocity_se: 0,
  });

  const handleAddUser = () => {
    api_admin_add_animal(animalData)
      .then((res) => {
        if (res.data.code === 200) {
          // Close the add user form
          setAddAnimal(false);
          setConfirmAddAnimal(true);
        }
      })
      .catch((err) => {
        console.log("Something went wrong");
      });
  };

  return (
    <ModalWrapper>
      <div
        style={{
          display: "flex",
          gap: "4px",
          padding: "0px 30px",
          marginTop: "10px",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", right: "-30px", top: "-20px" }}>
          <img src="/add-new-user-dophine.png" alt="dophine" />
        </div>
        <img
          src="/user-remove.png"
          alt="delete"
          style={{ height: "30px", width: "30px", display: "none" }}
        />
        <p
          style={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#17256E",
            marginTop: "3px",
            marginLeft: "38px",
          }}
        >
          Add Animal
        </p>
      </div>
      <div style={{ width: "100%" }}>
        <img src="/divider.png" alt="divider" />
      </div>
      <div style={{ padding: "10px 70px" }} className="add-user-modal-wrap">
        <p style={{ fontSize: "14px", fontWeight: "500", maxWidth: "80%" }}>
          Please enter animal name below to add a new animal to the portal.
        </p>
      </div>
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: "20px",
          padding: "0 70px",
          paddingBottom: "5px",
        }}
        justifyContent="space-between"
        className="input-x-left-wrap"
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
          className="input-full-wrap"
        >
          <div class="w-100">
            <div
              variant="subtitle1"
              className="vra-label"
            >
              Animal Name
            </div>
            <input
              type="text"
              placeholder="Sperm Whale"
              className={classes.input + ' vra-modal-input'} onChange={(e) => {
                setAnimalData({ ...animalData, name: e.target.value });
              }}
            />
          </div>
          <div class="w-100">
            <div
              variant="subtitle1"
              className="vra-label"
            >
              Length
            </div>
            <input
              type="number"
              placeholder="15.0"
              className={classes.input + ' vra-modal-input'} onChange={(e) => {
                setAnimalData({
                  ...animalData,
                  length: parseFloat(e.target.value),
                });
              }}
            />
          </div>
        </div>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: "20px",
          padding: "0 70px",
          paddingBottom: "5px",
        }}
        justifyContent="space-between"
        className="input-x-left-wrap"
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
          className="input-full-wrap"
        >
          <div class="w-100">
            <div
              variant="subtitle1"
              className="vra-label"
            >
              Beam
            </div>
            <input
              type="number"
              placeholder="3.0"
              className={classes.input + ' vra-modal-input'} onChange={(e) => {
                setAnimalData({
                  ...animalData,
                  beam: parseFloat(e.target.value),
                });
              }}
            />
          </div>
          <div class="w-100">
            <div
              variant="subtitle1"
              className="vra-label"
            >
              Velocity Ne
            </div>
            <input
              type="number"
              placeholder="1.7"
              className={classes.input + ' vra-modal-input'} onChange={(e) => {
                setAnimalData({
                  ...animalData,
                  velocity_ne: parseFloat(e.target.value),
                });
              }}
            />
          </div>
        </div>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: "20px",
          padding: "0 70px",
          paddingBottom: "5px",
        }}
        className="input-x-left-wrap"
        justifyContent="space-between"
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
          className="input-full-wrap"
        >
          <div class="w-100">
            <div
              variant="subtitle1"
              className="vra-label"
            >
              Velocity Ma
            </div>
            <input
              type="number"
              placeholder="1.7"
              className={classes.input + ' vra-modal-input'} onChange={(e) => {
                setAnimalData({
                  ...animalData,
                  velocity_ma: parseFloat(e.target.value),
                });
              }}
            />
          </div>
          <div class="w-100">
            <div
              variant="subtitle1"
              className="vra-label"
            >
              Velocity Se
            </div>
            <input
              type="number"
              placeholder="1.7"
              className={classes.input + ' vra-modal-input'} onChange={(e) => {
                setAnimalData({
                  ...animalData,
                  velocity_se: parseFloat(e.target.value),
                });
              }}
            />
          </div>
        </div>
      </Grid>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "25px",
          marginBottom: "30px",
          marginTop: "30px",
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="contained"
          className={classes.updateBtn + " vra-modal-btn"}
          style={{ maxWidth: "220px", height: "45px" }}
          onClick={() => {
            setAddAnimal(false);
            setConfirmAddAnimal(true);
            handleAddUser();
          }}
        >
          Add Animal
        </Button>
        <Button
          variant="contained"
          className={classes.cancelBtn + " vra-modal-btn"}
          style={{ maxWidth: "220px", height: "45px" }}
          onClick={() => setAddAnimal(false)}
        >
          Cancel
        </Button>
      </div>
    </ModalWrapper>
  );
}
