import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Navbar';
import img1 from '../pics/img5.jpg';
import { api_signup_verify } from '../api';
import '../components/css/form.css';


export default function VerificationCode() {
  const divStyle = {
    backgroundImage: `url(${img1})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100vh',
  };
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [otpCode, setOtpCode] = useState('');
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const uid = queryParams.get('u');
    if (uid) {
      setUserId(uid);
    }
  }, [location.search]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErr('');

    if (!userId) {
      setErr('Please enter uid!');
      setLoading(false);
      return;
    }
    api_signup_verify(userId)
      .then((res) => {
        if (res.data.code === 200) {
          setLoading(false);
          navigate('/login');
          toast.success("Account verified successfully!")
        }
      })
      .catch((err) => {
        setErr(err?.response?.data?.message);
        setLoading(false);
        console.log('err : ', err);
      });
  };

  useEffect(() => {
    // const signupUserCreds = JSON.parse(localStorage.getItem('userSignupCreds'));
    // setUserData(signupUserCreds);
  }, []);

  return (
    <div style={divStyle}>
      <Container>
        <Header backgroundColor="#17256E" />
        <Card
          className='form-card'
        >
          <Card.Body className='form-card-body'
          >
            <Card.Title
              className="text-center form-card-title"
            >
              Verification Code
            </Card.Title>
            <div className='form-p-wrap'>
              <p
                className='form-p-text'
              >
                To start using VRA, we need to verify your email address. A
                verification has been sent to your email. Please enter the code
                below to verify.
              </p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mt-4" controlId="formBasicFirstName">
                <Form.Label
                  className='form-label'
                >
                  Enter Code
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Code"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  className='form-input'
                />
              </Form.Group>
              {err && (
                <p
                  style={{
                    color: '#DC2626',
                    marginTop: '10px',
                    textAlign: 'center',
                  }}
                >
                  {err}
                </p>
              )}
              <Row className="justify-content-center">
                <Col xs="auto">
                  <Button
                    className='form-button'
                    style={{
                      background: !loading ? '#17256E' : '#58639d',
                      fontStyle: loading ? 'italic' : '',
                    }}
                    type="submit"
                  >
                    <span style={{ color: '#FDFDFE' }}>
                      {loading ? 'Verifying...' : 'Verify'}
                    </span>
                  </Button>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs="auto">
                  <p
                    className='form-end-label'
                  >
                    <strong>Resend Code</strong>
                  </p>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <ToastContainer />
      </Container>
    </div>
  );
}
