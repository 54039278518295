import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { ToastContainer, toast } from "react-toastify";
import { FiEye, FiEyeOff } from "react-icons/fi";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useLocation hook
import Header from "../components/Navbar";
import img1 from "../pics/img5.jpg";
import { api_change_password } from "../api";

export default function ResetPassword() {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const divStyle = {
    backgroundImage: `url(${img1})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
  };
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetPwToken, setResetPwToken] = useState("");
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const reset_pw_token = queryParams.get("reset_pw_token");
    if (reset_pw_token) {
      setResetPwToken(reset_pw_token);
    }
  }, [location.search]);

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const res = JSON.parse(localStorage.getItem("encodedEmail"));
    const decryptedEmail = atob(res);
    if (!decryptedEmail) {
      toast("You are not allowed to change password!", {
        position: "bottom-right",
        theme: "light",
      });
      setLoading(false);
      return;
    }

    // Check if any field is empty
    if (formData.password !== formData.confirmPassword) {
      toast("Password does not match", {
        position: "bottom-right",
        theme: "light",
      });
      setLoading(false);
      return;
    }
    formData.email = decryptedEmail;
    if (Object.values(formData).some((field) => field === "")) {
      toast("Please fill in all fields.", {
        position: "bottom-right",
        theme: "light",
      });
      setLoading(false);
      return;
    }
    // Check is password is meeting criteria.
    if (!passwordRegex.test(formData.password)) {
      toast.error("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character (@$!%*?&)", {
        position: "bottom-right",
        theme: "light",
      });
      setLoading(false);
      return
    }
    api_change_password(resetPwToken, {
      password: formData.password,
    })
    .then((res) => {
      if (res.data.code === 200) {
        toast.success("Password updated successfully!", {
          position: "bottom-right",
          theme: "light",
        })
        navigate("/login");
        setLoading(false);
      }
    })
      .catch((err) => {
        console.log("err : ", err);
        toast.error(err.message, {
          position: "bottom-right",
          theme: "light",
        });
        setLoading(false);
      });
  };

  return (
    <div style={divStyle}>
      <Container>
        <Header backgroundColor="#17256E" />
        <Card
          className="form-card"
        >
          <Card.Body className="form-card-body">
            <Card.Title
              className="text-center form-card-title"
            >
              Reset Password
            </Card.Title>
            <div className='form-p-wrap'
            >
              <p
                className='form-p-text'
              >
                Enter a new password for your account. Your password must be
                different from the previous password you used.
              </p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mt-4" controlId="formBasicPassword">
                <Form.Label
                  className='form-label'
                >
                  Enter New Password
                </Form.Label>
                <div style={{ position: "relative", flex: 2 }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="**********"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className='form-input'
                  />
                  <span
                    onClick={() => togglePasswordVisibility("password")}
                    className='form-input-eye'
                  >
                    {showPassword ? (
                      <FiEyeOff style={{ color: "#0A1967" }} />
                    ) : (
                      <FiEye style={{ color: "#0A1967" }} />
                    )}
                  </span>
                </div>
              </Form.Group>

              <Form.Group className="mt-4" controlId="formBasicConfirmPassword">
                <Form.Label
                className='form-label'
                >
                  Confirm New Password
                </Form.Label>
                <div style={{ position: "relative", flex: 2 }}>
                  <Form.Control
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="**********"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className='form-input'
                  />
                  <span
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                    className='form-input-eye'
                  >
                    {showConfirmPassword ? (
                      <FiEyeOff style={{ color: "#0A1967" }} />
                    ) : (
                      <FiEye style={{ color: "#0A1967" }} />
                    )}
                  </span>
                </div>
              </Form.Group>

              <Row className="justify-content-center">
                <Col xs="auto">
                  <Button
                    type="submit"
                    style={{
                      background: !loading ? "#17256E" : "#58639d",
                      fontStyle: loading ? "italic" : "",
                      boxShadow: "0px 4px 4px 0px #00000040",
                    }}
                    className='form-button'
                  >
                    <span style={{ color: "#FDFDFE" }}>
                      {loading ? "Resetting..." : "Reset"}
                    </span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <ToastContainer />
      </Container>
    </div>
  );
}
