import React, { useContext, useEffect, useState } from "react";
import { tableInfo } from "./tableInfo";
import Top from "./Top";
import InfoModal from "../InfoModal";
import AnimallPerameterModal from "../AnimallPerameterModal";
import { useAuth } from "../../Provider";
import { calc } from "@chakra-ui/react";
import { toast, ToastContainer } from "react-toastify";
import {
  api_add_daily_transit,
  api_add_monthly_transit,
  api_get_animals,
  api_get_lease_areas_names,
  api_get_vessel_categories,
  api_get_vessels,
} from "../../../api";
import { CircularProgress } from "@mui/material";

const VesselParameter = ({
  close,
  projectName,
  selected,
  distributionPoints,
}) => {
  const { calculatorHandler } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [animalModal, setAnimalModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [data, setData] = useState([]);
  const [leaseAreasNames, setLeaseAreasNames] = useState([]);
  const [vesselCategories, setVesselCategories] = useState([]);
  // const [aversion, setAversion] = useState(
  //   new Array(vesselCategories.length).fill(0)
  // );
  const [categoriesToRemove, setCategoriesToRemove] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [selections, setSelections] = useState({
    name: projectName,
    month: "",
    specie: "",
    lease_area: "",
    vesselCategorySelections: [], // Array to store the selections for each vessel category
  });

  // Handle onChange for the month, specie, and lease_area
  const handleGlobalChange = (field, value) => {
    setSelections((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle the vessel category selection
  const handleCategoryChange = (vcId, field, value) => {
    // Update the specific slider's value based on its index
    // setAversion((prevAversions) =>
    //   prevAversions.map((aversion, i) =>
    //     i === vcId ? Number(value) : aversion
    //   )
    // );

    setSelections((prev) => {
      const existingCategory = prev.vesselCategorySelections.find(
        (sel) => sel.vessel_category_id === vcId
      );

      let updatedVesselCategorySelections;

      // If the vessel category already exists, update the relevant field
      if (existingCategory) {
        updatedVesselCategorySelections = prev.vesselCategorySelections.map(
          (sel) =>
            sel.vessel_category_id === vcId ? { ...sel, [field]: value } : sel
        );
      } else {
        // If it doesn't exist, add a new entry with the vessel_category_id and the provided field value
        updatedVesselCategorySelections = [
          ...prev.vesselCategorySelections,
          { vessel_category_id: vcId, [field]: value },
        ];
      }

      return {
        ...prev,
        vesselCategorySelections: updatedVesselCategorySelections,
      };
    });

    // Remove the ID from `categoriesToRemove` if the category is checked again
    setCategoriesToRemove((prev) => prev.filter((id) => id !== vcId));

    console.log("selection", selections);
  };

  const handleCategoryUncheck = (vcId) => {
    setCategoriesToRemove((prev) => {
      // Ensure the ID is only added once and avoid duplicates
      if (!prev.includes(vcId)) {
        return [...prev, vcId];
      }
      return prev;
    });
  };

  // Add new vessel category selection
  // const addVesselCategorySelection = (vcId) => {
  //   setSelections((prev) => ({
  //     ...prev,
  //     vesselCategorySelections: [
  //       ...prev.vesselCategorySelections,
  //       { vessel_category_id: vcId },
  //     ],
  //   }));
  // };

  const startDailyProcessing = () => {
    const { month, specie, lease_area, vesselCategorySelections } = selections;

    // Validation to ensure all fields are filled
    if (!month || !specie || !lease_area) {
      toast.error("Please select month, specie, and lease area", {
        position: "bottom-right",
        theme: "light",
      });
      return;
    }

    // Filter out unchecked vessel categories using categoriesToRemove
    const filteredSelections = vesselCategorySelections.filter(
      (sel) => !categoriesToRemove.includes(sel.vessel_category_id)
    );

    let missingFields = false;

    filteredSelections.forEach((sel) => {
      if (!sel.vessel || !sel.operation || !sel.no_of_vessels) {
        missingFields = true;
      }
      if (sel.aversion === undefined) {
        sel.aversion = "0";
      }
    });

    if (missingFields) {
      toast.error("Some vessel category fields are missing", {
        position: "bottom-right",
        theme: "light",
      });
      return;
    }

    let payload = {
      ...selections,
      vesselCategorySelections: filteredSelections,
    };

    console.log("daily payload:", payload);
    handleAddDailyTransit(payload);
  };

  const startMonthlyProcessing = () => {
    const { month, specie, vesselCategorySelections } = selections;

    // Validation to ensure all fields are filled
    if (!month || !specie) {
      toast.error("Please select month, specie", {
        position: "bottom-right",
        theme: "light",
      });
      return;
    }

    // Filter out unchecked vessel categories using categoriesToRemove
    const filteredSelections = vesselCategorySelections.filter(
      (sel) => !categoriesToRemove.includes(sel.vessel_category_id)
    );

    let missingFields = false;

    filteredSelections.forEach((sel, index) => {
      console.log("sel", sel);

      if (!sel.vessel || !sel.no_of_vessels) {
        missingFields = true;
      }
      if (sel.aversion === undefined) {
        sel.aversion = "0";
      }
      if (sel.vessel_speed === undefined) {
        // Find the matching vessel based on the vessel ID in sel.vessel
        const defaultVessel = vessels.find(
          (vessel) => vessel.id === sel.vessel
        );

        // Set vessel_speed to the found vessel's open_ocean_speed or a fallback empty string if none found
        sel.vessel_speed = defaultVessel
          ? String(defaultVessel.open_ocean_speed)
          : "0";
      }
    });

    if (missingFields) {
      toast.error("Some vessel category fields are missing", {
        position: "bottom-right",
        theme: "light",
      });
      return;
    }

    // Get geom and distribution point from localStorage
    const storedLineData = localStorage.getItem("selectedLine");
    if (!storedLineData) {
      toast.error("No geometry or distribution points selected", {
        position: "bottom-right",
        theme: "light",
      });
      return;
    }

    const { geom } = JSON.parse(storedLineData);

    if (!geom || !distributionPoints) {
      toast.error(
        "Geometry or distribution points are missing in the stored data",
        {
          position: "bottom-right",
          theme: "light",
        }
      );
      return;
    }

    // Round coordinates to 6 decimal places (you can adjust as needed)
    // const roundedDistributionPoints = distributionPoints.map((point) => ({
    //   lat: parseFloat(point.lat.toFixed(6)), // Round lat to 6 decimal places
    //   lng: parseFloat(point.lng.toFixed(6)), // Round lng to 6 decimal places
    // }));

    // Prepare the payload with rounded coordinates
    let payload = {
      ...selections,
      vesselCategorySelections: filteredSelections,
      geom, // Assuming the geom is in the correct format (WKB) already
      distributionPoints: distributionPoints,
    };

    console.log("monthly payload:", payload);
    handleAddMonthlyTransit(payload);
  };

  const handleTransitSubmit = () => {
    // if daily transit i.e wind farm
    if (selected === "wind") {
      startDailyProcessing();
    } else {
      // monthly transit
      startMonthlyProcessing();
    }
  };

  // const onSelect = () => {
  //   setAnimalModal(true);
  // };

  // const startProcessing = () => {
  //   console.log("start processing func");
  // };

  const getAnimals = () => {
    api_get_animals(1, 100, "ASC")
      .then((res) => {
        // console.log("animals resp: ", res);
        if (res.data.code === 200) {
          setData(res.data.data.animals);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  const getLeaseAreasNames = () => {
    api_get_lease_areas_names(1, 100, "ASC")
      .then((res) => {
        // console.log("lease areas names resp: ", res);
        if (res.data.code === 200) {
          setLeaseAreasNames(res.data.data.lease_areas_names);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  const getVesselCategories = () => {
    api_get_vessel_categories()
      .then((res) => {
        // console.log("vessel categories names resp: ", res);
        if (res.data.code === 200) {
          setVesselCategories(res.data.data.vessel_categories);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  const getVessels = () => {
    api_get_vessels(1, 100, "DESC")
      .then((res) => {
        // console.log("vessels resp: ", res);
        if (res.data.code === 200) {
          setVessels(res.data.data.vessels);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  const handleAddDailyTransit = async (payload) => {
    setIsLoading(true);
    try {
      const updateResp = await api_add_daily_transit(payload);
      if (updateResp.data.code === 200) {
        close();
        toast.success("Successfully submitted for processing!", {
          position: "bottom-right",
          theme: "light",
        });
      }
    } catch (error) {
      // console.log("error add transit",error);
      if (error.response.data.code === 400) {
        toast.error(error.response.data.data, {
          position: "bottom-right",
          theme: "light",
        });
      } else {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
        console.error("Error sending data for processing:", error);
      }
    } finally {
      setIsLoading(false); // Set loading state to false in any case
    }
  };

  const handleAddMonthlyTransit = async (payload) => {
    setIsLoading(true);
    try {
      const updateResp = await api_add_monthly_transit(payload);
      if (updateResp.data.code === 200) {
        close();
        // Clear only the "selectedLine" item from localStorage
        localStorage.removeItem("selectedLine");
        toast.success("Successfully submitted for processing!", {
          position: "bottom-right",
          theme: "light",
        });
      }
    } catch (error) {
      // console.log("error add transit",error);
      if (error.response.data.code === 400) {
        toast.error(error.response.data.data, {
          position: "bottom-right",
          theme: "light",
        });
      } else {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
        console.error("Error sending data for processing:", error);
      }
    } finally {
      setIsLoading(false); // Set loading state to false in any case
    }
  };

  useEffect(() => {
    getAnimals();
    getLeaseAreasNames();
    getVesselCategories();
    getVessels();
  }, []);

  return (
    <>
      <div className="vessel-parameter-modal">
        <Top close={calculatorHandler}>Vessel Parameters</Top>
        <img
          src="./fish.svg"
          alt=""
          width={"150px"}
          style={{
            position: "absolute",
            top: 10,
            left: -30,
            zIndex: 1001,
          }}
          class="modal-img-hidden"
        />
        <div className="vessel-parameter-wrap">
          <div
            style={{
              backgroundColor: "#EBEBEB",
              height: "100%",
              width: "150px",
              borderRadius: "5px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
              alignItems: "center",
              border: "1px solid #CBCBCB",
            }}
            class="vessel-sidebar"
          >
            <div
              style={{
                height: "290px",
                width: "100% !important",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 40,
                fontWeight: "bold",
                color: "#17256E",
                textAlign: "center",
              }}
              class="vessel-sidebar-wrap"
            >
              <div class="vessel-sidebar-p">Vessel Category</div>
              {selected === "wind" ? (
                <div class="vessel-sidebar-p">Operation Category</div>
              ) : (
                <div class="vessel-sidebar-p">
                  Vessel Speed(kts) Transit Speed
                </div>
              )}
              <div class="vessel-sidebar-p">Vessel Avoidance</div>
              <div class="vessel-sidebar-p">Number of Transits per Month</div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: 10,
              width: "calc(100% - 150px)",
            }}
          >
            <div className="vessel-table-month-card">
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 10,
                  flexWrap: "wrap",
                  padding: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                    width: "300px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "#17256E",
                    }}
                  >
                    Month
                  </div>
                  <select
                    placeholder="Select Month"
                    onChange={(e) =>
                      handleGlobalChange("month", e.target.value)
                    }
                    style={{
                      backgroundColor: "#EBEBEB",
                      border: "none",
                      width: "100%",
                      height: "80%",
                      borderRadius: "5px",
                    }}
                  >
                    <option value="" disabled selected>
                      Select Month
                    </option>
                    <option value="1">January</option>
                    <option value="2">Febuary</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                    width: "300px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "#17256E",
                    }}
                  >
                    Species
                  </div>
                  <select
                    placeholder="Select Species"
                    onChange={(e) =>
                      handleGlobalChange("specie", e.target.value)
                    }
                    id="speciesSelect"
                    style={{
                      backgroundColor: "#EBEBEB",
                      border: "none",
                      width: "100%",
                      height: "80%",
                      borderRadius: "5px",
                    }}
                  >
                    <option value="" disabled selected>
                      Select Species
                    </option>
                    {data
                      .filter((animal) => animal.status === "ACTIVE")
                      .map((animal) => (
                        <option key={animal.id} value={animal.id}>
                          {animal.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 10,
                    width: "300px",
                  }}
                >
                  {selected === "wind" ? (
                    <>
                      <div
                        style={{
                          fontWeight: "bold",
                          color: "#17256E",
                          textWrap: "nowrap",
                        }}
                      >
                        Lease areas
                      </div>
                      <select
                        placeholder="Select Lease area"
                        onChange={(e) =>
                          handleGlobalChange("lease_area", e.target.value)
                        }
                        id="speciesSelect"
                        style={{
                          backgroundColor: "#EBEBEB",
                          border: "none",
                          width: "100%",
                          height: "80%",
                          borderRadius: "5px",
                        }}
                      >
                        <option value="" disabled selected>
                          Select Lease Area
                        </option>
                        {leaseAreasNames.map((lease_areas) => (
                          <option key={lease_areas.id} value={lease_areas.id}>
                            {lease_areas.name}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="vessel-table-card">
              <div className="table-responsive h-100 vessel-table">
                <table
                  style={{
                    width: "100%",
                    height: "100%",
                    marginBottom: "10px",
                  }}
                >
                  {/* <tr
                    style={{
                      backgroundColor: "transparent",
                    }}
                  >
                    {vesselCategories.map((vc) => (
                      <th key={vc.id} value={vc.id} style={{color: "transparent"}}>
                        {vc.name}
                      </th>
                    ))}
                  </tr> */}
                  <tr>
                    {vesselCategories.map((vc) => (
                      <td key={vc.id}>
                        {/* Checkbox to select vessels */}
                        <label
                          style={{
                            fontSize: "12px",
                            fontWeight: "bold",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                handleCategoryChange(
                                  vc.id,
                                  "vessel",
                                  vessels
                                    .filter(
                                      (vessel) =>
                                        vessel.vessel_category === vc.name
                                    )
                                    .map(
                                      (filteredVessel) => filteredVessel.id
                                    )[0] // first index category
                                );
                              } else {
                                handleCategoryUncheck(vc.id);
                              }
                            }}
                          />
                          {vc.name}
                        </label>

                        {/*
                          <select
                            placeholder="Select Categoy"
                            onChange={(e) =>
                              handleCategoryChange(
                                vc.id,
                                "vessel",
                                e.target.value
                              )
                            }
                            style={{
                              backgroundColor: "white",
                              border: "none",
                              width: "100%",
                              height: "80%",
                              borderRadius: "5px",
                            }}
                          >
                            <option value="" disabled selected>
                              Select Vessel
                            </option>
                            {vessels
                              .filter(
                                (vessel) => vessel.vessel_category === vc.name
                              )
                              .map((filteredVessel) => (
                                <option
                                  key={filteredVessel.id}
                                  value={filteredVessel.id}
                                >
                                  {filteredVessel.vessel_type}
                                </option>
                              ))}
                          </select>
                          */}
                      </td>
                    ))}
                  </tr>

                  {selected === "wind" ? (
                    <tr>
                      <>
                        {vesselCategories.map((vc) => (
                          <td key={vc.id}>
                            <select
                              placeholder="Select Operation"
                              onChange={(e) =>
                                handleCategoryChange(
                                  vc.id,
                                  "operation",
                                  e.target.value
                                )
                              }
                              style={{
                                backgroundColor: "white",
                                border: "none",
                                width: "100%",
                                height: "40px",
                                borderRadius: "5px",
                              }}
                            >
                              <option value="" disabled selected>
                                Select Operation
                              </option>
                              <option key={"om"} value={"om"}>
                                OM
                              </option>
                              <option
                                key={"construction"}
                                value={"construction"}
                              >
                                Construction
                              </option>
                              <option key={"survey"} value={"survey"}>
                                Survey
                              </option>
                            </select>
                          </td>
                        ))}
                      </>
                    </tr>
                  ) : (
                    <tr>
                      {vesselCategories.map((vc, index) => (
                        <td key={vc.id}>
                          <input
                            placeholder="Vessel Speed"
                            type="number"
                            value={
                              selections.vesselCategorySelections.find(
                                (sel) => sel.vessel_category_id === vc.id
                              )?.vessel_speed ??
                              vessels[index]?.open_ocean_speed
                            }
                            onChange={(e) =>
                              handleCategoryChange(
                                vc.id,
                                "vessel_speed",
                                e.target.value
                              )
                            }
                            style={{
                              backgroundColor: "white",
                              border: "none",
                              width: "100%",
                              height: "40px",
                              borderRadius: "5px",
                            }}
                          ></input>
                        </td>
                      ))}
                    </tr>
                  )}
                  <tr>
                    {vesselCategories.map((vc, index) => (
                      <td key={vc.id}>
                        <input
                          type="range"
                          id="mySlider"
                          min="0"
                          max="100"
                          defaultValue={"0"}
                          onChange={(e) =>
                            handleCategoryChange(
                              vc.id,
                              "aversion",
                              e.target.value
                            )
                          }
                          title={
                            selections.vesselCategorySelections.find(
                              (sel) => sel.vessel_category_id === vc.id
                            )?.aversion
                          }
                          style={{
                            backgroundColor: "white",
                            border: "none",
                            width: "100%",
                            height: "20%",
                            borderRadius: "5px",
                          }}
                        ></input>
                        <div
                          style={{
                            textAlign: "center",
                            height: "5px",
                            color: "black",
                          }}
                        >
                          {
                            selections.vesselCategorySelections.find(
                              (sel) => sel.vessel_category_id === vc.id
                            )?.aversion
                          }
                        </div>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    {vesselCategories.map((vc) => (
                      <td key={vc.id}>
                        <input
                          placeholder="No. of transit"
                          min={"0"}
                          type="number"
                          onChange={(e) =>
                            handleCategoryChange(
                              vc.id,
                              "no_of_vessels",
                              e.target.value
                            )
                          }
                          style={{
                            backgroundColor: "white",
                            border: "none",
                            width: "100%",
                            height: "40px",
                            borderRadius: "5px",
                          }}
                        ></input>
                      </td>
                    ))}
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            marginBottom: "20px",
            marginTop: "15px",
          }}
        >
          <button
            style={{
              backgroundColor: "#CBCBCB",
              color: "black",
              border: "none",
              borderRadius: "5px",
              padding: "10px 20px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            className="select-tool-button"
            onClick={calculatorHandler}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "#17256E",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "10px 20px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            className="select-tool-button"
            onClick={() => !isLoading && handleTransitSubmit()}
          >
            {isLoading ? (
              <div style={{ height: "23px", width: "110px" }}>
                <CircularProgress
                  style={{ height: "20px", width: "20px", color: "white" }}
                />
              </div>
            ) : (
              "Start Processing"
            )}
          </button>
        </div>
      </div>
      {animalModal && (
        <AnimallPerameterModal close={() => setAnimalModal(false)} />
      )}
      {infoModal && (
        <InfoModal close={() => setInfoModal(false)}>
          Expected number of animal encounter during month along the route is
          0.6211
        </InfoModal>
      )}
      <ToastContainer />
    </>
  );
};

export default VesselParameter;
