import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Switch,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Header2 from "./Nevbar2";
import { Button, Grid, Pagination } from "@mui/material";
import ModalProvider from "./ModalProvider";
import EditUserForm from "./EditUserForm";
import {
  api_get_vessels,
  api_admin_delete_vessel,
  api_admin_deactivate_animal,
  api_admin_search_animal,
  api_admin_update_vessel,
} from "../api";
import AddNewVesselForm from "./AddNewVesselForm";
import { userBtnStyle } from "./TableStyles";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#ff0000",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
  },
  editButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#00ff00",
    "&:hover": {
      backgroundColor: "#00cc00",
    },
  },
  button: {
    textTransform: "none",
    borderRadius: "20px",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
    width: "100px",
    padding: "8px 20px",
  },
  searchBtn: {
    textTransform: "none",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "left",
    color: "black",
    border: "1px solid grey",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
    width: "200px",
    padding: "8px 20px",
  },
  userBtn: {
    textTransform: "none",
    borderRadius: "20px",
    color: "white",
    backgroundColor: "#17256e",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#101010",
    },
    width: "200px",
    padding: "8px 20px",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  menu: {
    display: "block",
    marginTop: "5px",
    position: "absolute",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    width: "auto",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    zIndex: "1",
  },
  list: {
    color: "black",
    padding: "5px 25px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "black",
    },
    display: "block",
    zIndex: "1",
    borderBottom: "1px solid #B5C0D0",
  },
  paginationRoot: {
    "& .MuiPaginationItem-root": {
      color: "white",
    },
  },
}));

const Vessels = () => {
  const classes = useStyles();
  const [deleteVessel, setDeleteVessel] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [addVessel, setAddVessel] = useState(false);
  const [updateVessel, setUpdateVessel] = useState(false);
  const [confirmAddVessel, setConfirmAddVessel] = useState(false);
  const [confirmEditVessel, setConfirmEditVessel] = useState(false);
  const [confirmUpdateVessel, setConfirmUpdateVessel] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const [editVessel, setEditVessel] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [orderType, setOrderType] = useState("DESC");
  const [vesselToUpdate, setVesselToUpdate] = useState(null);
  const [total, setTotal] = useState(0);
  const totalPages = Math.ceil(total / rowsPerPage);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("name");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterSelection = (value = filter) => {
    setFilter(value);
    setIsOpen(false);
    if (value === "name") {
      handleSearch(search, value);
    } else {
      setSearch("");
      getUpdatedVessels("DESC");
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      const deleteRes = await api_admin_delete_vessel({
        vessel_id: vesselToUpdate.id,
      });

      const res = await api_get_vessels(page, rowsPerPage, orderType);
      setData(res.data.data.vessels);
      setTotal(res.data.data.total);

      setTimeout(() => {
        setDeleteVessel(false);
      }, 3000);
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
      console.error("Error reloading vessels after update:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateVessel = async (payload) => {
    setIsLoading(true);
    try {
      const updateResp = await api_admin_update_vessel(payload);
      if (updateResp.data.code === 200) {
        setConfirmUpdateVessel(false);
        setUpdateSuccessful(true);
        const res = await api_get_vessels(page, rowsPerPage, orderType);
        setData(res.data.data.vessels);
        setTotal(res.data.data.total);

        setTimeout(() => {
          setDeleteVessel(false);
        }, 3000);
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
      console.error("Error reloading vessels after update:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        if (!search) {
          const res = await api_get_vessels(page, rowsPerPage, orderType);
          if (res.data.code === 200) {
            setData(res.data.data.vessels);
            setTotal(res.data.data.total);
          } else {
            console.log(res.data.message);
          }
        } else {
          handleFilterSelection(filter);
        }
      } catch (error) {
        console.log("Something went wrong:", error);
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const getUpdatedVessels = async (orderType = "DESC") => {
    setIsLoading(true);

    try {
      const res = await api_get_vessels(page, rowsPerPage, orderType);

      if (res.data.code === 200) {
        setData(res.data.data.vessels);
        setTotal(res.data.data.total);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
      console.log("Something went wrong:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = async (search_input = search, filter = "name") => {
    let searchTerm = search_input.trim();

    if (searchTerm !== "") {
      if (filter === "") {
        toast.error("Please select a search filter", {
          position: "bottom-right",
          theme: "light",
        });
      } else {
        let payload = {
          search_input: searchTerm,
          filter,
        };

        setIsLoading(true);

        try {
          const res = await api_admin_search_animal(page, rowsPerPage, payload);

          if (res.data.code === 200) {
            setData(res.data.data.vessels);
            setTotal(res.data.data.total);
          } else {
            console.log(res.data.message);
          }
        } catch (error) {
          toast.error("Something went wrong", {
            position: "bottom-right",
            theme: "light",
          });
          console.log("Something went wrong: ", error);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(true);
      try {
        await getUpdatedVessels("DESC");
      } catch (error) {
        console.log("Something went wrong: ", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || (e.key === "Backspace" && search === "")) {
      handleSearch(search, filter);
    }
  };

  return (
    <>
      <div className="content">
        <Header2 />
        <div class="breadcrumb-div">
          <h5
            style={{
              color: "#17256e",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Manage Vessels
          </h5>
          <p>
            Home{" "}
            <span style={{ color: "#17256e", fontWeight: "bold" }}>
              <ArrowRightOutlinedIcon /> Manage Vessels
            </span>
          </p>
        </div>
        <div className="content-wrap">
          <TableContainer component={Paper} style={{ background: "#f9f9f9" }}>
            <Grid
              container
              justifyContent="space-between"
              style={{ margin: "20px 0 0", padding: "0 20px" }}
            >
              <Grid item>
                <h6
                  style={{
                    color: "#17256e",
                    fontWeight: "bold",
                  }}
                >
                  Vessels{" "}
                  <span
                    style={{
                      color: "white",
                      width: "20px",
                      borderRadius: "5px",
                      background: "#17256e",
                      padding: "2px 6px",
                      marginLeft: "10px",
                    }}
                  >
                    {total}
                  </span>
                </h6>
                <div>
                  <p>
                    Sort by :{" "}
                    <span
                      style={{
                        color: "#17256e",
                        fontWeight: "bold",
                      }}
                    >
                      <select
                        id="sort"
                        name="sort"
                        className={classes.userBtn}
                        onChange={(e) => {
                          setOrderType(e.target.value);
                          getUpdatedVessels(e.target.value);
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                          color: "#222831",
                          fontSize: "16px",
                          borderRadius: "12px",
                          fontWeight: "bold",
                          width: "auto",
                          cursor: "pointer",
                          marginLeft: "20px",
                          textAlign: "center",
                          border: "0px",
                          padding: "5px",
                        }}
                      >
                        <option value="DESC" className={classes.userBtn}>
                          Recent
                        </option>
                        <option value="ASC" className={classes.userBtn}>
                          Oldest
                        </option>
                      </select>
                    </span>{" "}
                  </p>
                </div>
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between" gap="10px">
                  {/*   <input
                    className={classes.searchBtn}
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                  ></input>
                  <Grid item>
                    <div
                      ref={dropdownRef}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <Button
                        variant="contained"
                        className={classes.userBtn}
                        onClick={toggleDropdown}
                        style={{ width: "auto" }}
                      >
                        <FilterAltOutlinedIcon className={classes.icon} />
                        Filter-by&nbsp;
                        <span
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {filter}
                        </span>
                      </Button>
                      {isOpen && (
                        <div className={classes.menu}>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("")}
                            value={""}
                          >
                            Clear
                          </div>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("name")}
                            value={"name"}
                          >
                            Name
                          </div>
                        </div>
                      )}
                    </div> 
                  </Grid> */}
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.userBtn}
                      onClick={() => setAddVessel(true)}
                      sx={userBtnStyle}
                    >
                      Add a New Vessel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {total > 0 ? (
              <div className="table-responsive">
                <Table aria-label="vessel table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Vessel Type
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Vessel Category
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Inshore Speed
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Open Ocean Speed
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Lease Areas Speed
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Length
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Width
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Draft
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Delete
                      </TableCell>
                      {/* <TableCell style={{ fontWeight: "bold" }}>Status</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell style={{ textTransform: "capitalize" }}>
                          {row.vessel_type}
                        </TableCell>
                        <TableCell style={{ textTransform: "capitalize" }}>
                          {row.vessel_category}
                        </TableCell>
                        <TableCell>{row.inshore_speed}</TableCell>
                        <TableCell>{row.open_ocean_speed}</TableCell>
                        <TableCell>{row.leaseareas_speed}</TableCell>
                        <TableCell>{row.length}</TableCell>
                        <TableCell>{row.width}</TableCell>
                        <TableCell>{row.draft}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="deleteVessel"
                            onClick={() => {
                              setVesselToUpdate(row);
                              setConfirmDelete(true);
                            }}
                            className={classes.deleteButton}
                          >
                            <DeleteRoundedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "50vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isLoading ? (
                  <>
                    <CircularProgress />
                  </>
                ) : (
                  <h4>No vessel found</h4>
                )}
              </div>
            )}
          </TableContainer>
        </div>
        {totalPages > 1 ? (
          <div className="bottom-bar">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#17256e",
                width: "100%",
                height: "100%",
                marginTop: "10px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                padding: "10px",
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                classes={{ root: classes.paginationRoot }}
                onChange={handlePageChange}
                style={{ color: "white" }}
              />
            </div>
          </div>
        ) : null}
      </div>
      {deleteVessel && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "0px 40px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "5px", top: "0px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => setDeleteVessel(false)}
              />
            </div>
            <img
              src="/delete-icon.png"
              alt="delete"
              style={{ height: "80px", width: "80px" }}
            />
            <p
              style={{ fontSize: "20px", fontWeight: "700", color: "#17256E" }}
            >
              Vessel Successfully Deleted.
            </p>
            <p
              style={{ fontSize: "14px", fontWeight: "400", color: "#17256E" }}
            >
              This vessel is deleted from Portal.
            </p>
          </div>
        </ModalProvider>
      )}

      {confirmDelete && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                padding: "0px 40px",
              }}
            >
              <img
                src="/delete-icon.png"
                alt="delete"
                style={{ height: "30px", width: "30px" }}
              />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#17256E",
                  marginTop: "13px",
                }}
              >
                Delete Vessel
              </p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#17256E" }}
            ></div>
            <div
              style={{
                display: "flex",
                gap: "4px",
                padding: "0px 40px",
                marginTop: "10px",
              }}
            >
              <img
                src="/user-remove.png"
                alt="delete"
                style={{ height: "30px", width: "30px" }}
              />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#17256E",
                  marginTop: "3px",
                }}
              >
                Are you sure you want to delete this vessel?
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                padding: "0px 40px",
                margin: "20px 0",
                flexWrap: "wrap",
              }}
            >
              <button
                style={{
                  borderRadius: "5px",
                  padding: "5px 60px",
                  color: "#17256E",
                  background: "#F2F2F2",
                  outline: "none",
                  border: "none",
                  boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  WebkitBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  MozBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                }}
                onClick={() => {
                  setConfirmDelete(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  borderRadius: "5px",
                  padding: "5px 60px",
                  background: "#17256E",
                  color: "white",
                  outline: "none",
                  border: "none",
                  boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  WebkitBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  MozBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                }}
                onClick={() => {
                  handleDelete();
                  setDeleteVessel(true);
                  setConfirmDelete(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </ModalProvider>
      )}
      {(confirmAddVessel || confirmEditVessel) && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "40px 80px",
              paddingBottom: "20px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "25px", top: "15px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => {
                  setConfirmAddVessel(false);
                  setConfirmEditVessel(false);
                  getUpdatedVessels();
                }}
              />
            </div>
            <img
              src="/approve-user.png"
              alt="approve"
              style={{ height: "80px", width: "80px" }}
            />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#17256E",
              }}
            >
              Vessel Successfully {confirmEditVessel ? "Edited" : "Added"}.
            </p>
          </div>
        </ModalProvider>
      )}
      {updateSuccessful && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "40px 80px",
              paddingBottom: "20px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "25px", top: "15px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => {
                  setConfirmAddVessel(false);
                  setConfirmEditVessel(false);
                  setConfirmUpdateVessel(false);
                  setUpdateSuccessful(false);
                  getUpdatedVessels();
                }}
              />
            </div>
            <img
              src="/approve-user.png"
              alt="approve"
              style={{ height: "80px", width: "80px" }}
            />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#17256E",
              }}
            >
              Vessel Successfully Updated.
            </p>
          </div>
        </ModalProvider>
      )}
      {confirmUpdateVessel && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "40px 80px",
              paddingBottom: "20px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "25px", top: "15px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => {
                  setConfirmAddVessel(false);
                  setConfirmEditVessel(false);
                  getUpdatedVessels();
                }}
              />
            </div>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#17256E",
              }}
            >
              Vessel Type already exists. <br></br>Would you like to update ?
            </p>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "25px",
                margin: "30px 0",
              }}
            >
              <Button
                variant="contained"
                className={classes.userBtn}
                sx={userBtnStyle}
                onClick={() => {
                  handleUpdateVessel(updateVessel);
                }}
              >
                Update Vessel
              </Button>
              <Button
                variant="contained"
                className={classes.userBtn}
                sx={userBtnStyle}
                onClick={() => setAddVessel(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </ModalProvider>
      )}
      {addVessel && (
        <AddNewVesselForm
          setAddVessel={setAddVessel}
          setConfirmAddVessel={setConfirmAddVessel}
          setConfirmUpdateVessel={setConfirmUpdateVessel}
          setUpdateVessel={setUpdateVessel}
        />
      )}
      {editVessel && (
        <EditUserForm
          setEditUser={setEditVessel}
          setConfirmEditUser={setConfirmEditVessel}
          userToUpdate={vesselToUpdate}
          onUpdateUser={handleUpdateVessel}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default Vessels;
