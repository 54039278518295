import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  makeStyles,
  CircularProgress,
  DialogContent,
  Dialog,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Header2 from "./Nevbar2";
import { Button, Grid, Pagination } from "@mui/material";
import ModalProvider from "./ModalProvider";
import {
  api_get_species_raster,
  api_admin_ban_user,
  api_admin_search_user,
  api_download_species_raster,
  api_admin_delete_species_raster,
} from "../api";
import { userBtnStyle } from "./TableStyles";
import getMonthName from "./lib/GetMonth";
import formatBytes from "./lib/FormatBytes";
import EditRasterForm from "./EditRasterForm";
import RasterUpload from "./RasterUpload";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#ff0000",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
  },
  editButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#00ff00",
    "&:hover": {
      backgroundColor: "#00cc00",
    },
  },
  downloadButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#CD7F32",
    "&:hover": {
      backgroundColor: "brown",
    },
  },
  button: {
    textTransform: "none",
    borderRadius: "20px",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
    width: "100px",
    padding: "8px 20px",
  },
  searchBtn: {
    textTransform: "none",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "left",
    color: "black",
    border: "1px solid grey",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
    width: "200px",
    padding: "8px 20px",
  },
  userBtn: {
    textTransform: "none",
    borderRadius: "20px",
    color: "white",
    backgroundColor: "#17256e",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#101010",
    },
    width: "200px",
    padding: "8px 20px",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  menu: {
    display: "block",
    marginTop: "5px",
    position: "absolute",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    width: "auto",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    zIndex: "1",
  },
  list: {
    color: "black",
    padding: "5px 25px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "black",
    },
    display: "block",
    zIndex: "1",
    borderBottom: "1px solid #B5C0D0",
  },
  paginationRoot: {
    "& .MuiPaginationItem-root": {
      color: "white",
    },
  },
}));

const RasterData = () => {
  const classes = useStyles();
  const [deleteRaster, setDeleteRaster] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [addRaster, setAddRaster] = useState(false);
  const [confirmAddRaster, setConfirmAddRaster] = useState(false);
  const [confirmEditRaster, setConfirmEditRaster] = useState(false);
  const [editRaster, setEditRaster] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [orderType, setOrderType] = useState("DESC");
  const [rasterToUpdate, setRasterToUpdate] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const totalPages = Math.ceil(totalUsers / rowsPerPage);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("email");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterSelection = (value = filter) => {
    setFilter(value);
    setIsOpen(false);
    if (value === "email") {
      handleSearch(search, value);
    } else if (value === "email") {
      handleSearch(search, value);
    } else if (value === "name") {
      handleSearch(search, value);
    } else if (value === "verified") {
      handleSearch("true", value);
    } else if (value === "unverified") {
      handleSearch("false", "verified");
    } else if (value === "banned") {
      handleSearch("true", value);
    } else if (value === "active") {
      handleSearch("false", "banned");
    } else {
      setSearch("");
      getUpdatedRaster("DESC");
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDelete = async (id) => {
    setIsLoading(true);

    try {
      await api_admin_delete_species_raster(rasterToUpdate.id);

      try {
        const res = await api_get_species_raster(page, rowsPerPage, orderType);
        setData(res.data.data.species_raster);
        setTotalUsers(res.data.data.total);

        setTimeout(() => {
          setDeleteRaster(false);
        }, 3000);
      } catch (error) {
        setDeleteRaster(false);
        console.error("Error reloading users after update:", error);
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      }
    } catch (error) {
      setDeleteRaster(false);
      console.log("Something went wrong");
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleBan = async (e) => {
    setIsLoading(true);

    try {
      const banRes = await api_admin_ban_user({ email: e.target.value });

      if (banRes.data.code === 200) {
        toast.success(banRes.data.data.message, {
          position: "bottom-right",
          theme: "light",
        });

        try {
          const res = await api_get_species_raster(
            page,
            rowsPerPage,
            orderType
          );
          setData(res.data.data.species_raster);
          setTotalUsers(res.data.data.total);

          setTimeout(() => {
            setDeleteRaster(false);
          }, 3000);
        } catch (error) {
          setDeleteRaster(false);
          console.error("Error reloading users after update:", error);
          toast.error("Something went wrong", {
            position: "bottom-right",
            theme: "light",
          });
        }
      }
    } catch (err) {
      setDeleteRaster(false);
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateRaster = (updatedUserData) => {
    // Find the index of the user in the data array
    const userIndex = data.findIndex((user) => user.id === updatedUserData.id);

    // If the user is found, update the data array with the updated user data
    if (userIndex !== -1) {
      const newData = [...data];
      newData[userIndex] = updatedUserData;
      setData(newData);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        if (!search) {
          const res = await api_get_species_raster(
            page,
            rowsPerPage,
            orderType
          );
          if (res.data.code === 200) {
            setData(res.data.data.species_raster);
            setTotalUsers(res.data.data.total);
          } else {
            console.log(res.data.message);
          }
        } else {
          handleFilterSelection(filter);
        }
      } catch (error) {
        console.log("Something went wrong:", error);
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const getUpdatedRaster = async (orderType = "DESC") => {
    setIsLoading(true);

    try {
      const res = await api_get_species_raster(page, rowsPerPage, orderType);

      if (res.data.code === 200) {
        setData(res.data.data.species_raster);
        setTotalUsers(res.data.data.total);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log("Something went wrong:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = async (search_input = search, filter = "email") => {
    let search = search_input.trim();

    if (search !== "") {
      if (filter === "") {
        toast.error("Please select a search filter", {
          position: "bottom-right",
          theme: "light",
        });
      } else {
        let payload = {
          search_input: search,
          filter,
        };

        setIsLoading(true);

        try {
          const res = await api_admin_search_user(page, rowsPerPage, payload);
          console.log("api search resp:", res);

          if (res.data.code === 200) {
            setData(res.data.data.species_raster);
            setTotalUsers(res.data.data.total);
          } else {
            console.log(res.data.message);
          }
        } catch (error) {
          console.log("Something went wrong: ", error);
        } finally {
          setIsLoading(false);
        }
      }
    } else if (search_input === "") {
      setIsLoading(true);
      try {
        await getUpdatedRaster("DESC");
      } catch (error) {
        console.log("Something went wrong: ", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || (e.key === "Backspace" && search === "")) {
      handleSearch(search, filter);
    }
  };

  function convertDateFormat(inputDate) {
    // Parse the input date string
    const parsedDate = new Date(inputDate);

    // Extract day, month, and year components
    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed, so add 1
    const year = parsedDate.getFullYear();

    // Format the date as DD-MM-YYYY
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }
  const handleDownload = (fileUrl) => {
    // Create an anchor element
    const link = document.createElement("a");

    // Set the href attribute to the file URL
    link.href = fileUrl;

    // Set the download attribute to specify the file name for the download
    // You can use the file name from the URL or specify a custom name
    link.download = fileUrl.split("/").pop(); // This gets the file name from the URL

    // Append the anchor to the document body (not visible to the user)
    document.body.appendChild(link);

    // Programmatically trigger a click on the anchor element
    link.click();

    // Remove the anchor from the document body after the download is initiated
    document.body.removeChild(link);
  };

  return (
    <>
      <div
        
        className="content"
      >
        <Header2 />
        <div class="breadcrumb-div">
          <h5
            style={{
              color: "#17256e",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Manage Species Raster
          </h5>
          <p>
            Home{" "}
            <span style={{ color: "#17256e", fontWeight: "bold" }}>
              <ArrowRightOutlinedIcon /> Manage Species Raster
            </span>
          </p>
        </div>
        <div className="content-wrap">
          <TableContainer component={Paper} style={{ background: "#f9f9f9" }}>
            <Grid
              container
              justifyContent="space-between"
              style={{ margin: "20px 0 0" , padding: "0 20px"}}
            >
              <Grid item>
                <h6
                  style={{
                    color: "#17256e",
                    fontWeight: "bold",
                  }}
                >
                  Raster{" "}
                  <span
                    style={{
                      color: "white",
                      width: "20px",
                      borderRadius: "5px",
                      background: "#17256e",
                      padding: "2px 6px",
                      marginLeft: "10px",
                    }}
                  >
                    {totalUsers}
                  </span>
                </h6>
                <div>
                  <p>
                    Sort by :{" "}
                    <span
                      style={{
                        color: "#17256e",
                        fontWeight: "bold",
                      }}
                    >
                      <select
                        id="sort"
                        name="sort"
                        className={classes.userBtn}
                        onChange={(e) => {
                          setOrderType(e.target.value);
                          getUpdatedRaster(e.target.value);
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                          color: "#222831",
                          fontSize: "16px",
                          borderRadius: "12px",
                          fontWeight: "bold",
                          width: "auto",
                          cursor: "pointer",
                          marginLeft: "20px",
                          textAlign: "center",
                          border: "0px",
                          padding: "5px",
                        }}
                      >
                        <option value="DESC" className={classes.userBtn}>
                          Recent
                        </option>
                        <option value="ASC" className={classes.userBtn}>
                          Oldest
                        </option>
                      </select>
                    </span>{" "}
                  </p>
                </div>
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between" gap="10px">
                  {/* <input
                    className={classes.searchBtn}
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                  ></input>
                  <Grid item>
                    <div
                      ref={dropdownRef}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <Button
                        variant="contained"
                        className={classes.userBtn}
                        onClick={toggleDropdown}
                        sx={userBtnStyle}
                        style={{ width: "auto" }}
                      >
                        <FilterAltOutlinedIcon className={classes.icon} />
                        Filter-by&nbsp;
                        <span
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {filter}
                        </span>
                      </Button>
                      {isOpen && (
                        <div className={classes.menu}>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("")}
                            value={""}
                          >
                            Clear
                          </div>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("email")}
                            value={"email"}
                          >
                            Email
                          </div>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("name")}
                            value={"name"}
                          >
                            Name
                          </div>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("unverified")}
                            value={"unverified"}
                          >
                            Unverified
                          </div>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("verified")}
                            value={"verified"}
                          >
                            Verified
                          </div>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("banned")}
                            value={"banned"}
                          >
                            Banned
                          </div>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("active")}
                            value={"active"}
                          >
                            Active
                          </div>
                        </div>
                      )}
                    </div>
                  </Grid> */}
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.userBtn}
                      onClick={() => setAddRaster(true)}
                      sx={userBtnStyle}
                    >
                      Add Specie Raster
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {data.length > 0 ? (
              <div class="table-responsive">
                <Table aria-label="user table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Month</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Specie Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Size</TableCell>
                    {/* <TableCell style={{ fontWeight: "bold" }}>
                      Area Per Pixel
                    </TableCell> */}
                    <TableCell style={{ fontWeight: "bold" }}>
                      Processing Status
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Edit</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Download
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{convertDateFormat(row.created_at)}</TableCell>
                      <TableCell>{getMonthName(row.month)}</TableCell>
                      <TableCell>{`${row.specie}`}</TableCell>
                      <TableCell>{formatBytes(row.size)}</TableCell>
                      {/* <TableCell>{`${row.area_per_pixel}`}</TableCell> */}
                      <TableCell>{row.processing_status}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setRasterToUpdate(row);
                            setEditRaster(true);
                          }}
                          className={classes.editButton}
                        >
                          <ModeEditOutlineRoundedIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="download"
                          onClick={() => handleDownload(row.file_s3_path)}
                          className={classes.downloadButton}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            setRasterToUpdate(row);
                            setConfirmDelete(true);
                          }}
                          className={classes.deleteButton}
                        >
                          <DeleteRoundedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "50vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isLoading ? (
                  <>
                    <CircularProgress />
                  </>
                ) : (
                  <h4>No Data found</h4>
                )}
              </div>
            )}
          </TableContainer>
        </div>
        {totalPages > 1 ? (
          <div
            style={{ height: "60px", margin: "10px 0px", padding: "0 50px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#17256e",
                width: "100%",
                height: "100%",
                marginTop: "10px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                padding: "10px",
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                classes={{ root: classes.paginationRoot }}
                onChange={handlePageChange}
                style={{ color: "white" }}
              />
            </div>
          </div>
        ) : null}
      </div>
      {deleteRaster && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "0px 40px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "5px", top: "0px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => setDeleteRaster(false)}
              />
            </div>
            <img
              src="/delete-icon.png"
              alt="delete"
              style={{ height: "80px", width: "80px" }}
            />
            <p
              style={{ fontSize: "20px", fontWeight: "700", color: "#17256E" }}
            >
              Data Successfully Deleted.
            </p>
            <p
              style={{ fontSize: "14px", fontWeight: "400", color: "#17256E" }}
            >
              This raster data will no longer be available to your Portal.
            </p>
          </div>
        </ModalProvider>
      )}

      {confirmDelete && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                padding: "0px 40px",
              }}
            >
              <img
                src="/delete-icon.png"
                alt="delete"
                style={{ height: "30px", width: "30px" }}
              />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#17256E",
                  marginTop: "13px",
                }}
              >
                Delete Raster
              </p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#17256E" }}
            ></div>
            <div
              style={{
                display: "flex",
                gap: "4px",
                padding: "0px 40px",
                marginTop: "10px",
              }}
            >
              <img
                src="/user-remove.png"
                alt="delete"
                style={{ height: "30px", width: "30px" }}
              />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#17256E",
                  marginTop: "3px",
                }}
              >
                Are you sure you want to delete this data?
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                padding: "0px 40px",
                margin: "20px 0",
                flexWrap: "wrap"	  
              }}
            >
              <button
                style={{
                  borderRadius: "5px",
                  padding: "5px 60px",
                  color: "#17256E",
                  background: "#F2F2F2",
                  outline: "none",
                  border: "none",
                  boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  WebkitBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  MozBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                }}
                onClick={() => {
                  setConfirmDelete(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  borderRadius: "5px",
                  padding: "5px 60px",
                  background: "#17256E",
                  color: "white",
                  outline: "none",
                  border: "none",
                  boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  WebkitBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  MozBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                }}
                onClick={() => {
                  handleDelete();
                  setDeleteRaster(true);
                  setConfirmDelete(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </ModalProvider>
      )}
      {(confirmAddRaster || confirmEditRaster) && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "40px 80px",
              paddingBottom: "20px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "25px", top: "15px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => {  
                  setConfirmAddRaster(false);
                  setConfirmEditRaster(false);
                  getUpdatedRaster();
                }}
              />
            </div>
            <img
              src="/approve-user.png"
              alt="approve" 
              style={{ height: "80px", width: "80px" }}
            />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#17256E",
              }}
            >
              Raster Successfully {confirmEditRaster ? "Edited" : "Added"}.
            </p>
          </div>
        </ModalProvider>
      )}
      {addRaster && (
        <Dialog
          open={addRaster}
          onClose={() => setAddRaster(false)}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            elevation: 3,
            sx: {
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              padding: 2,
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "30px 0",
              marginBottom: "1px",
              width: "82%",
              position: "relative",
              borderTopRightRadius: "208px",
            }}
          >
            <div style={{ position: "absolute", right: "-22%", top: "-32%" }}>
              <img src="/add-new-user-dophine.png" alt="dolphine" class="modal-img-hidden" />
            </div>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                marginLeft: "30px",
                gap: "5px",
              }}
            >
              <div style={{ position: "relative" }}>
                <img src="upload-p1.png" alt="upload" />
                <img
                  src="upload-p2.png"
                  alt="upload"
                  style={{ position: "absolute", left: "57%" }}
                />
              </div>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "#17256E",
                  marginTop: "10px",
                }}
              >
                Upload Raster
              </p>
            </div>
            <div>
              <img
                src="/divider.png"
                alt="divider"
                style={{
                  width: "100%",
                  marginLeft: "-73px",
                  height: "1px",
                }}
              />
            </div>
          </div>
          <DialogContent>
            <RasterUpload
              cancelUpload={setAddRaster}
              getUpdatedRaster={getUpdatedRaster}
            />
          </DialogContent>
        </Dialog>
      )}
      {editRaster && (
        <EditRasterForm
          setEditRaster={setEditRaster}
          setConfirmEditRaster={setConfirmEditRaster}
          rasterToUpdate={rasterToUpdate}
          onUpdateRaster={handleUpdateRaster}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default RasterData;
