import React, { createContext, useContext, useEffect, useState } from 'react';
import { setUserInfo } from '../hooks/useUserInfo';
import { setAuthHeader } from '../api';

// Create the AuthContext with an initial value
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider component to wrap your application with
export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [openCalculator, setOpenCalculator] = useState(false);

  const calculatorHandler = () => {
    setOpenCalculator(!openCalculator);
  };

  // Function to simulate a login action
  const login = (token, userData) => {
    localStorage.setItem('isLoggedIn', JSON.stringify(token));
    localStorage.setItem('userData', JSON.stringify(userData));
    setAuthHeader(token);
    setUserInfo(userData);
    setAuthenticated(true);
  };

  // Function to simulate a logout action
  const logout = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userData');
    setAuthenticated(false);
  };

  // The value provided by the context provider
  const contextValue = {
    authenticated,
    login,
    logout,
    calculatorHandler,
    openCalculator,
  };

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('isLoggedIn'));
    const userData = JSON.parse(localStorage.getItem('userData'));
    setAuthHeader(token);
    if (token) {
      setAuthenticated(true);
      setUserInfo(userData);
    }
  }, []);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
