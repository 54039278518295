import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Button } from "react-bootstrap";
import ModalWrapper from "./ModalProvider";
import { api_admin_add_ocean } from "../api";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  userBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 30px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  updateBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "200px",
    padding: "8px 8px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  cancelBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "#17256e",
    backgroundColor: "#f2f2f2", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "200px",
    padding: "8px 87px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  input: {
    borderRadius: "10px",
    padding: "6px 10px",
    width: "100%",
    outline: "none",
    border: "none",
    background: "#eee",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
}));

export default function AddNewOceanForm({ setAddOcean, setConfirmAddOcean }) {
  const classes = useStyles();
  const [ocean, setOcean] = useState({
    ocean_name: "",
    ocean_starting_latitude: "",
    ocean_ending_latitude: "",
  });

  const handleAddDensityData = () => {
    api_admin_add_ocean(ocean)
      .then((res) => {
        if (res.data.code === 200) {
          setAddOcean(false);
          setConfirmAddOcean(true);
        }
      })
      .catch((err) => {
        setAddOcean(false);
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  return (
    <>
      <ModalWrapper>
        <div
          style={{
            display: "flex",
            gap: "4px",
            padding: "0px 30px",
            marginTop: "10px",
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", right: "-30px", top: "-20px" }}>
            <img
              src="/add-new-user-dophine.png"
              alt="dophine"
              class="modal-img-hidden"
            />
          </div>
          <img
            src="/user-remove.png"
            alt="delete"
            style={{ height: "30px", width: "30px", display: "none" }}
          />
          <p
            style={{
              fontSize: "20px",
              fontWeight: "700",
              color: "#17256E",
              marginTop: "3px",
              marginLeft: "38px",
            }}
            class="heading-m-0"
          >
            Add Ocean
          </p>
        </div>
        <div style={{ width: "100%" }}>
          <img src="/divider.png" alt="divider" />
        </div>
        <div style={{ padding: "10px 70px" }} class="add-user-modal-wrap">
          <p style={{ fontSize: "14px", fontWeight: "500", maxWidth: "80%" }}>
            Please enter the ocean name below to add a new ocean to the portal.
          </p>
        </div>
        <Grid
          container
          spacing={6}
          style={{
            marginBottom: "20px",
            padding: "0 70px",
            paddingBottom: "5px",
          }}
          justifyContent="space-between"
          className="input-x-wrap"
        >
          <Grid item xs={12} style={{ maxWidth: "100%" }}>
            <Typography variant="subtitle1" className="vra-label">
              Ocean Name
            </Typography>
            <input
              type="text"
              placeholder="Northeast"
              className={classes.input}
              onChange={(e) => {
                setOcean({ ...ocean, ocean_name: e.target.value });
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ maxWidth: "100%" }}>
            <Typography variant="subtitle1" className="vra-label">
              Ocean Starting Latitude
            </Typography>
            <input
              type="number"
              placeholder="Northeast"
              className={classes.input}
              onChange={(e) => {
                setOcean({ ...ocean, ocean_starting_latitude: parseFloat(e.target.value) });
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ maxWidth: "100%" }}>
            <Typography variant="subtitle1" className="vra-label">
              Ocean Ending Latitude
            </Typography>
            <input
              type="number"
              placeholder="Northeast"
              className={classes.input}
              onChange={(e) => {
                setOcean({ ...ocean, ocean_ending_latitude: parseFloat(e.target.value) });
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "30px",
            margin: "30px 0",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="contained"
            className={classes.updateBtn + " vra-modal-btn"}
            onClick={() => {
              handleAddDensityData();
            }}
          >
            Add Ocean
          </Button>
          <Button
            variant="contained"
            className={classes.cancelBtn + " vra-modal-btn"}
            onClick={() => setAddOcean(false)}
          >
            Cancel
          </Button>
        </div>
      </ModalWrapper>
      <ToastContainer />
    </>
  );
}
