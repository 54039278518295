import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import Form from "react-bootstrap/Form";
import { api_admin_file_upload } from "../api";
import { ToastContainer, toast } from "react-toastify";

const FileUpload = ({ cancelUpload, getUpdatedFiles }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileDescription, setFileDescription] = useState("");
  const [color, setColor] = useState("#000000");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");

  const validFileTypes = {
    lease_areas: "zip",
    inshore: "zip",
    // construction: "txt",
    // om: "txt",
    // survey: "txt",
  };

  const handleFileChange = (newFiles) => {
    setFiles(newFiles);
    // Check if any file is uploaded
    if (newFiles.length > 0) {
      // Get the first file in the array (as you are limiting to 1 file)
      const uploadedFile = newFiles[0];

      // Update the fileName state with the name of the uploaded file
      setFileName(uploadedFile.name);
    }
  };

  const handleFileDescriptionChange = (event) => {
    setFileDescription(event.target.value);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };

  const handleCancel = () => {
    setFiles([]);
    setFileDescription("");
    setFileName("");
    setFileType("");
    cancelUpload(false);
    getUpdatedFiles();
  };

  const handleUpload = async () => {
    setIsLoading(true); // Set loading state to true

    try {
      const file = files[0];

      // Extract the file extension
      const fileExtension = file.name.split(".").pop();

      // Check if any field is empty
      if (!file || !fileName || !fileType || !fileDescription || !color) {
        toast.error("One or more fields are missing", {
          position: "bottom-right",
          theme: "light",
        });
        return;
      }

      // Check if the file type is valid
      if (!validFileTypes[fileType]) {
        toast.error("Invalid file type: " + fileType, {
          position: "bottom-right",
          theme: "light",
        });
        return;
      }

      // Check if the file extension matches the expected extension for the given file type
      if (validFileTypes[fileType] !== fileExtension) {
        toast.error(
          `Invalid file extension! Expected .${validFileTypes[fileType]} for ${fileType}`,
          {
            position: "bottom-right",
            theme: "light",
          }
        );
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("file_name", fileName);
      formData.append("file_type", fileType);
      formData.append("description", fileDescription);
      formData.append("color", color);

      const res = await api_admin_file_upload(formData);

      if (res.data.code === 200) {
        toast.success(res.data.message || "File added successfully!", {
          position: "bottom-right",
          theme: "light",
        });
        handleCancel();
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error uploading file", {
        position: "bottom-right",
        theme: "light",
      });
      console.log("Error uploading file:", error);
    } finally {
      setIsLoading(false); // Set loading state to false in any case
    }
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  return (
    <>
      <Box>
        <div
          style={{
            maxWidth: "500px",
            width: "100%",
            backgroundColor: "RGB(239, 239, 239)",
            border: "1px solid #C0BEBE",
            borderRadius: "8px",
            margin: "15px 0",
            marginBottom: "20px",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxHeight: "100px",
          }}
        >
          <p style={{ fontWeight: "600", fontSize: "15px", color: "#17256E" }}>
            File Type
          </p>
          <Form.Select
            aria-label="Select file"
            onChange={handleFileTypeChange}
            value={fileType}
            style={{
              maxWidth: "200px",
              boxShadow: "0px 4px 4px 0px #00000026",
            }}
          >
            <option value="">Select file</option>
            <option value="inshore">Inshore</option>
            <option value="lease_areas">Lease Areas</option>
            {/* <option value="construction">construction</option>
            <option value="om">om</option>
            <option value="survey">survey</option> */}
          </Form.Select>
        </div>
        <DropzoneArea
          acceptedFiles={[]}
          filesLimit={1}
          onChange={handleFileChange}
        />
        <div
          style={{ marginTop: "10px", position: "relative" }}
          className="fileupload"
        >
          <div style={{ position: "absolute", top: "-105px", left: "20px" }}>
            <img src="/upload-dull.png" alt="upload" />
          </div>
          <div style={{ position: "absolute", top: "-72px", left: "80px" }}>
            <p
              style={{ fontWeight: "400", fontSize: "12px", color: "#BEBBBB" }}
            >
              Attach a file here
            </p>
          </div>
          <p
            style={{
              fontSize: "12px",
              color: "#17256E",
              fontWeight: "400",
              marginTop: "20px",
            }}
          >
            File Name
          </p>
          <input
            value={fileName}
            onChange={(e) => {
              setFileName(e.target.value);
            }}
            placeholder="File Name"
            style={{
              border: "1px solid #C0BEBE",
              borderRadius: "8px",
              width: "99%",
              padding: "10px 15px",
              backgroundColor: "rgb(239, 239, 239)",
              outline: "none",
              fontSize: "12px",
            }}
          />
          <p
            style={{
              fontSize: "12px",
              color: "#17256E",
              fontWeight: "400",
              marginTop: "20px",
            }}
          >
            File Description(200)
          </p>
          <input
            value={fileDescription}
            onChange={handleFileDescriptionChange}
            placeholder="Description"
            style={{
              border: "1px solid #C0BEBE",
              borderRadius: "8px",
              width: "99%",
              padding: "10px 15px",
              backgroundColor: "rgb(239, 239, 239)",
              outline: "none",
              fontSize: "12px",
            }}
          />
          <p
            style={{
              fontSize: "12px",
              color: "#17256E",
              fontWeight: "400",
              marginTop: "20px",
            }}
          >
            Add Color Code (RGB)
          </p>
          <input
            type="color"
            value={color}
            onChange={handleColorChange}
            placeholder="Add rgb color code for lease areas"
            style={{
              border: "1px solid #C0BEBE",
              borderRadius: "8px",
              width: "99%",
              padding: "10px 15px",
              backgroundColor: "rgb(239, 239, 239)",
              outline: "none",
              fontSize: "12px",
            }}
          />
        </div>
        <div style={{ maxWidth: "80%", margin: "0 auto", marginTop: "50px" }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                onClick={handleCancel}
                style={{
                  color: "black",
                  background: "#F2F2F2",
                  padding: "10px 40px",
                  borderRadius: "11px",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleUpload}
                style={{
                  color: "white",
                  height: "45px",
                  background: "#17256E",
                  padding: "10px 40px",
                  borderRadius: "11px",
                  boxShadow: "0px 4px 4px 0px #00000040",
                }}
              >
                {isLoading ? (
                  <div style={{ height: "40px" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  "Upload"
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
      <ToastContainer />
    </>
  );
};

export default FileUpload;
