const deleteButtonStyle = {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#ff0000",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
  };
  
  const editButtonStyle = {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#00ff00",
    "&:hover": {
      backgroundColor: "#00cc00",
    },
  };
  
  const buttonStyle = {
    textTransform: "none",
    borderRadius: "20px",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
    width: "100px",
    padding: "8px 20px",
  };
  
  const searchBtnStyle = {
    textTransform: "none",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "left",
    color: "black",
    border: "1px solid grey",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
    width: "200px",
    padding: "8px 20px",
  };
  
  const userBtnStyle = {
    textTransform: "none",
    borderRadius: "20px",
    color: "white",
    backgroundColor: "#17256e",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#101010",
    },
    width: "200px",
    padding: "8px 20px",
  };
  
  const menuStyle = {
    display: "block",
    marginTop: "5px",
    position: "absolute",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    width: "auto",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    zIndex: "1",
  };
  
  const listStyle = {
    color: "black",
    padding: "5px 25px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "black",
    },
    display: "block",
    zIndex: "1",
    borderBottom: "1px solid #B5C0D0",
  };
  
  export {
    deleteButtonStyle,
    editButtonStyle,
    buttonStyle,
    searchBtnStyle,
    userBtnStyle,
    menuStyle,
    listStyle,
  };
  