import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "./nav.css";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "./Provider";
import useUserInfo from "../hooks/useUserInfo";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { Tooltip, tooltipClasses } from "@mui/material";
import { IoFishOutline } from "react-icons/io5";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import WaterOutlinedIcon from "@mui/icons-material/WaterOutlined";
import GradientOutlinedIcon from "@mui/icons-material/GradientOutlined";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const CustomDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  // const { calculatorHandler, openCalculator } = useContext(AuthContext);
  const userInfo = useUserInfo();
  const { calculatorHandler } = useAuth();
  const location = useLocation();
  const currentPage = location.pathname;
  const theme = useTheme();
  const [open, setOpen] = useState();

  return (
    <Box sx={{ display: "flex" }}>
      <CustomDrawer variant="permanent">
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* <img
            src="/sidebaricons/Menu.png"
            alt="menu"
            style={{ marginTop: "35px" }}
          /> */}
          <div style={{ marginTop: "25px", cursor: "pointer" }}>
            {currentPage === "/map" && (
              <Tooltip
                title="Calculator"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "20px",
                        },
                    },
                  },
                }}
              >
                <img
                  src="/sidebaricons/Calculator.png"
                  alt="calculator"
                  onClick={() => calculatorHandler()}
                />
              </Tooltip>
            )}
          </div>
          <Link style={{ marginTop: "15px" }} to="/map">
            <Tooltip
              title="Map"
              placement="right"
              arrow
              slotProps={{
                popper: {
                  sx: {
                    [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                      {
                        marginLeft: "10px",
                      },
                  },
                },
              }}
            >
              <img src="/sidebaricons/map.png" alt="map" />
            </Tooltip>
          </Link>
          {userInfo?.user_type !== "USER" && (
            <Link style={{ marginLeft: "5px" }} to="/spatial-data">
              <Tooltip
                title="Spatial Data"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "2px",
                        },
                    },
                  },
                }}
              >
                <img src="/sidebaricons/files.png" alt="files" />
              </Tooltip>
            </Link>
          )}
          {/* {userInfo?.user_type !== "USER" && (
            <Link
              style={{ marginLeft: "5px", marginTop: "-6px" }}
              to="/dashboard"
            >
              <Tooltip
                title="Dashboard"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "2px",
                        },
                    },
                  },
                }}
              >
                <img src="/sidebaricons/dashboard.png" alt="dashboard" />
              </Tooltip>
            </Link>
          )} */}
          {userInfo?.user_type !== "USER" && (
            <Link style={{ marginLeft: "5px", marginTop: "-6px" }} to="/users">
              <Tooltip
                title="Users"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "2px",
                        },
                    },
                  },
                }}
              >
                <img src="/sidebaricons/users.png" alt="users" />
              </Tooltip>
            </Link>
          )}
          {userInfo?.user_type !== "USER" && (
            <Link
              style={{ marginLeft: "5px", marginTop: "-6px" }}
              to="/audit-logs"
            >
              <Tooltip
                title="Audit Logs"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "2px",
                        },
                    },
                  },
                }}
              >
                <div
                  style={{
                    width: "86px",
                    height: "78px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "55px",
                      height: "42px",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 10px 0px #00000014",
                      borderRadius: "8px",
                    }}
                  >
                    <ArticleOutlinedIcon sx={{ color: "#292D32" }} />
                  </div>
                </div>
              </Tooltip>
            </Link>
          )}
          {userInfo?.user_type !== "USER" && (
            <Link
              style={{ marginLeft: "5px", marginTop: "-6px" }}
              to="/animals"
            >
              <Tooltip
                title="Animals"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "2px",
                        },
                    },
                  },
                }}
              >
                <div
                  style={{
                    width: "86px",
                    height: "78px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "55px",
                      height: "42px",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 10px 0px #00000014",
                      borderRadius: "8px",
                    }}
                  >
                    <IoFishOutline
                      style={{
                        color: "#292D32",
                        height: "25px",
                        width: "25px",
                      }}
                    />
                  </div>
                </div>
              </Tooltip>
            </Link>
          )}
          {userInfo?.user_type !== "USER" && (
            <Link
              style={{ marginLeft: "5px", marginTop: "-6px" }}
              to="/month-species-constant"
            >
              <Tooltip
                title="Month Species Constant"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "2px",
                        },
                    },
                  },
                }}
              >
                <div
                  style={{
                    width: "86px",
                    height: "78px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "55px",
                      height: "42px",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 10px 0px #00000014",
                      borderRadius: "8px",
                    }}
                  >
                    <CalendarMonthIcon
                      style={{
                        color: "#292D32",
                        height: "25px",
                        width: "25px",
                      }}
                    />
                  </div>
                </div>
              </Tooltip>
            </Link>
          )}
          {userInfo?.user_type !== "USER" && (
            <Link
              style={{ marginLeft: "5px", marginTop: "-6px" }}
              to="/vessels"
            >
              <Tooltip
                title="Vessels"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "2px",
                        },
                    },
                  },
                }}
              >
                <div
                  style={{
                    width: "86px",
                    height: "78px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "55px",
                      height: "42px",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 10px 0px #00000014",
                      borderRadius: "8px",
                    }}
                  >
                    <DirectionsBoatFilledOutlinedIcon
                      sx={{ color: "#292D32" }}
                    />
                  </div>
                </div>
              </Tooltip>
            </Link>
          )}
          {userInfo?.user_type !== "USER" && (
            <Link
              style={{ marginLeft: "5px", marginTop: "-6px" }}
              to="/vessel-type-data"
            >
              <Tooltip
                title="Vessel Type Data"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "2px",
                        },
                    },
                  },
                }}
              >
                <div
                  style={{
                    width: "86px",
                    height: "78px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "55px",
                      height: "42px",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 10px 0px #00000014",
                      borderRadius: "8px",
                    }}
                  >
                    <InsertChartOutlinedIcon sx={{ color: "#292D32" }} />
                  </div>
                </div>
              </Tooltip>
            </Link>
          )}
          {userInfo?.user_type !== "USER" && (
            <Link style={{ marginLeft: "5px", marginTop: "-6px" }} to="/oceans">
              <Tooltip
                title="Oceans"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "2px",
                        },
                    },
                  },
                }}
              >
                <div
                  style={{
                    width: "86px",
                    height: "78px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "55px",
                      height: "42px",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 10px 0px #00000014",
                      borderRadius: "8px",
                    }}
                  >
                    <WaterOutlinedIcon sx={{ color: "#292D32" }} />
                  </div>
                </div>
              </Tooltip>
            </Link>
          )}
          {userInfo?.user_type !== "USER" && (
            <Link
              style={{ marginLeft: "5px", marginTop: "-6px" }}
              to="/raster-data"
            >
              <Tooltip
                title="Raster Data"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "2px",
                        },
                    },
                  },
                }}
              >
                <div
                  style={{
                    width: "86px",
                    height: "78px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "55px",
                      height: "42px",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 10px 0px #00000014",
                      borderRadius: "8px",
                    }}
                  >
                    <GradientOutlinedIcon sx={{ color: "#292D32" }} />
                  </div>
                </div>
              </Tooltip>
            </Link>
          )}
          {userInfo?.user_type !== "USER" && (
            <Link
              style={{ marginLeft: "5px", marginTop: "-6px" }}
              to="/percentage-time-data"
            >
              <Tooltip
                title="Percentage Time Data"
                placement="right"
                arrow
                slotProps={{
                  popper: {
                    sx: {
                      [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                        {
                          marginLeft: "2px",
                        },
                    },
                  },
                }}
              >
                <div
                  style={{
                    width: "86px",
                    height: "78px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "55px",
                      height: "42px",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 4px 10px 0px #00000014",
                      borderRadius: "8px",
                    }}
                  >
                    <PieChartOutlineIcon sx={{ color: "#292D32" }} />
                  </div>
                </div>
              </Tooltip>
            </Link>
          )}
        </div>
        <Divider />
      </CustomDrawer>
    </Box>
  );
}
