import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import {
  api_admin_upload_species_raster,
  api_get_animals,
} from "../api";
import { ToastContainer, toast } from "react-toastify";

const RasterUpload = ({ cancelUpload, getUpdatedRaster }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [month, setMonth] = useState("1");
  const [areaPerPixel, setAreaPerPixel] = useState("");
  const [specieName, setSpecieName] = useState("");
  const [animals, setAnimals] = useState([]);

  const handleFileChange = (newFiles) => {
    setFiles(newFiles);
    // Check if any file is uploaded
    if (newFiles.length > 0) {
      // Get the first file in the array (as you are limiting to 1 file)
      const uploadedFile = newFiles[0];
    }
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleCancel = () => {
    setFiles([]);
    setMonth("");
    setSpecieName("");
    cancelUpload(false);
    getUpdatedRaster();
  };

  const handleUpload = async () => {
    setIsLoading(true); // Set loading state to true

    try {
      const file = files[0];

      // Extract the file extension
      const fileExtension = file.name.split(".").pop();

      // Check if any field is empty
      if (!file || !specieName || !month) {
        toast.error("One or more fields are missing", {
          position: "bottom-right",
          theme: "light",
        });
        return;
      }

      // Check if the file extension matches the expected extension for the given file type
      if ("img" !== fileExtension) {
        toast.error(`Invalid file extension! Expected .img for Raster data`, {
          position: "bottom-right",
          theme: "light",
        });
        return;
      }

      // Get the file size in bytes
      const fileSize = file.size;

      const formData = new FormData();
      formData.append("file", file);
      formData.append("specie_name", specieName);
      formData.append("month", month);
      formData.append("size", fileSize);
      formData.append("area_per_pixel", areaPerPixel);

      const res = await api_admin_upload_species_raster(formData);

      if (res.data.code === 200) {
        toast.success(res.data.message || "File added successfully!", {
          position: "bottom-right",
          theme: "light",
        });
        handleCancel();
      }
    } catch (error) {
      if (error.response.data.code === 404) {
        toast.error(error?.response?.data?.data || "Specie not found", {
          position: "bottom-right",
          theme: "light",
        });
      } else {
        toast.error(error?.response?.data?.message || "Error uploading file", {
          position: "bottom-right",
          theme: "light",
        });
        console.log("Error uploading file:", error);
      }
    } finally {
      setIsLoading(false); // Set loading state to false in any case
    }
  };

  const getAnimals = () => {
    api_get_animals(1, 100, "ASC")
      .then((res) => {
        if (res.data.code === 200) {
          setAnimals(res.data.data.animals);
          setSpecieName(res.data?.data?.animals[0].name);
          // console.log("default specie name ", res.data?.data?.animals[0].name);
        } else {
          console.log(res.data.message);
          toast.error(res.data.message, {
            position: "bottom-right",
            theme: "light",
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getAnimals();
  }, []);

  return (
    <>
      <Box sx={{ marginBottom: "40px" }}>
        <DropzoneArea
          acceptedFiles={[]}
          filesLimit={1}
          onChange={handleFileChange}
        />
        <div
          style={{ marginTop: "10px", position: "relative" }}
          className="fileupload"
        >
          <div style={{ position: "absolute", top: "-105px", left: "20px" }}>
            <img src="/upload-dull.png" alt="upload" />
          </div>
          <div style={{ position: "absolute", top: "-72px", left: "80px" }}>
            <p
              style={{ fontWeight: "400", fontSize: "12px", color: "#BEBBBB" }}
            >
              Attach a raster img here
            </p>
          </div>
          <p
            style={{
              fontSize: "12px",
              color: "#17256E",
              fontWeight: "400",
              marginTop: "20px",
            }}
          >
            Specie Name
          </p>
          {/* <input
            value={specieName}
            onChange={(e) => {
              setSpecieName(e.target.value);
            }}
            placeholder="Specie Name"
            style={{
              border: "1px solid #C0BEBE",
              borderRadius: "8px",
              width: "100%",
              padding: "10px 15px",
              backgroundColor: "rgb(239, 239, 239)",
              outline: "none",
              fontSize: "12px",
            }}
          /> */}
          <select
            placeholder="Select Species"
            id="speciesSelect"
            style={{
              border: "1px solid #C0BEBE",
              borderRadius: "8px",
              width: "100%",
              padding: "10px 15px",
              backgroundColor: "rgb(239, 239, 239)",
              outline: "none",
              fontSize: "12px",
            }}
            onChange={(e) => {
              setSpecieName(e.target.value);
            }}
          >
            {animals
              .filter((animal) => animal.status === "ACTIVE")
              .map((animal) => (
                <option key={animal.id} value={animal.name}>
                  {animal.name}
                </option>
              ))}
          </select>
          {/* <p
            style={{
              fontSize: "12px",
              color: "#17256E",
              fontWeight: "400",
              marginTop: "20px",
            }}
          >
            Area Per Pixel
          </p>
          <input
            value={areaPerPixel}
            onChange={(e) => {
              setAreaPerPixel(e.target.value);
            }}
            placeholder="Area Per Pixel"
            style={{
              border: "1px solid #C0BEBE",
              borderRadius: "8px",
              width: "100%",
              padding: "10px 15px",
              backgroundColor: "rgb(239, 239, 239)",
              outline: "none",
              fontSize: "12px",
            }}
          /> */}
          <p
            style={{
              fontSize: "12px",
              color: "#17256E",
              fontWeight: "400",
              marginTop: "20px",
            }}
          >
            Month
          </p>
          <select
            value={month}
            onChange={handleMonthChange}
            placeholder="Month"
            style={{
              border: "1px solid #C0BEBE",
              borderRadius: "8px",
              width: "100%",
              padding: "10px 15px",
              backgroundColor: "rgb(239, 239, 239)",
              outline: "none",
              fontSize: "12px",
            }}
          >
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </div>
        <div style={{ maxWidth: "80%", margin: "0 auto", marginTop: "50px" }}>
          <Grid
            container
            justifyContent="space-around"
            sx={{ paddingRight: "20px", gap: "20px" }}
          >
            <Grid item>
              <Button
                onClick={handleCancel}
                style={{
                  color: "black",
                  background: "#F2F2F2",
                  padding: "10px 40px",
                  borderRadius: "11px",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  width: "200px",
                }}
                className="button-narrow"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleUpload}
                style={{
                  color: "white",
                  height: "45px",
                  background: "#17256E",
                  padding: "10px 40px",
                  borderRadius: "11px",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  width: "200px",
                }}
                className="button-narrow"
              >
                {isLoading ? (
                  <div style={{ height: "40px" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  "Upload"
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
      <ToastContainer />
    </>
  );
};

export default RasterUpload;
