import { Switch } from "@material-ui/core";
import { api_admin_delete_file } from "../api";
import { toast, ToastContainer } from "react-toastify";

const UploadedFiles = ({
  id,
  fileName,
  fileType,
  fileUrl,
  description,
  active,
  date,
  getUpdatedFiles,
}) => {
  const downloadFile = (fileUrl) => {
    // Create an anchor element
    const link = document.createElement("a");

    // Set the href attribute to the file URL
    link.href = fileUrl;

    // Set the download attribute to specify the file name for the download
    // You can use the file name from the URL or specify a custom name
    link.download = fileUrl.split("/").pop(); // This gets the file name from the URL

    // Append the anchor to the document body (not visible to the user)
    document.body.appendChild(link);

    // Programmatically trigger a click on the anchor element
    link.click();

    // Remove the anchor from the document body after the download is initiated
    document.body.removeChild(link);
  };

  function convertDateFormat(inputDate) {
    // Parse the input date string
    const parsedDate = new Date(inputDate);

    // Extract day, month, and year components
    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed, so add 1
    const year = parsedDate.getFullYear();

    // Format the date as DD-MM-YYYY
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  const handleDelete = async (fileID) => {
    console.log("delete fileID ", fileID);
    try {
      const resp = await api_admin_delete_file({
        file_id: fileID,
        file_url: fileUrl,
        file_name: fileName,
      });
      if (resp.data.code === 200) {
        getUpdatedFiles();
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
      console.error("Error reloading species data after update:", error);
    }
  };

  return (
    <div id={id} class="uploaded-file-wrap">
      <img
        src="/file-type.png"
        alt="file"
        style={{ width: "47px", height: "71px" }}
      />
      <div style={{ marginLeft: "20px", width: "230px" }}>
        <h6 style={{ fontWeight: "700", color: "darkblue" }}>{fileName}</h6>
        <p style={{ fontSize: "14px", fontWeight: "700" }}>
          File Type : {fileType}
        </p>
        <p
          style={{
            fontSize: "12px",
            fontWeight: "500",
            marginTop: "-14px",
            height: "50px",
          }}
        >
          {description}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-20px",
          }}
        >
          {" "}
          <span
            style={{
              fontSize: "10px",
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <img src="/calendar.png" alt="calendar" />{" "}
            <span>{convertDateFormat(date)}</span>
          </span>{" "}
          {/* <span>
            <Switch checked={active} color="default" />
          </span> */}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          justifyContent: "flexStart",
        }}
      >
        <div
          style={{
            background: "red",
            width: "34px",
            height: "34px",
            padding: "7px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/delete-file.png"
            alt="delete"
            style={{ width: "100%", height: "100%", cursor: "pointer" }}
            onClick={() => handleDelete(id)}
          />
        </div>
        {/* <img
          src="/edit-icon.png"
          alt="edit"
          style={{ width: "34px", height: "34px" }}
        /> */}
        <img
          src="/download-icon.png"
          alt="download"
          style={{ width: "34px", height: "34px", cursor: "pointer" }}
          onClick={() => downloadFile(fileUrl)}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default UploadedFiles;
