import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FinishJob from "./FinishJob";
import {
  api_get_daily_transit,
  api_get_monthly_transit,
  api_get_monthly_transit_geojson,
} from "../api";
import { toast, ToastContainer } from "react-toastify";

function ProcessingSlide(props) {
  const { addGeoJson, removeGeoJson } = props;
  const [key, setKey] = useState("daily"); // Default to 'daily' tab
  const [isOpen, setIsOpen] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [completedData, setCompletedData] = useState([]);
  const [dailyTransitData, setDailyTransitData] = useState([]);
  const [monthlyTransitData, setMonthlyTransitData] = useState([]);
  const [selectedMonthlyTransits, setSelectedMonthlyTransits] = useState([]);

  const toggleOpen = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  const closeModal = () => setOpenResultModal(false);

  // Function to filter completed data and open the modal
  const handleOpenModal = (data) => {
    setCompletedData(data); // Set the clicked completed job
    setOpenResultModal(true); // Open the modal after setting the data
  };

  // Fetch Daily Transit Data
  const getDailyTransit = () => {
    api_get_daily_transit(1, 100, "ASC")
      .then((res) => {
        if (res.data.code === 200) {
          // Sort the transits array based on created_at field
          const sortedTransits = res.data.data.transits.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at); // Descending order
          });
          setDailyTransitData(sortedTransits);
        } else {
          console.log(res.data.message);
          toast.error(res.data.message, {
            position: "bottom-right",
            theme: "light",
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  // Fetch Monthly Transit Data
  const getMonthlyTransit = () => {
    api_get_monthly_transit(1, 100, "ASC")
      .then((res) => {
        if (res.data.code === 200) {
          const sortedTransits = res.data.data.transits.sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at); // Descending order
          });
          setMonthlyTransitData(sortedTransits);
        } else {
          console.log(res.data.message);
          toast.error(res.data.message, {
            position: "bottom-right",
            theme: "light",
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  const getMonthlyTransitGeoJson = async (id) => {
    try {
      const res = await api_get_monthly_transit_geojson(id);
      if (res.data.code === 200) {
        const features = res.data.data.features;
        const minExpectedStrikes = res.data.data.min_expected_strikes;
        const maxExpectedStrikes = res.data.data.max_expected_strikes;
        addGeoJson(features, minExpectedStrikes, maxExpectedStrikes, id);
      } else {
        console.log(res.data);
      }
    } catch (error) {
      console.log("Something went wrong:", error);
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
    }
  };

  // Handle checkbox selection for monthly items
  const handleCheckbox = (checked, id) => {
    if (checked) {
      getMonthlyTransitGeoJson(id);
      setSelectedMonthlyTransits([...selectedMonthlyTransits, id]); // Track the selected transits
    } else {
      removeGeoJson(id); // Remove the GeoJSON layer
      setSelectedMonthlyTransits(
        selectedMonthlyTransits.filter((item) => item !== id)
      ); // Remove from selected transits
    }
  };

  // Fetch data based on active tab
  useEffect(() => {
    if (key === "daily") {
      getDailyTransit();
    } else if (key === "monthly") {
      getMonthlyTransit();
    }
  }, [key]);

  return (
    <div>
      <div className={`processing-slide ${isOpen ? "open" : ""}`}>
        <div className="opener" onClick={toggleOpen}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chevron-left"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </div>
        <div className="slide-content">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="processing-tabs"
          >
            {/* Daily Tab */}
            <Tab eventKey="daily" title="Daily">
              <div className="tab-content-custom">
                {dailyTransitData.map((d, i) => (
                  <p className="processing-p" key={i}>
                    <span>{d.name}</span>
                    <span
                      className={`badge ${
                        d.processing_status === "completed"
                          ? "bg-success"
                          : "bg-warning"
                      }`}
                      style={
                        d.processing_status === "completed"
                          ? { cursor: "pointer" }
                          : { cursor: "default" }
                      }
                      onClick={() => {
                        d.processing_status === "completed" &&
                          handleOpenModal(d);
                      }}
                    >
                      {d.processing_status}
                    </span>
                  </p>
                ))}
              </div>
            </Tab>

            {/* Monthly Tab */}
            <Tab eventKey="monthly" title="Monthly">
              <div className="tab-content-custom">
                {monthlyTransitData.map((d, i) => (
                  <p className="processing-p" key={i}>
                    <div class="input-processing-align">
                    {d.processing_status === "completed" ? (
                      <input
                        type="checkbox"
                        checked={selectedMonthlyTransits.includes(d.id)}
                        onChange={(e) => handleCheckbox(e.target.checked, d.id)}
                      />
                    ) : null}
                    <span>{d.name}</span>
                    </div>
                    <span
                      className={`badge ${
                        d.processing_status === "completed"
                          ? "bg-success"
                          : "bg-warning"
                      }`}
                      style={
                        d.processing_status === "completed"
                          ? { cursor: "pointer" }
                          : { cursor: "default" }
                      }
                      onClick={() => {
                        d.processing_status === "completed" &&
                          handleOpenModal(d);
                      }}
                    >
                      {d.processing_status}
                    </span>
                  </p>
                ))}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <FinishJob
        isOpen={openResultModal}
        onClose={closeModal}
        data={completedData}
      />
      <ToastContainer />
    </div>
  );
}

export default ProcessingSlide;
