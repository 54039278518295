import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Form, Row, Col } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import img1 from "../pics/img4.png";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { useAuth } from "./Provider";
import { CgProfile } from "react-icons/cg";
import L from "leaflet";
import blueMarker from "../assets/blue-marker.png";
import "leaflet/dist/leaflet.css";
import './css/header_2.css';
function Header2({ opacity, marginBottom, map }) {
  const BASE_URL = process.env.REACT_APP_PUBLIC_BASE_URL.replace("api", "");
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const [isHovered, setIsHovered] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  let userInfo = localStorage.getItem("userData");
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
  }
  let profilePic = localStorage.getItem("profilePic");
  if (profilePic) {
    profilePic = JSON.parse(profilePic);
  }
  const { first_name, last_name } = userInfo;

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  // Custom Icon
  const customIcon = new L.Icon({
    iconUrl: blueMarker,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
  });

  const handleSearch = async (e) => {
    // e.preventDefault();
    if (!query) return;

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${query}`
      );
      const data = await response.json();
      setResults(data);

      if (data.length > 0 && map) {
        const { lat, lon } = data[0];
        map.setView([lat, lon], 13);
        L.marker([lat, lon], { icon: customIcon })
          .addTo(map)
          .bindPopup(data[0].display_name)
          .openPopup();
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <Container
      className="header-2"
    >
      <Row>
        <Navbar
          className="mt-1"
          style={{
            borderRadius: "20px",
            backgroundColor: "#17256e",
            opacity,
            marginBottom,
          }}
          data-bs-theme="dark"
        >
          <Row
            className="header-container"
          >
            <Col xl={4} lg={4} md={3} sm={4} xs={6}>
              <Navbar.Brand className="nav-link">
                <img
                  src={img1}
                  className="d-inline-block align-top header-2-logo"
                  alt="Your Logo"
                />
              </Navbar.Brand>
            </Col>
            {location.pathname === "/map" && (
              <Col xl={6} lg={5} md={6} sm={12} xs={12} className="col-order-sm-3 col-order-xs-3">
                <Form onChange={handleSearch}  className="header-form-search">
                  <div style={{ position: "relative" , width:"100%" }}>
                    <div
                      className="header-form-search-icon"
                    >
                      <SearchOutlinedIcon style={{ color: "#b7b2bf" }} />
                    </div>
                    <Form.Control
                      type="search"
                      placeholder="Search Location"
                      className="me-2 header-2-search"
                      aria-label="Search"
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </div>

                </Form>
                {results.length > 0 && (
                  <ul
                    className="header-2-ul"
                  >
                    {results.map((result, index) => (
                      <li
                        key={index}
                        style={{ padding: "5px", cursor: "pointer" }}
                        onClick={() => {
                          const { lat, lon } = result;
                          map.setView([lat, lon], 13);
                          L.marker([lat, lon], { icon: customIcon })
                            .addTo(map)
                            .bindPopup(result.display_name)
                            .openPopup();
                          setResults([]);
                        }}
                      >
                        {result.display_name}
                      </li>
                    ))}
                  </ul>
                )}
              </Col>
            )}
            <Col xl={2} lg={3} md={3} sm={8} xs={6} className="col-order-sm-2 col-order-xs-2">
              <div
                className="header-2-profile-section desktop"
              >
                <div>
                  <p className="header-profile-name">
                    {first_name + " " + last_name}
                  </p>
                  <p
                    className="header2-logout"
                    onClick={() => {
                      logout();
                      navigate("/");
                      window.location.reload();
                    }}
                  >
                    <LogoutOutlinedIcon style={{ rotate: "180deg" }} /> <span className="signout-text">Sign out</span>
                  </p>
                </div>
                <Link to="/profile">
                  {profilePic ? (
                    <img
                      src={profilePic}
                      alt="profile_pic"
                      className="header-2-profile-pic"
                    />
                  ) : (
                    <div
                      className="profile-icon"
                    >
                      <CgProfile
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  )}
                </Link>
              </div>
              <div
                className="header-2-profile-section mobile"
              >
                <div className={`mini-screen-dropdown ${dropdownVisible ? "visible" : ""}`}>
                  <Link to="/profile" className="header-profile-name">
                    {first_name + " " + last_name}
                  </Link>
                  <p
                    className="header2-logout"
                    onClick={() => {
                      logout();
                      navigate("/");
                      window.location.reload();
                    }}
                  >
                    <LogoutOutlinedIcon style={{ rotate: "180deg" }} /> <span className="signout-text">Sign out</span>
                  </p>
                </div>
                <div className="mini-screen-profile" onClick={toggleDropdown}>
                  {profilePic ? (
                    <img
                      src={profilePic}
                      alt="profile_pic"
                      className="header-2-profile-pic"
                    />
                  ) : (
                    <div
                      className="profile-icon"
                    >
                      <CgProfile
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Navbar>
      </Row>
    </Container>
  );
}

export default Header2;
