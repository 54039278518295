import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core";
import Header2 from "../components/Nevbar2";
import { Button } from "react-bootstrap";
import { setUserInfo } from "../hooks/useUserInfo";
import { api_profile_pic, api_update_user_info, editUser } from "../api";
import { CgProfile } from "react-icons/cg";
import { useAuth } from "../components/Provider";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  userBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 40px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  updateBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 140px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  cancelBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "#17256e",
    backgroundColor: "#f2f2f2", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 140px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  input: {
    borderRadius: "10px",
    padding: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    background: "#eee",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
}));

export default function User() {
  const { authenticated } = useAuth();
  const navigate = useNavigate();
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  useEffect(() => {
    if (!authenticated) {
      navigate("/login"); // Redirect to login page if not authenticated
    }
  }, [authenticated]);
  const classes = useStyles();
  let userInfo = localStorage.getItem("userData");
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
  }
  let profilePic = localStorage.getItem("profilePic");
  if (profilePic) {
    try {
      profilePic = JSON.parse(profilePic);
    } catch (error) {
      profilePic = "";
    }
  }
  const fileInputRef = useRef(null);
  const { first_name, last_name, email } = userInfo;
  const [updateData, setUpdateData] = useState({
    first_name,
    last_name,
    email,
  });
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    setLoading(true);
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append("file", file);

    api_profile_pic(formData)
      .then((res) => {
        localStorage.setItem(
          "profilePic",
          JSON.stringify(res.data.data.image_url)
        );
        profilePic = res.data.data.image_url;
        setUserInfo(res.data.data);
        toast(res.data.message, {
          position: "bottom-right",
          theme: "light",
        });
        setUpdateData({ ...updateData, image_url: profilePic });
        setLoading(false);
      })
      .catch((err) => {
        let errorCode = err.response.data.code;
        if (errorCode >= 500) {
          toast("Something went wrong", {
            position: "bottom-right",
            theme: "light",
          });
        } else if (errorCode == 401) {
          toast.error("Session expired", {
            position: "bottom-right",
            theme: "light",
          });
        } else {
          toast("Something went wrong", {
            position: "bottom-right",
            theme: "light",
          });
        }
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    if (updateData?.cPass) {
      if (updateData.password !== updateData?.cPass) {
        toast("Passwords do not match!", {
          position: "bottom-right",
          theme: "light",
        });
        return;
      }
      if (!passwordRegex.test(updateData.password)) {
        toast.error(
          "Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character (@$!%*?&)",
          {
            position: "bottom-right",
            theme: "light",
          }
        );
        setLoading(false);
        return;
      }
    }
    api_update_user_info(updateData)
      .then((res) => {
        if (res.data.code === 200) {
          // Update userData object with new data
          let oldUserData = localStorage.getItem("userData");
          if (oldUserData) {
            oldUserData = JSON.parse(oldUserData);
          }
          localStorage.setItem(
            "userData",
            JSON.stringify({
              ...oldUserData,
              first_name: updateData.first_name,
              last_name: updateData.last_name,
            })
          );
          setUserInfo(updateData);
          setUpdateData({ ...updateData, cPass: "", password: "" });
          toast.success(res.data.data, {
            position: "bottom-right",
            theme: "light",
          });
          // navigate("/map");
        }
      })
      .catch((err) => {
        let errorCode = err.response.data.code;
        if (errorCode >= 500) {
          toast("Something went wrong", {
            position: "bottom-right",
            theme: "light",
          });
        } else if (errorCode == 401) {
          toast.error("Session expired", {
            position: "bottom-right",
            theme: "light",
          });
        } else {
          toast("Something went wrong", {
            position: "bottom-right",
            theme: "light",
          });
        }
      });
  };

  return (
    <>
      {authenticated && (
        <div
          className="content"
        >
          <Header2 />
          <div className="profile-heading">
            <h5
            >
              account info
            </h5>
          </div>
          <div
            className="profile-card-section"
          >
            <div
              class="profile-page-icon"
            >
              {profilePic ? (
                <img
                  src={profilePic}
                  alt="img"
                  style={{ height: "100%", width: "100%", borderRadius: "50%" }}
                />
              ) : (
                <CgProfile
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
                margin: "20px 0",
                marginTop: "60px",
                marginBottom: "40px",
              }}
            >
              <h5
                style={{
                  color: "#17256e",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: "20px",
                }}
              >
                {updateData.first_name + " "}
                {updateData.last_name}
              </h5>
              <input
                type="file"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileChange}
                accept="image/*"
              />
              <Button
                variant="contained"
                className={classes.userBtn + " profile-btn"}
                style={{
                  fontStyle: loading ? "italic" : "",
                  background: !loading ? "#17256E" : "#58639d",
                  cursor: loading ? "not-allowed" : "",
                  pointerEvents: loading ? "none" : "",
                }}
                // disabled={loading}
                onClick={() => {
                  loading ? console.log("avoid") : fileInputRef.current.click();
                }}
              >
                {loading ? "Uploading..." : "Change Profile Picture"}
              </Button>
            </div>
            <Grid
              container
              spacing={2}
              style={{ marginBottom: "20px" }}
              justifyContent="space-between"
            >
              <Grid item xs={6} className="profile-input">
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginBottom: "12px",
                  }}
                >
                  First Name
                </Typography>
                <input
                  type="text"
                  placeholder="Full Name"
                  className={classes.input}
                  value={updateData.first_name}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, first_name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6} className="profile-input">
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginBottom: "12px",
                  }}
                >
                  Last Name
                </Typography>
                <input
                  type="text"
                  placeholder="Full Name"
                  className={classes.input}
                  value={updateData.last_name}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, last_name: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6} className="profile-input">
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginBottom: "12px",
                  }}
                >
                  Email Address
                </Typography>
                <input
                  type="email"
                  placeholder="Email Address"
                  className={classes.input}
                  value={updateData?.email}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={6} className="profile-input">
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginBottom: "12px",
                  }}
                >
                  Password
                </Typography>
                <input
                  type="password"
                  placeholder="Password"
                  className={classes.input}
                  value={updateData?.password}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, password: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6} className="profile-input">
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginBottom: "12px",
                  }}
                >
                  Confirm Password
                </Typography>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className={classes.input}
                  value={updateData?.cPass}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, cPass: e.target.value })
                  }
                />
              </Grid>
            </Grid>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "5px",
                margin: "80px 0",
                marginBottom: "20px",
              }}
            >
              <Button
                onClick={handleSubmit}
                variant="contained"
                className={classes.updateBtn + " vra-profile-btn"}
              >
                Update
              </Button>
              <Button
                variant="contained"
                className={classes.cancelBtn + " vra-profile-btn"}
                href="/map"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
}
