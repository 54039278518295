import React from "react";
import getMonthName from "./lib/GetMonth";

const FinishJob = ({ isOpen, onClose, data }) => {
  if (!isOpen) return null;

  // Calculate the sum of all the Result values in vesselCategorySelections
  const totalEncounterRiskFactor = data?.vesselCategorySelections?.reduce(
    (sum, category) => sum + (Number(category.Result) || 0),
    0
  );

  return (
    <div
      className={`modal fade ${isOpen ? "show d-block" : ""}`}
      tabIndex="-1"
      style={{ backgroundColor: isOpen ? "rgba(0,0,0,0.5)" : "transparent" }}
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content finish-job-modal-content">
          <div className="modal-body finish-job-body">
            <div className="header">
              <h5>Completed Results</h5>
              <span onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="#fff"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-x"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </span>
            </div>
            <div className="body">
              <div>
                <p className="finish-job-p">
                  <span>Name : </span>
                  <span
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {data.name}
                  </span>
                </p>
                <p className="finish-job-p">
                  <span>Month : </span>
                  <span>{getMonthName(data.month)}</span>
                </p>
                <p className="finish-job-p">
                  <span>Species : </span>
                  <span
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {data.specie}
                  </span>
                </p>
                {data.lease_area ? (
                  <p className="finish-job-p">
                    <span>Lease Areas : </span>
                    <span
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {data.lease_area}
                    </span>
                  </p>
                ) : null}
                <p className="finish-job-p">
                  <span>Total Encounter: </span>
                  <span
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    {totalEncounterRiskFactor}
                  </span>
                </p>
              </div>
              <div className="result-blocks-wrap">
                {data?.vesselCategorySelections?.map((category, index) => (
                  <div className="result-block" key={index}>
                    <p>
                      <span>Vessel:</span>
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        {category.Vessel}
                      </span>
                    </p>
                    <p>
                      <span>Category:</span>
                      <span
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        {category.VesselCategoryID}
                      </span>
                    </p>
                    {category.Operation ? (
                      <p>
                        <span>Operation:</span>
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {category.Operation}
                        </span>
                      </p>
                    ) : null}
                    {category.Aversion ? (
                      <p>
                        <span>Vessel Avoidance:</span>
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {category.Aversion}
                        </span>
                      </p>
                    ) : null}
                    {category.VesselSpeed ? (
                      <p>
                        <span>Vessel Speed:</span>
                        <span
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {category.VesselSpeed}
                        </span>
                      </p>
                    ) : null}
                    <p>
                      <span>No of Vessels:</span>
                      <span> {category.NoOfVessels}</span>
                    </p>
                    <p>
                      <span>Encounter:</span>
                      <span> {category.Result}</span>
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinishJob;
