import React, { useState } from 'react'
import SelectTool from './Components/SelectTool';
import '../css/modal.css'

const Modal = () => {
    return (
        <div className='custom-modal-wrap'>
            <SelectTool />
        </div>
    )
}

export default Modal
