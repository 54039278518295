import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import { FiEye, FiEyeOff } from 'react-icons/fi'; // Import eye icons
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Navbar';
import img1 from '../pics//img5.jpg';
import { api_signup } from '../api';
import '../components/css/form.css';

function Signup() {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const divStyle = {
    backgroundImage: `url(${img1})`,
  };
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [err, setErr] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sigupData, setSignupData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleChange = (e) => {
    setSignupData({
      ...sigupData,
      [e.target.name]: e.target.value,
    });
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setErr('');
    if (
      !sigupData.email ||
      !sigupData.password ||
      !sigupData.firstName ||
      !sigupData.lastName ||
      !sigupData.confirmPassword
    ) {
      toast('Please fill all the fields!', {
        position: 'bottom-right',
        theme: 'light',
      });
      setErr('');
      setLoading(false);
      return;
    }
    if (sigupData.password !== sigupData.confirmPassword) {
      toast('Passwords do not match!', {
        position: 'bottom-right',
        theme: 'light',
      });
      setLoading(false);
      return;
    }
    const { confirmPassword, ...rest } = sigupData;
    // Check is password is meeting criteria.
    if (!passwordRegex.test(sigupData.password)) {
      toast.error("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one digit, and one special character (@$!%*?&)", {
        position: "bottom-right",
        theme: "light",
      });
      setLoading(false);
      return
    }
    api_signup({ 
      first_name: sigupData.firstName,
      last_name: sigupData.lastName,
      email: sigupData.email,
      password: sigupData.password
     })
      .then((res) => {
        if (res.data.code === 200) {
          toast(res.data.message || 'Verification email send successfully!', {
            position: 'bottom-right',
            theme: 'light',
          });
          setLoading(false);
          navigate('/email-sent');
        }
      })
      .catch((err) => {
        console.log('err => ', err?.response?.data);
        toast.error(err?.response?.data?.data, {
          position: 'bottom-right',
          theme: 'light',
        });
        setLoading(false);
      });
  };

  return (
    <div style={divStyle} class="form-wrap">
      <Header backgroundColor="#17256E" />
      <Card
        className='form-card'
      >
        <Card.Body className='form-card-body'>
          <Row className="justify-content-center">
            <Col>
              <Card.Title
                className="text-center form-card-title" 
              >
                Sign Up
              </Card.Title>
            </Col>
          </Row>
          <Form onSubmit={formSubmitHandler}>
            <Row className="justify-content-center">
              <Col>
                <Form.Group className="mt-4" controlId="formBasicFirstName">
                  <Form.Label
                    className='form-label'  
                  >
                    First Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    className='form-input'
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mt-4" controlId="formBasicLastName">
                  <Form.Label
                   className='form-label'

                  >
                    Last Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    className='form-input'
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mt-4" controlId="formBasicEmail">
                  <Form.Label
                    className='form-label'

                  >
                    Email address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                   className='form-input'
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mt-4" controlId="formBasicPassword">
                  <Form.Label
                    className='form-label'

                  >
                    Password
                  </Form.Label>
                  <div style={{ position: 'relative', flex: 2 }}>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder="**********"
                      name="password"
                      className='form-input'
                      onChange={handleChange}
                    />
                    <span
                      onClick={() => togglePasswordVisibility('password')}
                      className='form-input-eye'
                    >
                      {!showPassword ? (
                        <FiEyeOff style={{ color: '#0A1967' }} />
                      ) : (
                        <FiEye style={{ color: '#0A1967' }} />
                      )}
                    </span>
                  </div>
                </Form.Group>

                <Form.Group
                  className="mt-4"
                  controlId="formBasicConfirmPassword"
                >
                  <Form.Label
                    className='form-label'
                  >
                    Confirm Password
                  </Form.Label>
                  <div style={{ position: 'relative', flex: 2 }}>
                    <Form.Control
                      type={showConfirmPassword ? 'text' : 'password'}
                      placeholder="**********"
                      name="confirmPassword"
                      className='form-input'
                      onChange={handleChange}
                    />
                    <span
                      onClick={() =>
                        togglePasswordVisibility('confirmPassword')
                      }
                      className='form-input-eye'
                    >
                      {!showConfirmPassword ? (
                        <FiEyeOff style={{ color: '#0A1967' }} />
                      ) : (
                        <FiEye style={{ color: '#0A1967' }} />
                      )}
                    </span>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            {err && (
              <p
                style={{
                  color: '#DC2626',
                  marginTop: '10px',
                  textAlign: 'center',
                }}
              >
                {err}
              </p>
            )}

            <Row className="justify-content-center">
              <Col xs="auto">
                <Button
                  style={{
                    
                    marginTop: err ? '5px' : '40px',
                    background: !loading ? '#17256E' : '#58639d',
                    fontStyle: loading ? 'italic' : '',
                  }}
                  className='form-button'
                  type="submit"
                >
                  <span style={{ color: 'white' }}>
                    {loading ? 'Sending otp...' : 'Register'}
                  </span>
                </Button>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col xs="auto">
                <p
                 className='form-end-label'
                >
                  Already have an account?{' '}
                  <Link
                    to="/login"
                    className='form-end-label-link'
                  >
                    Log In
                  </Link>
                </p>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <ToastContainer />
    </div>
  );
}

export default Signup;
