import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { FaQuestionCircle } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "../../Provider";
import { toast, ToastContainer } from "react-toastify";

const Transit = ({
  setModalOpen,
  setProjectName,
  selected,
  setSplitPoints,
}) => {
  const { calculatorHandler } = useAuth();
  const [name, setName] = useState("");
  const [geom, setGeom] = useState("");

  const handleProjectName = (e) => {
    let name = e.target.value;
    // console.log("project name",name);
    setProjectName(name);
    setName(name);
  };

  const handleDistributionPoints = (e) => {
    let points = parseFloat(e.target.value);
    console.log("points", points);
    setSplitPoints(points);
  };

  // Fetch geom and split points from local storage when the component loads
  useEffect(() => {
    // Get geom and distribution point from localStorage
    const storedLineData = localStorage.getItem("selectedLine");
    if (selected === "transit") {
      if (!storedLineData) {
        toast.error("No geometry or distribution points selected", {
          position: "bottom-right",
          theme: "light",
        });
        return;
      }
  
      const { geom } = JSON.parse(storedLineData);
  
      if (!geom) {
        toast.error(
          "Geometry or distribution points are missing in the stored data",
          {
            position: "bottom-right",
            theme: "light",
          }
        );
        return;
      }
  
      if (geom) {
        setGeom(geom);
      }
  
      // Log the values for reference
      console.log("Geom:", geom);
    }
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          flex: 1,
          padding: " 0 10px",
        }}
      >
        <div
          style={{
            padding: "10px 50px",
            fontWeight: "bold",
            color: "rgb(23, 37, 110)",
            fontSize: 15,
            textAlign: "center",
            marginTop: 20,
          }}
        >
          Enter Project Name
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
        >
          <TextField
            variant="filled"
            size="small"
            onChange={handleProjectName}

          />
          {/* <Tooltip
            placement="right-start"
            title="Enter the project name so that it can be easily found among the layers"
          >
            <IconButton>
              <FaQuestionCircle size={22} />
            </IconButton>
          </Tooltip> */}
        </div>

        {/* Conditionally display the message if selected is "transit" and geom is empty */}
        {/* {selected === "transit" && (
          <>
            <div
              style={{
                padding: "10px 50px",
                fontWeight: "bold",
                color: "rgb(23, 37, 110)",
                fontSize: 15,
                textAlign: "center",
                marginTop: 20,
              }}
            >
              Enter Split Points
            </div>
            <TextField
            variant="filled"
            size="small"
              type="number"
              style={{
                padding: "10px 50px",
                fontWeight: "bold",
                color: "rgb(23, 37, 110)",
                fontSize: 15,
                textAlign: "center",
                marginTop: 20,
                margin:0,
              }}
              onChange={handleDistributionPoints}
            />
          </>
        )} */}
        {selected === "transit" && !geom && (
          <div
            style={{
              color: "red",
              fontSize: "14px",
              marginTop: "10px",
              textAlign: "center",
            }}
          >
            Please select the polygon line first
          </div>
        )}

        <div class="parameter-button-wrap">
          <button
            style={{
              backgroundColor: "#CBCBCB",
              color: "black",
              border: "none",
              borderRadius: "5px",
              padding: "10px 20px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            className="select-tool-button"
            onClick={calculatorHandler}
          >
            Cancel
          </button>
          <button
            style={{
              backgroundColor: "rgb(23, 37, 110)",
              opacity: 0.9,
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "10px 20px",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            className="select-tool-button"
            onClick={() => {
              if (!name) {
                toast.error("Please add the Project Name", {
                  position: "bottom-right",
                  theme: "light",
                });
                return;
              }
              if (selected === "transit" && !geom) {
                toast.error("Please select the line first for montly transit", {
                  position: "bottom-right",
                  theme: "light",
                });
                return;
              }
              setModalOpen(true);
            }}
          >
            Select Parameters
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Transit;
