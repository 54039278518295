import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Header2 from "./Nevbar2";
import { Button, Grid, Pagination } from "@mui/material";
import { api_get_audit_logs, api_admin_search_auditlog } from "../api";
import { userBtnStyle } from "./TableStyles";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#ff0000",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
  },
  editButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#00ff00",
    "&:hover": {
      backgroundColor: "#00cc00",
    },
  },
  button: {
    textTransform: "none",
    borderRadius: "20px",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
    width: "100px",
    padding: "8px 20px",
  },
  searchBtn: {
    textTransform: "none",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "left",
    color: "black",
    border: "1px solid grey",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
    width: "200px",
    padding: "8px 20px",
  },
  userBtn: {
    textTransform: "none",
    borderRadius: "20px",
    color: "white",
    backgroundColor: "#17256e",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#101010",
    },
    width: "200px",
    padding: "8px 20px",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  menu: {
    display: "block",
    marginTop: "5px",
    position: "absolute",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    width: "auto",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    zIndex: "1",
  },
  list: {
    color: "black",
    padding: "5px 25px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "black",
    },
    display: "block",
    zIndex: "1",
    borderBottom: "1px solid #B5C0D0",
  },
  paginationRoot: {
    '& .MuiPaginationItem-root': {
      color: 'white',
    },
  },
}));

const AuditLogs = () => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [orderType, setOrderType] = useState("DESC");
  const [totalLogs, setTotalLogs] = useState(0);
  const totalPages = Math.ceil(totalLogs / rowsPerPage);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("email");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterSelection = (value = filter) => {
    setFilter(value);
    setIsOpen(false);
    if (value === "email") {
      handleSearch(search, value);
    } else if (value === "name") {
      handleSearch(search, value);
    } else {
      setSearch("");
      getUpdatedUsers("DESC");
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const fetchAuditLogs = async () => {
      setIsLoading(true);

      try {
        if (!search) {
          const res = await api_get_audit_logs(page, rowsPerPage, orderType);
          if (res.data.code === 200) {
            setData(res.data.data.audit_logs);
            setTotalLogs(res.data.data.total);
          } else {
            console.log(res.data.message);
          }
        } else {
          handleFilterSelection(filter);
        }
      } catch (error) {
        console.log("Something went wrong:", error);
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchAuditLogs();
  }, [page]);

  const getUpdatedUsers = async (orderType) => {
    setIsLoading(true);

    try {
      const res = await api_get_audit_logs(page, rowsPerPage, orderType);

      if (res.data.code === 200) {
        setData(res.data.data.audit_logs);
        setTotalLogs(res.data.data.total);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log("Something went wrong:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = async (search_input = search, filter = "email") => {
    let search = search_input.trim();

    if (search !== "") {
      if (filter === "") {
        toast.error("Please select a search filter", {
          position: "bottom-right",
          theme: "light",
        });
      } else {
        let payload = {
          search_input: search,
          filter,
        };

        setIsLoading(true);

        try {
          const res = await api_admin_search_auditlog(
            page,
            rowsPerPage,
            payload
          );
          console.log("api search resp:", res);

          if (res.data.code === 200) {
            setData(res.data.data.auditlogs);
            setTotalLogs(res.data.data.total);
          } else {
            console.log(res.data.message);
          }
        } catch (error) {
          console.log("Something went wrong: ", error);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(true);
      try {
        await getUpdatedUsers("DESC");
      } catch (error) {
        console.log("Something went wrong: ", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || (e.key === "Backspace" && search === "")) {
      handleSearch(search, filter);
    }
  };

  function convertDateFormat(inputDate) {
    // Parse the input date string
    const parsedDate = new Date(inputDate);

    // Extract day, month, and year components
    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed, so add 1
    const year = parsedDate.getFullYear();

    // Format the date as DD-MM-YYYY
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  return (
    <>
      <div
        className="content"
      >
        <Header2 />
        <div className="breadcrumb-div">
          <h5
            style={{
              color: "#17256e",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Audit Logs
          </h5>
          <p>
            Home{" "}
            <span style={{ color: "#17256e", fontWeight: "bold" }}>
              <ArrowRightOutlinedIcon /> Audit Logs
            </span>
          </p>
        </div>
        <div className="content-wrap">
          <TableContainer component={Paper} style={{ background: "#f9f9f9" }}>
            <Grid
              container
              justifyContent="space-between"
              style={{ margin: "20px 0 0", padding: "0 20px" }}
            >
              <Grid item>
                <h6
                  style={{
                    color: "#17256e",
                    fontWeight: "bold",
                  }}
                >
                  Total Logs{" "}
                  <span
                    style={{
                      color: "white",
                      width: "20px",
                      borderRadius: "5px",
                      background: "#17256e",
                      padding: "2px 6px",
                      marginLeft: "10px",
                    }}
                  >
                    {totalLogs}
                  </span>
                </h6>
                <div>
                  <p>
                    Sort by :{" "}
                    <span
                      style={{
                        color: "#17256e",
                        fontWeight: "bold",
                      }}
                    >
                      <select
                        id="sort"
                        name="sort"
                        className={classes.userBtn}
                        onChange={(e) => {
                          setOrderType(e.target.value);
                          getUpdatedUsers(e.target.value);
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                          color: "#222831",
                          fontSize: "16px",
                          borderRadius: "12px",
                          fontWeight: "bold",
                          width: "auto",
                          cursor: "pointer",
                          marginLeft: "20px",
                          textAlign: "center",
                          border: "0px",
                          padding: "5px",
                        }}
                      >
                        <option value="DESC" className={classes.userBtn}>
                          Recent
                        </option>
                        <option value="ASC" className={classes.userBtn}>
                          Oldest
                        </option>
                      </select>
                    </span>{" "}
                  </p>
                </div>
              </Grid>
              <Grid item >
                <Grid container justifyContent="space-between" gap="10px">
                  <input
                    className={classes.searchBtn}
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                  ></input>
                  <Grid item>
                    <div
                      ref={dropdownRef}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <Button
                        variant="contained"
                        className={classes.userBtn}
                        onClick={toggleDropdown}
                        sx={userBtnStyle}
                        style={{ width: "auto" }}
                      >
                        <FilterAltOutlinedIcon className={classes.icon} />
                        Filter-by&nbsp;
                        <span
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {filter}
                        </span>
                      </Button>
                      {isOpen && (
                        <div className={classes.menu}>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("")}
                            value={""}
                          >
                            Clear
                          </div>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("email")}
                            value={"email"}
                          >
                            Email
                          </div>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("name")}
                            value={"name"}
                          >
                            Name
                          </div>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {totalLogs > 0 ? (
              <div className="table-responsive">
                <Table aria-label="user table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      User Type
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Name</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Email Address
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Endpoint Accessed
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{convertDateFormat(row.Created)}</TableCell>
                      <TableCell>{row.ctx.UserType}</TableCell>
                      <TableCell>{`${row.FirstName} ${row.LastName}`}</TableCell>
                      <TableCell>{row.Email}</TableCell>
                      <TableCell>{row.ctx.Endpoint}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "50vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isLoading ? (
                  <>
                    <CircularProgress />
                  </>
                ) : (
                  <h4>No logs found</h4>
                )}
              </div>
            )}
          </TableContainer>
        </div>
        {totalPages > 1 ? (
          <div
            className="bottom-bar"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#17256e",
                width: "100%",
                height: "100%",
                marginTop: "10px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                padding: "10px",
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                classes={{ root: classes.paginationRoot }}
                onChange={handlePageChange}
                sx={{ color: "white !important" }}
              />
            </div>
          </div>
        ) : null}
      </div>
      <ToastContainer />
    </>
  );
};

export default AuditLogs;
