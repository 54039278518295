import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";


const SidebarWithTopBar = ({
  onIconClick,
  data,
  selectedFiles,
  onFileSelect,
  setSelectedIcon,
}) => {
  // const icons = [
  //   {
  //     id: "inshore",
  //     icon: <DirectionsBoatFilledOutlinedIcon />,
  //     name: "Inshore",
  //   },
  //   { id: "lease_areas", icon: <WaterOutlinedIcon />, name: "Lease Areas" },
  //   { id: "raster", icon: <GradientOutlinedIcon />, name: "Raster" },
  // ];

  // const handleIconClick = (icon) => {
  //   setSelectedIcon(icon);
  //   if (onIconClick) {
  //     onIconClick(icon.id);
  //   }
  // };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    onFileSelect(id, checked); // Pass the change to the parent component
  };

  return (
    <Box>
      {/* Sidebar */}
      {/* <Box
        sx={{
          width: "100%",
          height: "50px",
          overflowY: "auto",
          backgroundColor: "#f0f0f0",
          borderRight: "1px solid #ddd",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
          padding: 0,
        }}
      >
        {icons.map((icon) => (
          <IconButton
            key={icon.id}
            onClick={() => handleIconClick(icon)}
          >
            {icon.icon}
          </IconButton>
        ))}
      </Box> */}

      {/* Main content */}
      <Box sx={{ width: "100%", padding: 0 }}>
        <Box p={2} sx={{ padding: 0 }}>
          <List sx={{ padding: 0 }}>
            {data ? (
              data?.map((file) => (
                <ListItem key={file.FileID}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={file.FileID}
                        checked={selectedFiles.includes(file.FileID)} // Bind the checkbox state to selectedFiles
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={
                      <Box
                      sx={{
                        width: "150px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        ":hover": {
                          overflowY: "hidden",
                          whiteSpace: "normal",
                          backgroundColor: "#f5f5f5",
                          zIndex: 1,
                          position: "relative",
                          padding: "5px",
                          width: "230px", // Allow it to adjust to the content size
                          textOverflow: "unset",
                        },
                      }}
                        title={file.FileName}
                      >
                        {file.FileName}
                      </Box>
                    }
                  />
                </ListItem>
              ))
            ) : (
              <h5
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "50px"
                }}
              >
                No record found
              </h5>
            )}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarWithTopBar;
