import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Header2 from "./Nevbar2";
import { Button, Grid, Pagination } from "@mui/material";
import ModalProvider from "./ModalProvider";
import { api_admin_delete_ocean, api_get_oceans } from "../api";
import { deleteButtonStyle, userBtnStyle } from "./TableStyles";
import AddNewOceanForm from "./AddNewOceanForm";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#ff0000",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
  },
  editButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#00ff00",
    "&:hover": {
      backgroundColor: "#00cc00",
    },
  },
  button: {
    textTransform: "none",
    borderRadius: "20px",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
    width: "100px",
    padding: "8px 20px",
  },
  userBtn: {
    textTransform: "none",
    borderRadius: "20px",
    color: "white",
    backgroundColor: "#17256e",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#101010",
    },
    width: "200px",
    padding: "8px 20px",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  menu: {
    display: "block",
    marginTop: "5px",
    position: "absolute",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    width: "auto",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    zIndex: "1",
  },
  list: {
    color: "black",
    padding: "5px 25px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "black",
    },
    display: "block",
    zIndex: "1",
    borderBottom: "1px solid #B5C0D0",
  },
  paginationRoot: {
    "& .MuiPaginationItem-root": {
      color: "white",
    },
  },
}));

const Oceans = () => {
  const classes = useStyles();
  const [deleteOcean, setDeleteOcean] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [addOcean, setAddOcean] = useState(false);
  const [confirmAddOcean, setConfirmAddOcean] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [orderType, setOrderType] = useState("DESC");
  const [densityDataToUpdate, setDensityDataToUpdate] = useState(null);
  const [total, setTotal] = useState(0);
  const totalPages = Math.ceil(total / rowsPerPage);
  const [isLoading, setIsLoading] = useState(false);

  const getUpdatedDensityData = async (orderType = "DESC") => {
    setIsLoading(true);

    try {
      const res = await api_get_oceans(page, rowsPerPage, orderType);

      if (res.data.code === 200) {
        setData(res.data.data.oceans);
        setTotal(res.data.data.total);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
      console.log("Something went wrong:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function convertDateFormat(inputDate) {
    // Parse the input date string
    const parsedDate = new Date(inputDate);

    // Extract day, month, and year components
    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed, so add 1
    const year = parsedDate.getFullYear();

    // Format the date as DD-MM-YYYY
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      await api_admin_delete_ocean({ id: densityDataToUpdate.id });

      const res = await api_get_oceans(page, rowsPerPage, orderType);
      setData(res.data.data.oceans);
      setTotal(res.data.data.total);
      setDeleteOcean(true);
      setTimeout(() => {
        setDeleteOcean(false);
      }, 3000);
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
      console.error("Error reloading species data after update:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const res = await api_get_oceans(page, rowsPerPage, orderType);
        if (res.data.code === 200) {
          setData(res.data.data.oceans);
          setTotal(res.data.data.total);
        } else {
          console.log(res.data.message);
        }
      } catch (error) {
        console.log("Something went wrong:", error);
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
        }}
      >
        <Header2 />
        <div style={{ marginTop: "20px", marginLeft: "7vh" }}>
          <h5
            style={{
              color: "#17256e",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Oceans
          </h5>
          <p>
            Home{" "}
            <span style={{ color: "#17256e", fontWeight: "bold" }}>
              <ArrowRightOutlinedIcon /> Oceans
            </span>
          </p>
        </div>
        <div style={{ padding: "0 50px", margin: "10px 0" }}>
          <TableContainer component={Paper} style={{ background: "#f9f9f9" }}>
            <Grid
              container
              justifyContent="space-between"
              style={{ margin: "20px 0 0", padding: "0 20px" }}
            >
              <Grid item>
                <h6
                  style={{
                    color: "#17256e",
                    fontWeight: "bold",
                  }}
                >
                  Oceans{" "}
                  <span
                    style={{
                      color: "white",
                      width: "20px",
                      borderRadius: "5px",
                      background: "#17256e",
                      padding: "2px 6px",
                      marginLeft: "10px",
                    }}
                  >
                    {total}
                  </span>
                </h6>
                <div>
                  <p>
                    Sort by :{" "}
                    <span
                      style={{
                        color: "#17256e",
                        fontWeight: "bold",
                      }}
                    >
                      <select
                        id="sort"
                        name="sort"
                        className={classes.userBtn}
                        onChange={(e) => {
                          setOrderType(e.target.value);
                          getUpdatedDensityData(e.target.value);
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                          color: "#222831",
                          fontSize: "16px",
                          borderRadius: "12px",
                          fontWeight: "bold",
                          width: "auto",
                          cursor: "pointer",
                          marginLeft: "20px",
                          textAlign: "center",
                          border: "0px",
                          padding: "5px",
                        }}
                      >
                        <option value="DESC" className={classes.userBtn}>
                          Recent
                        </option>
                        <option value="ASC" className={classes.userBtn}>
                          Oldest
                        </option>
                      </select>
                    </span>{" "}
                  </p>
                </div>
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between" gap="10px">
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.userBtn}
                      onClick={() => setAddOcean(true)}
                      sx={userBtnStyle}
                    >
                      Add Ocean
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {total > 0 ? (
              <Table aria-label="specie_data table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Date Added
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Ocean Name
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Ocean Starting Latitude
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Ocean Ending Latitude
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{convertDateFormat(row.created)}</TableCell>
                      <TableCell style={{ textTransform: "capitalize" }}>
                        {row.name}
                      </TableCell>
                      <TableCell>{row.ocean_starting_latitude}</TableCell>
                      <TableCell>{row.ocean_ending_latitude}</TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="deleteVessel"
                          onClick={() => {
                            setDensityDataToUpdate(row);
                            setConfirmDelete(true);
                          }}
                          className={classes.deleteButton}
                          sx={deleteButtonStyle}
                        >
                          <DeleteRoundedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "50vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isLoading ? (
                  <>
                    <CircularProgress />
                  </>
                ) : (
                  <h4>No oceans found</h4>
                )}
              </div>
            )}
          </TableContainer>
        </div>
        {totalPages > 1 ? (
          <div
            style={{ height: "60px", margin: "10px 0px", padding: "0 50px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#17256e",
                width: "100%",
                height: "100%",
                marginTop: "10px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                padding: "10px",
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                classes={{ root: classes.paginationRoot }}
                onChange={handlePageChange}
                sx={{ color: "white" }}
              />
            </div>
          </div>
        ) : null}
      </div>
      {deleteOcean && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "0px 40px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "5px", top: "0px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => setDeleteOcean(false)}
              />
            </div>
            <img
              src="/delete-icon.png"
              alt="delete"
              style={{ height: "80px", width: "80px" }}
            />
            <p
              style={{ fontSize: "20px", fontWeight: "700", color: "#17256E" }}
            >
              Ocean Successfully Deleted.
            </p>
            <p
              style={{ fontSize: "14px", fontWeight: "400", color: "#17256E" }}
            >
              This ocean is deleted from Portal.
            </p>
          </div>
        </ModalProvider>
      )}

      {confirmDelete && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                padding: "0px 40px",
              }}
            >
              <img
                src="/delete-icon.png"
                alt="delete"
                style={{ height: "30px", width: "30px" }}
              />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#17256E",
                  marginTop: "13px",
                }}
              >
                Oceans
              </p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#17256E" }}
            ></div>
            <div
              style={{
                display: "flex",
                gap: "4px",
                padding: "0px 40px",
                marginTop: "10px",
              }}
            >
              <img
                src="/user-remove.png"
                alt="delete"
                style={{ height: "30px", width: "30px" }}
              />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#17256E",
                  marginTop: "3px",
                }}
              >
                Are you sure you want to delete this ocean?
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                padding: "0px 40px",
                margin: "20px 0",
                flexWrap: "wrap",
              }}
            >
              <button
                style={{
                  borderRadius: "5px",
                  padding: "5px 60px",
                  color: "#17256E",
                  background: "#F2F2F2",
                  outline: "none",
                  border: "none",
                  boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  WebkitBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  MozBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                }}
                onClick={() => {
                  setConfirmDelete(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  borderRadius: "5px",
                  padding: "5px 60px",
                  background: "#17256E",
                  color: "white",
                  outline: "none",
                  border: "none",
                  boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  WebkitBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  MozBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                }}
                onClick={() => {
                  handleDelete();
                  setConfirmDelete(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </ModalProvider>
      )}
      {confirmAddOcean && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "40px 80px",
              paddingBottom: "20px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "25px", top: "15px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => {
                  setConfirmAddOcean(false);
                  getUpdatedDensityData();
                }}
              />
            </div>
            <img
              src="/approve-user.png"
              alt="approve"
              style={{ height: "80px", width: "80px" }}
            />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#17256E",
              }}
            >
              Ocean Successfully Added.
            </p>
          </div>
        </ModalProvider>
      )}
      {addOcean && (
        <AddNewOceanForm
          setAddOcean={setAddOcean}
          setConfirmAddOcean={setConfirmAddOcean}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default Oceans;
