import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../components/Navbar";
import img1 from "../pics/img5.jpg";
import '../components/css/form.css';


export default function EmailSent() {
  const divStyle = {
    backgroundImage: `url(${img1})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
  };

  return (
    <div style={divStyle}>
      <Container>
        <Header backgroundColor="#17256E" />
        <Card
          className='form-card'
        >
          <Card.Body className='form-card-body'>
            <Card.Title
              className="text-center form-card-title"
            >
              Email Sent
            </Card.Title>
            <div className='form-p-wrap'>
              <p
               className='form-p-text'
              >
                An email has been sent to your account. Please check your inbox
                and follow the link provided to proceed with the further
                process.
              </p>
            </div>
          </Card.Body>
        </Card>
        <ToastContainer />
      </Container>
    </div>
  );
}
