import React, { useState } from "react";
import Top from "./Top";
import VesselParameter from "./VesselParameter";
import Transit from "./Transit";
import Aggregator from "./Aggregator";
import Query from "./Query";
import InfoModal from "../InfoModal";
import Settings from "./Settings";
import { useAuth } from "../../Provider";

const SelectTool = () => {
  const { calculatorHandler } = useAuth();
  const [selected, setSelected] = useState("wind"); // transit => monthly , wind => daily
  const [modalOpen, setModalOpen] = useState(false);
  const [projectName, setProjectName] = useState(false);
  const [queryModalOpen, setQueryModalOpen] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [splitPoints, setSplitPoints] = useState(1000);

  return (
    <>
      <div className="select-tool-div">
        {/* <Top close={calculatorHandler}>Select Tool</Top> */}
        <Top close={calculatorHandler}>Select Tool</Top>

        <div
          style={{
            display: "flex",
          }}
        >
          <div className="select-tool-option">
            <div
              style={{
                backgroundColor:
                  selected === "wind" ? "rgb(23, 37, 110)" : "white",
                opacity: 0.9,
                color: selected === "wind" ? "white" : "black",
              }}
              className="select-tool-option-wrap"
              onClick={() => setSelected("wind")}
            >
              Wind Farm Daily
            </div>
            <div
              style={{
                backgroundColor:
                  selected === "transit" ? "rgb(23, 37, 110)" : "white",
                opacity: 0.9,
                color: selected === "transit" ? "white" : "black",
              }}
              className="select-tool-option-wrap"
              onClick={() => setSelected("transit")}
            >
              Transit Monthly
            </div>
            {/* <div
              style={{
                backgroundColor:
                  selected === "aggregator" ? "rgb(23, 37, 110)" : "white",
                opacity: 0.9,
                color: selected === "aggregator" ? "white" : "black",
              }}
              className="select-tool-option-wrap"
              onClick={() => setSelected("aggregator")}
            >
              Aggregator
            </div> */}
            {/* <div
              style={{
                backgroundColor:
                  selected === "settings" ? "rgb(23, 37, 110)" : "white",
                opacity: 0.9,
                color: selected === "settings" ? "white" : "black",
                
              }}
              className="select-tool-option-wrap"
              onClick={() => setSelected("settings")}
            >
              Settings
            </div> */}
          </div>

          {/* {(selected === "transit" || selected === "wind") && <Transit calculatorHandler={calculatorHandler} setModalOpen={setModalOpen} />}
                    {selected === "aggregator" && <Aggregator calculatorHandler={calculatorHandler} setQueryModalOpen={setQueryModalOpen} />}
                    {selected === "settings" && <Settings calculatorHandler={calculatorHandler} setQueryModalOpen={setQueryModalOpen} />} */}
          {(selected === "transit") && (
            <Transit
              selected={selected}
              setModalOpen={setModalOpen}
              setProjectName={setProjectName}
              setSplitPoints={setSplitPoints}
            />
          )}
          {(selected === "wind") && (
            <Transit
              selected={selected}
              setModalOpen={setModalOpen}
              setProjectName={setProjectName}
            />
          )}
          {selected === "aggregator" && (
            <Aggregator setQueryModalOpen={setQueryModalOpen} />
          )}
          {selected === "settings" && (
            <Settings setQueryModalOpen={setQueryModalOpen} />
          )}
        </div>
      </div>
      {modalOpen && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <VesselParameter
            selected={selected}
            projectName={projectName}
            close={() => calculatorHandler(false)}
            distributionPoints={splitPoints}
          />
        </div>
      )}

      {queryModalOpen && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 1000,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <Query
            close={() => setQueryModalOpen(false)}
            setInfoModal={setInfoModal}
          />
        </div>
      )}

      {infoModal && (
        <InfoModal close={() => setInfoModal(false)}>
          Proceding finished output aggregate filkes are in GUI workspace folder
        </InfoModal>
      )}
    </>
  );
};

export default SelectTool;
