import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";

const RightSlideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      sx={{
        position: "fixed",
        top: isOpen ? "0%" : "15%",
        right: 0,
        width: isOpen ? 360 : 60,
        height: isOpen ? "100%" : "40px",
        backgroundColor: "#f4f4f4",
        boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.5)",
        transition: "all 0.3s ease",
        overflowX: "hidden",
        borderTopLeftRadius: isOpen ?  "20px" : "10px",
        borderBottomLeftRadius: isOpen ?  "20px" : "10px",
        zIndex: 1245,
      }}
    >
      <IconButton
        onClick={toggleSidebar}
        sx={{
          position: "absolute",
          top: "12px",
          left: isOpen ? "10px" : "5px",
          width: "15px",
          height: "15px",
          // backgroundColor: "#333",
          color: "#333",
          // "&:hover": {
          //   backgroundColor: "#555",
          //   color: "white"
          // },
        }}
      >
        {isOpen ? <ChevronRightIcon /> : <h6 style={{position:"relative" , top:"3px" , left:"18px"}}>Layers</h6>}
      </IconButton>
      {isOpen && (
        <>
          {/* <IconButton
            onClick={toggleSidebar}
            sx={{
              position: 'absolute',
              top: '20px',
              right: '10px',
              width: '30px',
              height: '30px',
              backgroundColor: '#333',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#555',
              },
            }}
          >
            <CloseIcon />
          </IconButton> */}
          <Box
            sx={{
              // padding: "20px",
              marginTop: "50px",
              height: "90%px",
              overflowY: "auto",
            }}
          >
            {children}
          </Box>
        </>
      )}
    </Box>
  );
};

export default RightSlideBar;
