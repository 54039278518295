import React, { useContext } from 'react';
// import { AuthContext } from '../Provider';
import Top from './Components/Top';
import { FaCircleInfo } from 'react-icons/fa6';

const InfoModal = ({ close, children }) => {
  // const { calculatorHandler } = useContext(AuthContext);
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          width: '500px',
          height: '220px',
          borderRadius: '10px',
          position: 'absolute',
          zIndex: 1000,
        }}
      >
        <Top close={close}>Information</Top>

        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: 8,
              color: '#17256E',
              padding: '10px 20px',
            }}
          >
            <FaCircleInfo size={20} />
            <div
              style={{ marginBottom: 20, fontSize: 15, textAlign: 'center' }}
            >
              {children}
            </div>
          </div>
          <button
            style={{
              backgroundColor: '#CBCBCB',
              color: 'black',
              border: 'none',
              borderRadius: '5px',
              padding: '10px 20px',
              marginBottom: '10px',
              cursor: 'pointer',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
            // onClick={() => { calculatorHandler(); close() }}>ok</button>
            onClick={() => {
              close();
            }}
          >
            ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
