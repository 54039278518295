import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import {
  api_admin_upload_percentage_time_data,
  api_get_all_files,
  api_get_animals,
  api_get_locations,
  api_get_oceans,
} from "../api";
import { ToastContainer, toast } from "react-toastify";

const PercentageTimeDataUpload = ({
  cancelUpload,
  getUpdatedPercentageTimeData,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [month, setMonth] = useState("1");
  const [size, setSize] = useState("0");
  const [areaPerPixel, setAreaPerPixel] = useState("1");
  const [specieID, setSpecieID] = useState("");
  const [locationID, setLocationID] = useState("");
  const [fileType, setFileType] = useState("");
  const [animals, setAnimals] = useState([]);
  const [locations, setLocations] = useState([]);

  const handleFileChange = (newFiles) => {
    setFiles(newFiles);
    // Check if any file is uploaded
    if (newFiles.length > 0) {
      // Get the first file in the array (as you are limiting to 1 file)
      const uploadedFile = newFiles[0];
    }
  };

  const handleCancel = () => {
    setFiles([]);
    setMonth("");
    setSpecieID("");
    setFileType("");
    cancelUpload(false);
    getUpdatedPercentageTimeData();
  };

  const handleUpload = async () => {
    setIsLoading(true); // Set loading state to true

    try {
      const file = files[0];

      // Extract the file extension
      const fileExtension = file.name.split(".").pop();

      // Check if any field is empty
      if (!file || !specieID || !month) {
        toast.error("One or more fields are missing", {
          position: "bottom-right",
          theme: "light",
        });
        return;
      }

      // Check if the file extension matches the expected extension for the given file type
      if ("xlsx" !== fileExtension) {
        toast.error(
          `Invalid file extension! Expected .xlsx for Percentage time data`,
          {
            position: "bottom-right",
            theme: "light",
          }
        );
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("specie_id", specieID);
      formData.append("location_id", locationID);

      const res = await api_admin_upload_percentage_time_data(formData);

      if (res.data.code === 200) {
        toast.success(res.data.message || "File added successfully!", {
          position: "bottom-right",
          theme: "light",
        });
        handleCancel();
      }
    } catch (error) {
      if (error.response.data.code === 404) {
        toast.error(error?.response?.data?.data || "Specie not found", {
          position: "bottom-right",
          theme: "light",
        });
      } else {
        toast.error(error?.response?.data?.message || "Error uploading file", {
          position: "bottom-right",
          theme: "light",
        });
        console.log("Error uploading file:", error);
      }
    } finally {
      setIsLoading(false); // Set loading state to false in any case
    }
  };

  const getAnimals = () => {
    api_get_animals(1, 100, "ASC")
      .then((res) => {
        if (res.data.code === 200) {
          setAnimals(res.data.data.animals);
          setSpecieID(res.data?.data?.animals[0].id);
          console.log("default specie id ", res.data?.data?.animals[0].id);
        } else {
          console.log(res.data.message);
          toast.error(res.data.message, {
            position: "bottom-right",
            theme: "light",
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  const getLocations = () => {
    api_get_oceans(1, 100, "ASC")
      .then((res) => {
        // console.log("oceans resp",res.data.data.oceans);
        if (res.data.code === 200) {
          setLocations(res.data.data.oceans);
          setLocationID(res.data?.data?.oceans[0].id);
          //   console.log("default location id ",res.data?.data?.oceans[0].id);
        } else {
          console.log(res.data.message);
          toast.error(res.data.message, {
            position: "bottom-right",
            theme: "light",
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getAnimals();
    getLocations();
  }, []);

  return (
    <>
      <Box sx={{ marginBottom: "40px" }}>
        <DropzoneArea
          acceptedFiles={[]}
          filesLimit={1}
          onChange={handleFileChange}
        />
        <div
          style={{ marginTop: "10px", position: "relative" }}
          className="fileupload"
        >
          <div style={{ position: "absolute", top: "-105px", left: "20px" }}>
            <img src="/upload-dull.png" alt="upload" />
          </div>
          <div style={{ position: "absolute", top: "-72px", left: "80px" }}>
            <p
              style={{ fontWeight: "400", fontSize: "12px", color: "#BEBBBB" }}
            >
              Attach a percentage time data file here
            </p>
          </div>
          <p
            style={{
              fontSize: "12px",
              color: "#17256E",
              fontWeight: "400",
              marginTop: "20px",
            }}
          >
            Specie Name
          </p>
          <select
            placeholder="Select Species"
            id="speciesSelect"
            style={{
              border: "1px solid #C0BEBE",
              borderRadius: "8px",
              width: "100%",
              padding: "10px 15px",
              backgroundColor: "rgb(239, 239, 239)",
              outline: "none",
              fontSize: "12px",
            }}
            onChange={(e) => {
              setSpecieID(e.target.value);
            }}
          >
            {animals
              .filter((animal) => animal.status === "ACTIVE")
              .map((animal) => (
                <option key={animal.id} value={animal.id}>
                  {animal.name}
                </option>
              ))}
          </select>
          <p
            style={{
              fontSize: "12px",
              color: "#17256E",
              fontWeight: "400",
              marginTop: "20px",
            }}
          >
            Oceans
          </p>
          <select
            placeholder="Select Species"
            id="speciesSelect"
            style={{
              border: "1px solid #C0BEBE",
              borderRadius: "8px",
              width: "100%",
              padding: "10px 15px",
              backgroundColor: "rgb(239, 239, 239)",
              outline: "none",
              fontSize: "12px",
            }}
            onChange={(e) => {
              setLocationID(e.target.value);
            }}
          >
            {locations.map((location) => (
              <option key={location.id} value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        </div>
        <div style={{ maxWidth: "80%", margin: "0 auto", marginTop: "50px" }}>
          <Grid
            container
            justifyContent="space-around"
            sx={{ paddingRight: "20px", gap: "20px" }}
          >
            <Grid item>
              <Button
                onClick={handleCancel}
                style={{
                  color: "black",
                  background: "#F2F2F2",
                  padding: "10px 40px",
                  borderRadius: "11px",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  width: "200px",
                }}
                className="vra-modal-btn"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleUpload}
                style={{
                  color: "white",
                  height: "45px",
                  background: "#17256E",
                  padding: "10px 40px",
                  borderRadius: "11px",
                  boxShadow: "0px 4px 4px 0px #00000040",
                  width: "200px",
                }}
                className="vra-modal-btn"
              >
                {isLoading ? (
                  <div style={{ height: "40px" }}>
                    <CircularProgress />
                  </div>
                ) : (
                  "Upload"
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </Box>
      <ToastContainer />
    </>
  );
};

export default PercentageTimeDataUpload;
