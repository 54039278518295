import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Button } from "react-bootstrap";
import ModalWrapper from "./ModalProvider";
import { api_admin_update_user_info } from "../api";

const useStyles = makeStyles((theme) => ({
  userBtn: {
    textTransform: "none",
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e",
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59",
    },
    width: "fit-content",
    padding: "8px 30px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  updateBtn: {
    height: "45px",
    minWidth: "175px",
    textTransform: "none",
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e",
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59",
    },
    padding: "8px 62px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  cancelBtn: {
    height: "45px",
    minWidth: "175px",
    textTransform: "none",
    borderRadius: "10px",
    color: "#17256e",
    backgroundColor: "#f2f2f2",
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59",
    },
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  input: {
    borderRadius: "10px",
    padding: "6px 10px",
    width: "100%",
    outline: "none",
    border: "none",
    background: "#eee",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
}));

export default function EditUserForm({
  setConfirmEditUser,
  setEditUser,
  userToUpdate,
  onUpdateUser,
}) {
  const classes = useStyles();
  const [updatedUserData, setUpdatedUserData] = useState(userToUpdate); // State to hold updated user data

  const handleSubmitData = () => {
    api_admin_update_user_info(updatedUserData)
      .then((res) => {
        // window.location.reload()
        onUpdateUser(updatedUserData);
        // Close the edit user form
        setEditUser(false);
        setConfirmEditUser(true);
      })
      .catch((err) => {
        console.log("Something went wrong");
      });
  };

  const handleStatusChange = (e) => {
    setUpdatedUserData({
      ...updatedUserData,
      IsVerified: e.target.value === "true" ? true : false,
    });
  };

  // useEffect(() => {}, [userToUpdate]); // Log whenever userToUpdate prop changes

  return (
    <ModalWrapper>
      <div
        style={{
          display: "flex",
          gap: "8px",
          padding: "0px 30px",
          marginTop: "10px",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", right: "-30px", top: "-20px" }}>
          <img src="/add-new-user-dophine.png" alt="dophine" />
        </div>
        <img
          src="/user-edit.png"
          alt="edit"
          style={{ height: "30px", width: "30px" }}
        />
        <div
          style={{
            height: "10px",
            display: "flex",
            flexDirection: "column",
            marginTop: "-6px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "700",
              color: "#17256E",
            }}
          >
            Edit User Profile <br />
          </span>
          <span
            style={{
              fontSize: "12px",
              fontWeight: "500",
              color: "#9e9e9e",
              marginTop: "-4px",
            }}
          >
            Update User Profile Information
          </span>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <img src="/divider.png" alt="divider" />
      </div>
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: "20px",
          marginTop: "10px",
          padding: "0 70px",
          paddingBottom: "5px",
        }}
        className="input-x-wrap"
        justifyContent="space-between"
      >
        <Grid item xs={6} style={{ maxWidth: "45%" }}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            First Name
          </Typography>
          <input
            type="text"
            placeholder="First Name"
            className={classes.input + ' vra-modal-input'}
            value={updatedUserData?.FirstName}
            onChange={(e) =>
              setUpdatedUserData({
                ...updatedUserData,
                FirstName: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={6} style={{ maxWidth: "45%" }}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            Last Name
          </Typography>
          <input
            type="text"
            placeholder="Last Name"
            className={classes.input + ' vra-modal-input'}
            value={updatedUserData?.LastName}
            onChange={(e) =>
              setUpdatedUserData({
                ...updatedUserData,
                LastName: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        style={{ padding: "0 70px", marginBottom: "20px" }}
        className="input-x-wrap"
      >
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            Email Address
          </Typography>
          <input
            type="email"
            placeholder="Email Address"
            className={classes.input + ' vra-modal-input'}
            style={{ width: "100%" }}
            disabled
            value={updatedUserData?.Email}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        style={{ padding: "0 70px", marginBottom: "20px" }}
        className="input-x-wrap"

      >
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            Status
          </Typography>
          <select
            className={classes.input + ' vra-modal-input'}
            value={updatedUserData?.IsVerified.toString()}
            onChange={handleStatusChange}
            style={{ height: "36px" }}
          >
            <option value="true">Verified</option>
            <option value="false">Unverified</option>
          </select>
        </Grid>
      </Grid>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "10px",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <div style={{ width: "220px" }}>
          <Button
            variant="contained"
            className={classes.updateBtn + " w-100"}
            onClick={() => {
              handleSubmitData();
              setEditUser(false);
              setConfirmEditUser(true);
            }}
          >
            Save
          </Button>
        </div>
        <div style={{ width: "220px" }}>
          <Button
            variant="contained"
            className={classes.cancelBtn + " w-100"}
            onClick={() => setEditUser(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
}
