import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
// import { useEffect } from 'react';
import React, { useState } from 'react';
import "./App.css";
import Map from "./screens/Map";
import Sidebar from "./components/Sidebar";
import GetStart from "./components/Getstart";
import Login from "./screens/Login";
import Signup from "./screens/Signup";
import Datasets from "./screens/Datasets";
import Users from "./screens/Users";
import { AuthProvider, useAuth } from "./components/Provider";
import Profile from "./screens/Profile";
import ForgotPassword from "./screens/ForgotPassword";
import VerifyEmail from "./screens/VerifyEmail";
import VerificationCode from "./screens/VerificationCode";
import ResetPassword from "./screens/ResetPassword";
import { useEffect } from "react";
import EmailSent from "./screens/EmailSent";
import AuditLogs from "./components/AuditLogs";
import Animals from "./components/Animals";
import Vessels from "./components/Vessels";
import RasterData from "./components/RasterData";
import Oceans from "./components/Oceans";
import PercentageTimeData from "./components/PercentageTimeData";
import SpatialData from "./components/SpatialData";
import VesselTypeData from "./components/VesselTypeData";
import MonthSpeciesConstant from "./components/MonthSpeciesConstant";

function Layout({ children }) {
  const { authenticated } = useAuth();
  const navigate = useNavigate();
  const sidebar =
    authenticated &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/login" ? (
      <Sidebar />
    ) : null;

  useEffect(() => {
    // This logic needs updating.
    if (
      authenticated &&
      (window.location.pathname === "/" ||
        window.location.pathname === "/login" ||
        window.location.pathname === "/signup" ||
        window.location.pathname === "/signup-verify" ||
        window.location.pathname === "/verify-email" ||
        window.location.pathname === "/forgot-password" ||
        window.location.pathname === "/verify-otp-code")
    ) {
      navigate("/map");
    }
  }, [window.location.pathname, authenticated]);

  // State to manage the open class
  const [isOpen, setIsOpen] = useState(false);

  // Toggle function to handle click event
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div className={`${authenticated ? 'width-sidebar' : ''} ${isOpen ? 'open' : ''}`}>
        {/* Mobile sidebar opener button always shown when authenticated */}
        {authenticated && (
          <div>
            <div className="bars" onClick={toggleSidebar}>
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
            </div>
            <div className={`mobile-sidebar-opener ${isOpen ? 'open' : ''}`} onClick={toggleSidebar}>
              <div className="close">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x-octagon">
                  <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                  <line x1="15" y1="9" x2="9" y2="15"></line>
                  <line x1="9" y1="9" x2="15" y2="15"></line>
                </svg>
              </div>
            </div>
          </div>
        )}
        {sidebar}
      </div>
      {children}
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<GetStart />} />{/*check*/}
            <Route path="/signup" element={<Signup />} /> {/*check*/}
            <Route path="/signup-verify" element={<VerificationCode />} />   {/*check*/}
            <Route path="/reset-password" element={<ResetPassword />} /> {/*check*/}
            <Route path="/login" element={<Login />} /> {/*check*/}
            <Route path="/map" element={<Map />} /> {/*check*/}
            <Route path="/datasets" element={<Datasets />} /> {/*check*/}
            <Route path="/spatial-data" element={<SpatialData />} /> {/*check*/}
            <Route path="/users" element={<Users />} /> {/*check*/}
            <Route path="/audit-logs" element={<AuditLogs />} />{/*check*/}
            <Route path="/animals" element={<Animals />} /> {/*check*/}
            <Route path="/vessels" element={<Vessels />} /> {/*check*/}
            <Route path="/vessel-type-data" element={<VesselTypeData />} /> {/*check*/}
            <Route path="/oceans" element={<Oceans />} />  {/*check*/}
            <Route path="/raster-data" element={<RasterData />} /> {/*check*/}
            <Route path="/month-species-constant" element={<MonthSpeciesConstant />} /> {/*check*/}
            <Route
              path="/percentage-time-data"
              element={<PercentageTimeData />}
            />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/profile" element={<Profile />} />{/*check*/}
            <Route path="/forgot-password" element={<ForgotPassword />} />{/*check*/}
            <Route path="/verify-email" element={<VerifyEmail />} />{/*check*/}
            <Route path="/email-sent" element={<EmailSent />} />{/*check*/}
            {/* Redirect any other route to the root path */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;
