import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Button } from "react-bootstrap";
import ModalWrapper from "./ModalProvider";
import { api_admin_add_vessel, api_get_vessel_categories } from "../api";
import { ToastContainer, toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  userBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 30px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  updateBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "220px",
    padding: "8px 10px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  cancelBtn: {
    textTransform: "none",
    borderRadius: "10px",
    color: "#17256e",
    backgroundColor: "#f2f2f2",
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59",
    },
    width: "fit-content",
    padding: "8px 87px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  input: {
    borderRadius: "10px",
    padding: "6px 10px",
    width: "100%",
    outline: "none",
    border: "none",
    background: "#eee",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
}));

export default function AddNewVesselForm({
  setAddVessel: setAddVessel,
  setConfirmAddVessel: setConfirmAddVessel,
  setConfirmUpdateVessel,
  setUpdateVessel,
}) {
  const classes = useStyles();
  const [vesselCategories, setVesselCategories] = useState([]);
  const [vesselType, setVesselType] = useState({
    vessel_type: "",
    category: "",
    inshore_speed: "",
    open_ocean_speed: "",
    leaseareas_speed: "",
    length: "",
    width: "",
    draft: "",
  });

  const handleAddVessel = () => {
    api_admin_add_vessel(vesselType)
      .then((res) => {
        if (res.data.code === 200) {
          // Close the add vessel form
          setAddVessel(false);
          setConfirmAddVessel(true);
        }
      })
      .catch((err) => {
        if (err.response.data.data.message === "Vessel type already exists") {
          let vessel_id = err.response.data.data.vessel_id;
          setAddVessel(false);
          setConfirmUpdateVessel(true);
          setUpdateVessel({
            ...vesselType,
            vessel_id,
          });
        } else {
          toast.error("Something went wrong", {
            position: "bottom-right",
            theme: "light",
          });
        }
        console.log("Something went wrong", err);
      });
  };

  const getVesselCategories = () => {
    api_get_vessel_categories()
      .then((res) => {
        // console.log("vessel categories names resp: ", res);
        if (res.data.code === 200) {
          let categories = res?.data.data.vessel_categories;
          setVesselCategories(categories);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getVesselCategories();
  }, []);

  return (
    <ModalWrapper>
      <div
        style={{
          display: "flex",
          gap: "4px",
          padding: "0px 30px",
          marginTop: "10px",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", right: "-30px", top: "-20px" }}>
          <img src="/add-new-user-dophine.png" alt="dophine" />
        </div>
        <img
          src="/user-remove.png"
          alt="delete"
          style={{ height: "30px", width: "30px", display: "none" }}
        />
        <p
          style={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#17256E",
            marginTop: "3px",
            marginLeft: "38px",
          }}
        >
          Add Vessel
        </p>
      </div>
      <div style={{ maxWidth: "100%" }}>
        <img src="/divider.png" alt="divider" />
      </div>
      <div style={{ padding: "10px 70px" }} className="add-user-modal-wrap">
        <p style={{ fontSize: "14px", fontWeight: "500", maxWidth: "80%" }}>
          Please enter vessel information below to add a new vessel to the
          portal.
        </p>
      </div>
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: "20px",
          padding: "0 70px",
          paddingBottom: "5px",
        }}
        justifyContent="space-between"
        className="input-x-wrap"
      >
        <Grid item xs={6} style={{ maxWidth: "50%" }}>
          <Typography variant="subtitle1" className="vra-label">
            Vessel Type
          </Typography>
          <input
            type="text"
            placeholder="High Speed Transfer"
            className={classes.input}
            onChange={(e) => {
              setVesselType((prevVesselType) => ({
                ...prevVesselType,
                vessel_type: e.target.value,
              }));
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ maxWidth: "50%" }}>
          <Typography variant="subtitle1" className="vra-label">
            Vessel Category
          </Typography>
          <select
            placeholder="Select Category"
            onChange={(e) => {
              setVesselType((prevVesselType) => ({
                ...prevVesselType,
                category: e.target.value,
              }));
            }}
            id="vesselCategory"
            className={classes.input}
          >
            <option value="" disabled selected>
              Select Category
            </option>
            {vesselCategories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item xs={6} style={{ maxWidth: "50%" }}>
          <Typography variant="subtitle1" className="vra-label">
            Inshore Speed
          </Typography>
          <input
            type="number"
            placeholder="Inshore Speed"
            className={classes.input}
            onChange={(e) => {
              setVesselType((prevVesselType) => ({
                ...prevVesselType,
                inshore_speed: parseFloat(e.target.value),
              }));
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ maxWidth: "50%" }}>
          <Typography variant="subtitle1" className="vra-label">
            Open Ocean Speed
          </Typography>
          <input
            type="number"
            placeholder="Open Ocean Speed"
            className={classes.input}
            onChange={(e) => {
              setVesselType((prevVesselType) => ({
                ...prevVesselType,
                open_ocean_speed: parseFloat(e.target.value),
              }));
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ maxWidth: "50%" }}>
          <Typography variant="subtitle1" className="vra-label">
            Lease Areas Speed
          </Typography>
          <input
            type="number"
            placeholder="Lease Areas Speed"
            className={classes.input}
            onChange={(e) => {
              setVesselType((prevVesselType) => ({
                ...prevVesselType,
                leaseareas_speed: parseFloat(e.target.value),
              }));
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ maxWidth: "50%" }}>
          <Typography variant="subtitle1" className="vra-label">
            Length
          </Typography>
          <input
            type="number"
            placeholder="Length"
            className={classes.input}
            onChange={(e) => {
              setVesselType((prevVesselType) => ({
                ...prevVesselType,
                length: parseFloat(e.target.value),
              }));
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ maxWidth: "50%" }}>
          <Typography variant="subtitle1" className="vra-label">
            Width
          </Typography>
          <input
            type="number"
            placeholder="Width"
            className={classes.input}
            onChange={(e) => {
              setVesselType((prevVesselType) => ({
                ...prevVesselType,
                width: parseFloat(e.target.value),
              }));
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ maxWidth: "50%" }}>
          <Typography variant="subtitle1" className="vra-label">
            Draft
          </Typography>
          <input
            type="number"
            placeholder="Draft"
            className={classes.input}
            onChange={(e) => {
              setVesselType((prevVesselType) => ({
                ...prevVesselType,
                draft: parseFloat(e.target.value),
              }));
            }}
          />
        </Grid>
      </Grid>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "25px",
          margin: "30px 0",
          flexWrap: "wrap",
        }}
      >
        <Button
          variant="contained"
          className={classes.updateBtn + " vra-modal-btn"}
          style={{ maxWidth: "220px", height: "45px" }}
          onClick={() => {
            handleAddVessel();
          }}
        >
          Add Vessel
        </Button>
        <Button
          variant="contained"
          className={classes.cancelBtn + " vra-modal-btn"}
          style={{ maxWidth: "220px", height: "45px" }}
          onClick={() => setAddVessel(false)}
        >
          Cancel
        </Button>
      </div>
      <ToastContainer />
    </ModalWrapper>
  );
}
