import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Switch,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Header2 from "./Nevbar2";
import { Button, Grid, Pagination } from "@mui/material";
import ModalProvider from "./ModalProvider";
import EditUserForm from "./EditUserForm";
import {
  api_get_animals,
  api_admin_delete_animal,
  api_admin_deactivate_animal,
  api_admin_search_animal,
  api_get_month_species_constants,
  api_add_month_specie_constant,
  api_admin_delete_constant,
} from "../api";
import AddNewAnimalForm from "./AddNewAnimalForm";
import { userBtnStyle } from "./TableStyles";
import getMonthName from "./lib/GetMonth";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#ff0000",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
  },
  editButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#00ff00",
    "&:hover": {
      backgroundColor: "#00cc00",
    },
  },
  button: {
    textTransform: "none",
    borderRadius: "20px",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
    width: "100px",
    padding: "8px 20px",
  },
  searchBtn: {
    textTransform: "none",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "left",
    color: "black",
    border: "1px solid grey",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
    width: "200px",
    padding: "8px 20px",
  },
  userBtn: {
    textTransform: "none",
    borderRadius: "20px",
    color: "white",
    backgroundColor: "#17256e",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#101010",
    },
    width: "200px",
    padding: "8px 20px",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  menu: {
    display: "block",
    marginTop: "5px",
    position: "absolute",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    width: "auto",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    zIndex: "1",
  },
  list: {
    color: "black",
    padding: "5px 25px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "black",
    },
    display: "block",
    zIndex: "1",
    borderBottom: "1px solid #B5C0D0",
  },
  paginationRoot: {
    "& .MuiPaginationItem-root": {
      color: "white",
    },
  },
}));

const MonthSpeciesConstant = () => {
  const classes = useStyles();
  const [deleteConstant, setDeleteConstant] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmAddUser, setConfirmAddUser] = useState(false);
  const [confirmEditUser, setConfirmEditUser] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [orderType, setOrderType] = useState("DESC");
  const [constantToUpdate, setConstantToUpdate] = useState(null);
  const [total, setTotal] = useState(0);
  const totalPages = Math.ceil(total / rowsPerPage);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("name");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [animals, setAnimals] = useState([]);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const initialFormData = {
    specie_id: "",
    foraging: "",
    migrating: "",
    carf_rearing: "",
    sag: "",
    month: "",
  };

  const handleFilterSelection = (value = filter) => {
    setFilter(value);
    setIsOpen(false);
    if (value === "name") {
      handleSearch(search, value);
    } else {
      setSearch("");
      getUpdatedMonthSpeciesConstant("DESC");
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      const deleteRes = await api_admin_delete_constant({
        constant_id: constantToUpdate.id,
      });

      getUpdatedMonthSpeciesConstant();

      setTimeout(() => {
        setDeleteConstant(false);
      }, 3000);
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
      console.error("Error reloading animals after update:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMonthSpeciesConstant = () => {
    api_get_month_species_constants(page, rowsPerPage, orderType)
      .then((res) => {
        if (res.data.code === 200) {
          setData(res.data.data.constants);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getAnimals();
    getMonthSpeciesConstant();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        if (!search) {
          const res = await api_get_month_species_constants(
            page,
            rowsPerPage,
            orderType
          );
          if (res.data.code === 200) {
            setData(res.data.data.constants);
            setTotal(res.data.data.total);
          } else {
            console.log(res.data.message);
          }
        } else {
          handleFilterSelection(filter);
        }
      } catch (error) {
        console.log("Something went wrong:", error);
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const getUpdatedMonthSpeciesConstant = async (orderType = "ASC") => {
    setIsLoading(true);
    try {
      const res = await api_get_month_species_constants(
        page,
        rowsPerPage,
        orderType
      );

      if (res.data.code === 200) {
        setData(res.data.data.constants);
        setTotal(res.data.data.total);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log("Something went wrong:", error);
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = async (search_input = search, filter = "name") => {
    let searchValue = search_input.trim();

    if (searchValue !== "") {
      if (filter === "") {
        toast.error("Please select a search filter", {
          position: "bottom-right",
          theme: "light",
        });
      } else {
        let payload = {
          search_input: searchValue,
          filter,
        };

        setIsLoading(true);

        try {
          const res = await api_admin_search_animal(page, rowsPerPage, payload);
          console.log("api search resp:", res);

          if (res.data.code === 200) {
            setData(res.data.data.animals);
            setTotal(res.data.data.total);
          } else {
            console.log(res.data.message);
          }
        } catch (error) {
          console.log("Something went wrong: ", error);
          toast.error("Something went wrong", {
            position: "bottom-right",
            theme: "light",
          });
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      setIsLoading(true);
      try {
        await getUpdatedMonthSpeciesConstant("DESC");
      } catch (error) {
        console.log("Something went wrong: ", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // const handleKeyDown = (e) => {
  //     if (e.key === "Enter" || (e.key === "Backspace" && search === "")) {
  //         handleSearch(search, filter);
  //     }
  // };

  // function convertDateFormat(inputDate) {
  //     // Parse the input date string
  //     const parsedDate = new Date(inputDate);

  //     // Extract day, month, and year components
  //     const day = parsedDate.getDate().toString().padStart(2, "0");
  //     const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed, so add 1
  //     const year = parsedDate.getFullYear();

  //     // Format the date as DD-MM-YYYY
  //     const formattedDate = `${day}-${month}-${year}`;

  //     return formattedDate;
  // }

  // month species constant form
  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]:
        name === "month"
          ? parseInt(value, 10)
          : ["foraging", "migrating", "carf_rearing", "sag"].includes(name)
          ? parseFloat(value)
          : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    api_add_month_specie_constant(formData)
      .then((res) => {
        if (res.data.code === 200) {
          setFormData(initialFormData);
          getMonthSpeciesConstant();
        }
      })
      .catch((err) => {
        console.log("Something went wrong");
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  const getAnimals = () => {
    api_get_animals(1, 100, "ASC")
      .then((res) => {
        console.log("animals resp: ", res);
        if (res.data.code === 200) {
          setAnimals(res.data.data.animals);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  return (
    <>
      <div className="content">
        <Header2 />
        <div className="breadcrumb-div">
          <h5
            style={{
              color: "#17256e",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Manage Month Species Contant
          </h5>
          <p>
            Home{" "}
            <span style={{ color: "#17256e", fontWeight: "bold" }}>
              <ArrowRightOutlinedIcon /> Manage Month Species Contant
            </span>
          </p>
        </div>
        <div className="content-wrap">
          <TableContainer component={Paper} style={{ background: "#f9f9f9" }}>
            <Grid
              container
              justifyContent="space-between"
              style={{ margin: "20px 0 0", padding: "0 20px" }}
            >
              <Grid item>
                <h6
                  style={{
                    color: "#17256e",
                    fontWeight: "bold",
                  }}
                >
                  Month Species Contant{" "}
                  <span
                    style={{
                      color: "white",
                      width: "20px",
                      borderRadius: "5px",
                      background: "#17256e",
                      padding: "2px 6px",
                      marginLeft: "10px",
                    }}
                  >
                    {total}
                  </span>
                </h6>
                <div>
                  <p>
                    Sort by :{" "}
                    <span
                      style={{
                        color: "#17256e",
                        fontWeight: "bold",
                      }}
                    >
                      <select
                        id="sort"
                        name="sort"
                        className={classes.userBtn}
                        onChange={(e) => {
                          setOrderType(e.target.value);
                          getUpdatedMonthSpeciesConstant(e.target.value);
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                          color: "#222831",
                          fontSize: "16px",
                          borderRadius: "12px",
                          fontWeight: "bold",
                          width: "auto",
                          cursor: "pointer",
                          marginLeft: "20px",
                          textAlign: "center",
                          border: "0px",
                          padding: "5px",
                        }}
                      >
                        <option value="DESC" className={classes.userBtn}>
                          Recent
                        </option>
                        <option value="ASC" className={classes.userBtn}>
                          Oldest
                        </option>
                      </select>
                    </span>{" "}
                  </p>
                </div>
              </Grid>
              {/* <Grid item >
                <Grid container justifyContent="space-between" gap="10px">
                    <input
                        className={classes.searchBtn}
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        onKeyDown={handleKeyDown}
                    ></input>
                </Grid>
            </Grid> */}
            </Grid>

            {/* month species constant form starts  */}
            <form onSubmit={handleSubmit} className="animal-form">
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="animal-form-input"
                    type="text"
                    name="specie_id"
                    placeholder="Enter Specied Id"
                    value={formData.species_id}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      Select Species
                    </option>
                    {animals
                      .filter((animal) => animal.status === "ACTIVE")
                      .map((animal) => (
                        <option key={animal.id} value={animal.id}>
                          {animal.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-3">
                  <select
                    className="animal-form-input"
                    name="month"
                    placeholder="Enter Month"
                    value={formData.month}
                    onChange={handleChange}
                  >
                    <option value="" disabled selected>
                      Select Month
                    </option>
                    <option value={1}>January</option>
                    <option value={2}>Febuary</option>
                    <option value={3}>March</option>
                    <option value={4}>April</option>
                    <option value={5}>may</option>
                    <option value={6}>June</option>
                    <option value={7}>July</option>
                    <option value={8}>August</option>
                    <option value={9}>September</option>
                    <option value={10}>October</option>
                    <option value={11}>November</option>
                    <option value={12}>December</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <input
                    type="number"
                    name="foraging"
                    placeholder="Enter Foraging"
                    className="animal-form-input"
                    value={formData.foraging}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <input
                    className="animal-form-input"
                    type="number"
                    name="migrating"
                    placeholder="Enter Migrating"
                    value={formData.migrating}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <input
                    className="animal-form-input"
                    type="number"
                    name="carf_rearing"
                    placeholder="Enter Carf Rearing "
                    value={formData.carf_rearing}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-3">
                  <input
                    className="animal-form-input"
                    type="number"
                    name="sag"
                    placeholder="Enter Sag"
                    value={formData.sag}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-3">
                  <button type="submit">Submit</button>
                </div>
              </div>
            </form>
            {/* month species constant forms ends   */}
            {total > 0 ? (
              <div class="table-responsive">
                <Table aria-label="animal table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Animal Name
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Month
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Foraging
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Migrating
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Carf Rearing
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Sag</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.specie_id}</TableCell>
                        <TableCell>{getMonthName(row.month)}</TableCell>
                        <TableCell>{row.foraging}</TableCell>
                        <TableCell>{row.migrating}</TableCell>
                        <TableCell>{row.carf_rearing}</TableCell>
                        <TableCell>{row.sag}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="deleteConstant"
                            onClick={() => {
                              setConstantToUpdate(row);
                              setConfirmDelete(true);
                            }}
                            className={classes.deleteButton}
                          >
                            <DeleteRoundedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "50vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isLoading ? (
                  <>
                    <CircularProgress />
                  </>
                ) : (
                  <h4>No data found</h4>
                )}
              </div>
            )}
          </TableContainer>
        </div>
        {totalPages > 1 ? (
          <div className="bottom-bar">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#17256e",
                width: "100%",
                height: "100%",
                marginTop: "10px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                padding: "10px",
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                classes={{ root: classes.paginationRoot }}
                onChange={handlePageChange}
                style={{ color: "white" }}
              />
            </div>
          </div>
        ) : null}
      </div>
      {deleteConstant && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "0px 40px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "5px", top: "0px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => setDeleteConstant(false)}
              />
            </div>
            <img
              src="/delete-icon.png"
              alt="delete"
              style={{ height: "80px", width: "80px" }}
            />
            <p
              style={{ fontSize: "20px", fontWeight: "700", color: "#17256E" }}
            >
              Month Species Constant Successfully Deleted.
            </p>
            <p
              style={{ fontSize: "14px", fontWeight: "400", color: "#17256E" }}
            >
              This month species constant is deleted from Portal.
            </p>
          </div>
        </ModalProvider>
      )}

      {confirmDelete && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                padding: "0px 40px",
              }}
            >
              <img
                src="/delete-icon.png"
                alt="delete"
                style={{ height: "30px", width: "30px" }}
              />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#17256E",
                  marginTop: "13px",
                }}
              >
                Delete Month Specie Constant
              </p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#17256E" }}
            ></div>
            <div
              style={{
                display: "flex",
                gap: "4px",
                padding: "0px 40px",
                marginTop: "10px",
              }}
            >
              <img
                src="/user-remove.png"
                alt="delete"
                style={{ height: "30px", width: "30px" }}
              />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#17256E",
                  marginTop: "3px",
                }}
              >
                Are you sure you want to delete this constant?
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                padding: "0px 40px",
                margin: "20px 0",
                flexWrap: "wrap",
              }}
            >
              <button
                style={{
                  borderRadius: "5px",
                  padding: "5px 60px",
                  color: "#17256E",
                  background: "#F2F2F2",
                  outline: "none",
                  border: "none",
                  boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  WebkitBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  MozBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                }}
                onClick={() => {
                  setConfirmDelete(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  borderRadius: "5px",
                  padding: "5px 60px",
                  background: "#17256E",
                  color: "white",
                  outline: "none",
                  border: "none",
                  boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  WebkitBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  MozBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                }}
                onClick={() => {
                  handleDelete();
                  setDeleteConstant(true);
                  setConfirmDelete(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </ModalProvider>
      )}
      {(confirmAddUser || confirmEditUser) && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "40px 80px",
              paddingBottom: "20px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "25px", top: "15px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => {
                  setConfirmAddUser(false);
                  setConfirmEditUser(false);
                  getUpdatedMonthSpeciesConstant();
                }}
              />
            </div>
            <img
              src="/approve-user.png"
              alt="approve"
              style={{ height: "80px", width: "80px" }}
            />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#17256E",
              }}
            >
              Animal Successfully {confirmEditUser ? "Edited" : "Added"}.
            </p>
          </div>
        </ModalProvider>
      )}
      <ToastContainer />
    </>
  );
};

export default MonthSpeciesConstant;
