import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Button } from "react-bootstrap";
import ModalWrapper from "./ModalProvider";
import { api_admin_update_species_raster, api_get_animals } from "../api";
import { ToastContainer, toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  userBtn: {
    textTransform: "none",
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e",
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59",
    },
    width: "fit-content",
    padding: "8px 30px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  updateBtn: {
    height: "45px",
    minWidth: "175px",
    textTransform: "none",
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e",
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59",
    },
    padding: "8px 62px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  cancelBtn: {
    height: "45px",
    minWidth: "175px",
    textTransform: "none",
    borderRadius: "10px",
    color: "#17256e",
    backgroundColor: "#f2f2f2",
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59",
    },
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  input: {
    borderRadius: "10px",
    padding: "6px 10px",
    width: "100%",
    outline: "none",
    border: "none",
    background: "#eee",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
}));

export default function EditRasterForm({
  setConfirmEditRaster,
  setEditRaster,
  rasterToUpdate,
  onUpdateRaster,
}) {
  const classes = useStyles();
  const [updatedRasterData, setUpdatedRasterData] = useState(rasterToUpdate);
  const [animals, setAnimals] = useState([]);

  const handleSubmitData = async () => {
    try {
      const res = await api_admin_update_species_raster(
        updatedRasterData,
        updatedRasterData.id
      );
      onUpdateRaster(updatedRasterData);
      setEditRaster(false);
      setConfirmEditRaster(true);
    } catch (error) {
      console.log("Something went wrong:", error);
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
    }
  };

  const handleMonthChange = (e) => {
    setUpdatedRasterData({
      ...updatedRasterData,
      month: e.target.value,
    });
  };

  const handleFileName = (e) => {
    setUpdatedRasterData({
      ...updatedRasterData,
      file_name: e.target.value,
    });
  };

  const getAnimals = () => {
    api_get_animals(1, 100, "ASC")
      .then((res) => {
        if (res.data.code === 200) {
          setAnimals(res.data.data.animals);
        } else {
          console.log(res.data.message);
          toast.error(res.data.message, {
            position: "bottom-right",
            theme: "light",
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  const handleSpecieName = (e) => {
    setUpdatedRasterData({
      ...updatedRasterData,
      specie_name: e.target.value,
    });
  };

  const handleAreaPerPixel = (e) => {
    setUpdatedRasterData({
      ...updatedRasterData,
      area_per_pixel: e.target.value,
    });
  };

  useEffect(() => {
    getAnimals();
  }, []);


  return (
    <ModalWrapper>
      <div
        style={{
          display: "flex",
          gap: "0px",
          padding: "0px 30px",
          marginTop: "10px",
          position: "relative",
          width: "500px",
        }}
        className="box-p-0"
      >
        <div style={{ position: "absolute", right: "-30px", top: "-20px" }} class="modal-whale">
          <img src="/add-new-user-dophine.png" alt="dophine" />
        </div>  
        <div
          style={{ height: "30px", width: "38px" }} class="box-w-0">
          </div>
        <div
          style={{
            height: "10px",
            display: "flex",
            flexDirection: "column",
            marginTop: "-6px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "700",
              color: "#17256E",
            }}
          >
            Edit Raster Data<br />
          </span>
          <span
            style={{
              fontSize: "12px",
              fontWeight: "500",
              color: "#9e9e9e",
              marginTop: "-4px",
            }}
          >
            Update Raster Information
          </span>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <img src="/divider.png" alt="divider" />
      </div>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        style={{ padding: "0 70px", marginBottom: "20px" }}
        className="input-x-wrap"
      >
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            File Name
          </Typography>
          <input
            type="text"
            placeholder={updatedRasterData.file_name}
            className={classes.input}
            style={{ width: "100%" }}
            onChange={handleFileName}
            value={updatedRasterData?.file_name}
          />
        </Grid>
        </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        style={{ padding: "0 70px", marginBottom: "20px" }}
        className="input-x-wrap"

      >
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            Specie Name
          </Typography>
          {/* <input
            type="text"
            placeholder={updatedRasterData.specie}
            className={classes.input}
            style={{ width: "100%" }}
            onChange={handleSpecieName}
            value={updatedRasterData?.specie_name}
          /> */}
                      <select
              placeholder="Select Species"
              id="speciesSelect"
              className={classes.input}
              onChange={handleSpecieName}
            >
              {animals
                .filter((animal) => animal.status === "ACTIVE")
                .map((animal) => (
                  <option key={animal.id} value={animal.name}>
                    {animal.name}
                  </option>
                ))}
            </select>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        style={{ padding: "0 70px", marginBottom: "20px" }}
        className="input-x-wrap"

      >
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            Month
          </Typography>
          <select
            className={classes.input}
            value={updatedRasterData?.month?.toString()}
            onChange={handleMonthChange}
            style={{ height: "36px" }}
          >
            <option value="1">January</option>
            <option value="2">February</option>
            <option value="3">March</option>
            <option value="4">April</option>
            <option value="5">May</option>
            <option value="6">June</option>
            <option value="7">July</option>
            <option value="8">August</option>
            <option value="9">September</option>
            <option value="10">October</option>
            <option value="11">November</option>
            <option value="12">December</option>
          </select>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        style={{ padding: "0 70px", marginBottom: "20px" }}
        className="input-x-wrap"

      >
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            Area Per Pixel
          </Typography>
          <input
            type="text"
            placeholder={updatedRasterData.area_per_pixel}
            className={classes.input}
            style={{ width: "100%" }}
            onChange={handleAreaPerPixel}
            value={updatedRasterData?.area_per_pixel}
          />
        </Grid>
      </Grid>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          marginTop: "20px",
          marginBottom: "40px",
          flexWrap: "wrap",
        }}
      >
        <div>
          <Button
            variant="contained"
            className={classes.updateBtn + " vra-modal-btn"}
            onClick={() => {
              handleSubmitData();
              setEditRaster(false);
              setConfirmEditRaster(true);
            }}
          >
            Save
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            className={classes.cancelBtn + " vra-modal-btn"}
            onClick={() => setEditRaster(false)}
          >
            Cancel
          </Button>
        </div>
      </div>
      <ToastContainer />
    </ModalWrapper>
  );
}
