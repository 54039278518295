import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Navbar';
import img1 from '../pics/img5.jpg';
import { api_reset_password } from '../api'; // Make sure this is correctly implemented
import { Col, Row } from 'react-bootstrap';
import '../components/css/form.css';


export default function ForgotPassword() {
  const navigate = useNavigate();
  const divStyle = {
    backgroundImage: `url(${img1})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100vh',
  };

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    // Check if email is empty
    if (!email) {
      toast.error('Email required!', {
        position: 'bottom-right',
        theme: 'light',
      });
      setLoading(false);
      return;
    }

    // Perform any email format validation here if needed

    api_reset_password({ email: email })
      .then((res) => {
        navigate('/email-sent');
        const encryptedEmail = btoa(email);
        localStorage.setItem('encodedEmail', JSON.stringify(encryptedEmail));
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.code >= 400) {
          toast.error(err.response.data.data, {
            position: "bottom-right",
            theme: "light",
          });
        }
        setLoading(false);
      });
  };

  return (
    <div style={divStyle}>
      <Container>
        <Header backgroundColor="#17256E" />
        <Card
          className='form-card'
        >
          <Card.Body className='form-card-body'
          >
            <Card.Title
              className="text-center form-card-title" 
            >
              Forgot Your Password?
            </Card.Title>
            <div className='form-p-wrap'>
              <p
                className='form-p-text'
              >
                Enter the email address associated with your account to reset
                your password.
              </p>
            </div>
            <Form>
              <Form.Group className="mt-4" controlId="formBasicFirstName">
                <Form.Label
                  className='form-label'
                >
                  Email Address
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className='form-input'
                />
              </Form.Group>

              <Row className="justify-content-center">
                <Col xs="auto">
                  <Button
                    style={{
                      background: !loading ? '#17256E' : '#58639d',
                      fontStyle: loading ? 'italic' : '',
                      boxShadow: '0px 4px 4px 0px #00000040',
                    }}
                    className='form-button'
                    onClick={handleSubmit}
                  >
                    <span style={{ color: '#FDFDFE' }}>
                      {loading ? 'Sending Otp...' : 'Recover Password'}
                    </span>
                  </Button>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="auto">
                  <p
                   className='form-end-label'
                  >
                    <Link
                      to="/login"
                      className='form-end-label-link'
                    >
                      Back to login
                    </Link>
                  </p>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <ToastContainer />
      </Container>
    </div>
  );
}
