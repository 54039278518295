import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Button } from "react-bootstrap";
import ModalWrapper from "./ModalProvider";
import { api_admin_add_user } from "../api";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  userBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 30px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  updateBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 80px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  cancelBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "#17256e",
    backgroundColor: "#f2f2f2", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 87px",
    marginTop: "10px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  input: {
    borderRadius: "10px",
    padding: "6px 10px",
    width: "100%",
    outline: "none",
    border: "none",
    background: "#eee",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
}));

export default function AddNewUserForm({ setAddUser, setConfirmAddUser }) {
  const classes = useStyles();
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    is_verified: true,
  });

  const handleAddUser = () => {
    api_admin_add_user(userData)
      .then((res) => {
        if (res.data.code === 200) {
          // Close the add user form
          setAddUser(false);
          setConfirmAddUser(true);
          setConfirmAddUser(true);
        }
      })
      .catch((err) => {
        console.log('err => ', err?.response?.data);
        toast.error(err?.response?.data?.data, {
          position: 'bottom-right',
          theme: 'light',
        });
      });
  };

  const handleVerifiedChange = (e) => {
    setUserData({
      ...userData,
      is_verified: e.target.value === "true" ? true : false,
    });
  };

  return (
    <ModalWrapper>
      <div
        style={{
          display: "flex",
          gap: "4px",
          padding: "0px 30px",
          marginTop: "10px",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", right: "-30px", top: "-20px" }}>
          <img src="/add-new-user-dophine.png" alt="dophine" />
        </div>
        <img
          src="/user-remove.png"
          alt="delete"
          style={{ height: "30px", width: "30px" }}
        />
        <p
          style={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#17256E",
            marginTop: "3px",
          }}
        >
          Add User
        </p>
      </div>
      <div style={{ width: "100%" }}>
        <img src="/divider.png" alt="divider" />
      </div>
      <div class="add-user-modal-wrap">
        <p style={{ fontSize: "14px", fontWeight: "500", maxWidth: "80%" }}>
          Please enter the user details below to add a new user to the portal.
        </p>
      </div>
      <Grid
        container
        spacing={2}
        style={{
          marginBottom: "20px",
          padding: "0 70px",
          paddingBottom: "5px",
        }}
        className="input-x-wrap"
        justifyContent="space-between"
      >
        <Grid item xs={6} style={{ maxWidth: "45%" }}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            First Name
          </Typography>
          <input
            type="text"
            placeholder="First Name"
            className={classes.input}
            onChange={(e) => {
              setUserData({ ...userData, first_name: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ maxWidth: "45%" }}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            Last Name
          </Typography>
          <input
            type="text"
            placeholder="Last Name"
            className={classes.input}
            onChange={(e) => {
              setUserData({ ...userData, last_name: e.target.value });
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        style={{ padding: "0 70px" }}
        className="input-x-wrap"

      >
        <Grid item xs={6} style={{ maxWidth: "45%" }}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            Email Address
          </Typography>
          <input
            type="email"
            placeholder="Email Address"
            className={classes.input}
            onChange={(e) => {
              setUserData({ ...userData, email: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6} style={{ maxWidth: "45%" }}>
          <Typography
            variant="subtitle1"
            className="vra-label"
          >
            Verified
          </Typography>
          <select className={classes.input} onChange={handleVerifiedChange}>
            <option value="true">True</option>
            <option value="false">False</option>
          </select>
        </Grid>
      </Grid>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "5px",
          margin: "30px 0",
        }}
      >
        <Button
          variant="contained"
          className={classes.updateBtn}
          onClick={() => {
            handleAddUser();
          }}
        >
          Add User
        </Button>
        <Button
          variant="contained"
          className={classes.cancelBtn}
          onClick={() => setAddUser(false)}
        >
          Cancel
        </Button>
      </div>
      <ToastContainer />
    </ModalWrapper>
  );
}
