const getMonthName = (monthNumber) => {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    // Adjust the monthNumber to be zero-indexed (i.e., 0 = January, 11 = December)
    const index = monthNumber - 1;
  
    // Check if the monthNumber is valid (between 1 and 12)
    if (index < 0 || index > 11) {
      throw new Error("Invalid month number. It should be between 1 and 12.");
    }
  
    return monthNames[index];
  };
  
  export default getMonthName;
  