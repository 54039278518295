import axios from "axios";
import appConfig from "../appConfig";

export const API = axios.create({
  baseURL: appConfig.backend.baseURL,
});

// Add a response interceptor to handle 401 unauthorized errors
API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle 401 unauthorized error
      logoutAndRedirectToLogin(); // Call your logout function and navigate to login page
    }
    return Promise.reject(error);
  }
);

// Function to logout user and redirect to login page
const logoutAndRedirectToLogin = () => {
  // Remove token from localStorage or session storage
  localStorage.removeItem("isLoggedIn");
  localStorage.removeItem("userData");
  localStorage.removeItem("authToken");

  // Redirect to login page
  window.location.href = "/login";
};

//default header for the axios instance
export const setAuthHeader = (token) => {
  API.defaults.headers.common["authorization"] = `${token}`;
};

// Check for token on application initialization
const token = localStorage.getItem("authToken");
if (token) {
  setAuthHeader(token);
}

export const api_signup = (body) => API.post(`/api/v1/signup`, body);

export const api_signup_verify = (param) =>
  API.post(`/api/v1/signup_verify?u=${param}`);

export const api_login = (body) => API.post(`/api/v1/login`, body);

export const api_profile_pic = (body) =>
  API.post(`/api/v1/upload_profile_pic`, body);

export const api_reset_password = (body) => API.post("api/v1/reset_pw", body);

export const api_change_password = (param, body) =>
  API.post(`/api/v1/change_pw?reset_pw_token=${param}`, body);

export const api_update_user_info = (body) =>
  API.post(`/api/v1/update_user_info`, body);

// Admin endpoints.

export const api_get_all_users = (page, size, order_type) =>
  API.get(
    `/api/v1/db7814e7-acd9-4016-b731-b0618e3863bd/all_users?pg=${page}&size=${size}&order_type=${order_type}`
  );

export const api_get_audit_logs = (page, size, order_type) =>
  API.get(
    `/api/v1/757097d3-f171-404c-b2e0-565b029a42e7/audit_logs?pg=${page}&size=${size}&order_type=${order_type}`
  );

export const api_get_all_files = (page, size, order_type, fileTypes) =>
  API.get(
    `/api/v1/1b984178-919c-464e-93c8-ac13912c9a2a/all_files?pg=${page}&size=${size}&order_type=${order_type}&file_types=${fileTypes}`
  );

export const api_get_shape_files_info = (fileType, month = "", specie = "") =>
  API.get(`/api/v1/34076e60-dce2-4963-bc67-a4ae8c013b22/get_shape_files_info?file_type=${fileType}&raster_month=${month}&raster_specie=${specie}`);

export const api_get_animals = (page, size, order_type) =>
  API.get(
    `/api/v1/get_animals?pg=${page}&size=${size}&order_type=${order_type}`
  );

export const api_get_daily_transit = (page, size, order_type) =>
  API.get(
    `/api/v1/get_all_daily_transits`
  );

export const api_get_monthly_transit = (page, size, order_type) =>
  API.get(
    `/api/v1/get_all_monthly_transits`
  );

export const api_get_monthly_transit_geojson = (transitId) =>
  API.get(
    `/api/v1/get_monthly_transit_geojson?transit_id=${transitId}`
  );

export const api_get_month_species_constants = (page, size, order_type) =>
  API.get(
    `/api/v1/get_month_species_constants?pg=${page}&size=${size}&order_type=${order_type}`
  );

export const api_get_lease_areas_names = () =>
  API.get(
    `/api/v1/get_distinct_lease_areas_names`
  );

export const api_get_vessel_categories = () =>
  API.get(
    `/api/v1/get_vessel_categories`
  );

export const api_get_vessels = (page, size, order_type) =>
  API.get(
    `/api/v1/get_vessels?pg=${page}&size=${size}&order_type=${order_type}`
  );

export const api_get_species_data = (page, size, order_type, table_name) =>
  API.get(
    `/api/v1/get_species_data?table_name=${table_name}&pg=${page}&size=${size}&order_type=${order_type}`
  );

export const api_get_oceans = (page, size, order_type) =>
  API.get(
    `/api/v1/get_oceans?pg=${page}&size=${size}&order_type=${order_type}`
  );

export const api_get_species_raster = (page, size, order_type) =>
  API.get(
    `/api/v1/get_species_raster?pg=${page}&size=${size}&order_type=${order_type}`
  );

export const api_get_percentage_time_data = (page, size, order_type, filter_name, filter_value) =>
  API.get(
    `/api/v1/get_percentage_time_data?pg=${page}&size=${size}&order_type=${order_type}&filter_name=${filter_name}&filter_value=${filter_value}`
  );

export const api_download_species_raster = async (raster_id) => {
  API.get(`/api/v1/download_species_raster?raster_id=${raster_id}`, {
    responseType: "blob", // Ensure response type is set to 'blob' for binary data
    headers: {
      Accept: "application/octet-stream",
    },
  });
};

export const api_admin_file_upload = (body) =>
  API.post(`/api/v1/upload_file`, body);

export const api_admin_upload_species_raster = (body) =>
  API.post(`/api/v1/upload_species_raster`, body);

export const api_admin_upload_percentage_time_data = (body) =>
  API.post(`/api/v1/upload_percentage_time_data`, body);

export const api_admin_ban_user = (body) =>
  API.post(`/api/v1/05d8e2e1-5b6b-4316-9995-3c618ec5f3d7/ban_user`, body);

export const api_admin_delete_user = (body) =>
  API.delete(`/api/v1/387cdd44-0f72-424f-aadc-343790ace309/delete_user`, {
    data: body,
  });

export const api_admin_update_user_info = (body) =>
  API.post(
    `/api/v1/0f015cbe-9f51-47b7-bfdb-97165ca41528/update_user_info `,
    body
  );

export const api_admin_update_species_raster = (body, raster_id) =>
  API.post(`/api/v1/update_species_raster?raster_id=${raster_id} `, body);

export const api_admin_add_user = (body) =>
  API.post(
    `/api/v1/391bbc89-551f-41b4-968f-8467ac66c402/admin_add_user `,
    body
  );

export const api_admin_add_specie_data = (table_name, body) =>
  API.post(`/api/v1/add_specie_data?table_name=${table_name} `, body);

export const api_admin_add_ocean = (body) =>
  API.post(`/api/v1/add_ocean`, body);

export const api_admin_search_user = (page, size, body) =>
  API.post(
    `/api/v1/514ec141-aeb6-4d74-b8cf-d30ea115d5ff/search_user?pg=${page}&size=${size} `,
    body
  );

export const api_admin_search_auditlog = (page, size, body) =>
  API.post(
    `/api/v1/b89869d9-6abf-49ff-ab26-39719d638ec6/search_auditlog?pg=${page}&size=${size} `,
    body
  );

export const api_admin_search_animal = (page, size, body) =>
  API.post(`/api/v1/search_animal?pg=${page}&size=${size} `, body);

export const api_admin_deactivate_animal = (body) =>
  API.post(`/api/v1/animal_deactivate`, body);

export const api_admin_delete_animal = (body) =>
  API.delete(`/api/v1/animal_delete`, { data: body });

export const api_admin_delete_constant = (body) =>
  API.delete(`/api/v1/constant_delete`, { data: body });

export const api_admin_delete_vessel = (body) =>
  API.delete(`/api/v1/delete_vessel`, { data: body });

export const api_admin_delete_specie_data = (body) =>
  API.delete(`/api/v1/delete_specie_data`, { data: body });

export const api_admin_delete_ocean = (body) =>
  API.delete(`/api/v1/delete_ocean`, { data: body });

export const api_admin_delete_file = (body) =>
  API.delete(`/api/v1/delete_file`, { data: body });

export const api_admin_delete_species_raster = (raster_id) =>
  API.delete(`/api/v1/delete_species_raster?raster_id=${raster_id}`);

export const api_admin_add_animal = (body) =>
  API.post(`/api/v1/63cfd480-9958-4a68-bc91-5ce83c6bf1dd/add_animal`, body);

export const api_admin_add_vessel = (body) =>
  API.post(`/api/v1/add_vessel`, body);

export const api_admin_update_vessel = (body) =>
  API.post(`/api/v1/update_vessel`, body);

export const api_add_month_specie_constant = (body) =>
  API.post(`/api/v1/63cfd480-9958-4a68-bc91-5ce83c6bf1dd/add_species_constant`, body);

export const api_add_daily_transit = (body) =>
  API.post(`/api/v1/add_daily_transit`, body);

export const api_add_monthly_transit = (body) =>
  API.post(`/api/v1/add_monthly_transit`, body);
