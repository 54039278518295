import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Header from "../components/Navbar";
import img1 from "../pics/img5.jpg";
import { api_login } from "../api";
import { useAuth } from "../components/Provider";
import '../components/css/form.css';

function Login() {
  const divStyle = {
    backgroundImage: `url(${img1})`,
  };

  const navigate = useNavigate();

  const { login } = useAuth();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.email || !formData.password) {
      toast.error("Please fill all the fields!", {
        position: "bottom-right",
        theme: "light",
      });
      setLoading(false);
      return;
    }
    api_login(formData)
      .then((res) => {
        if (res.data.code === 1000) {
          setLoading(false);
          navigate("/map");
          const accessToken = res.data?.data.token;
          const { email, first_name, last_name, user_id, user_type } =
            res.data.data;

          // Constructing userData object with the remaining fields
          const userData = {
            email,
            first_name,
            last_name,
            user_id,
            user_type,
          };
          // store user_type
          login(accessToken, userData);
          localStorage.setItem(
            "profilePic",
            JSON.stringify(res.data.data.profile_image_url)
          );
          // storing token
          localStorage.setItem("authToken", accessToken);
        }
      })
      .catch((err) => {
        let errorCode = err.response.data.code;
        if (errorCode >= 400 && errorCode < 500) {
          toast.error(err.response.data.data ?? "Invalid credentials", {
            position: "bottom-right",
            theme: "light",
            autoClose: 8000,
          });
        } else if (errorCode >= 500) {
          toast.error("Something went wrong", {
            position: "bottom-right",
            theme: "light",
            autoClose: 8000,
          });
        }
        setLoading(false);
      });
  };

  return (
    <div style={divStyle} className="form-wrap">
      <Container>
        <Header backgroundColor="#17256E" />
        <Card
          className="form-card"
        >
          <Card.Body className="form-card-body">
            <Card.Title
              className="text-center form-card-title"
            >
              Log In
            </Card.Title>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mt-4" controlId="formBasicFirstName">
                <Form.Label
                  className="form-label"
                >
                  Email Address
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email Address"
                  name="email"
                  className="form-input"
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mt-4" controlId="formBasicLastName">
                <Form.Label
                  className="form-label"
                >
                  Password
                </Form.Label>
                <div style={{ position: "relative", flex: 2 }}>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    className="form-input"
                    onChange={handleChange}
                  />
                  <span
                    onClick={() => setShowPassword(!showPassword)}
                    className="form-input-eye"
                  >
                    {!showPassword ? (
                      <FiEyeOff style={{ color: "#0A1967" }} />
                    ) : (
                      <FiEye style={{ color: "#0A1967" }} />
                    )}
                  </span>
                </div>
              </Form.Group>
              <div
                className="forgot-password-div"
              >
                <Link
                  to="/forgot-password"
                  className="forgot-password-link"
                >
                  Forgot Password?
                </Link>
              </div>
              <Row className="justify-content-center">
                <Col xs="auto">
                  <Button
                    className="form-button"
                    style={{
                      background: !loading ? "#17256E" : "#58639d",
                      fontStyle: loading ? "italic" : "",
                    }}
                    type="submit"
                  >
                    {loading ? "Logging..." : "Login"}
                  </Button>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="auto">
                  <p
                    className="form-end-label"
                  >
                    Don’t have an Account?{" "}
                    <Link
                      to="/signup"
                      className="form-end-label-link"
                    >
                      Sign Up
                    </Link>
                  </p>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <ToastContainer />
    </div>
  );
}

export default Login;