import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Form, Button } from "react-bootstrap";
import Header2 from "./Nevbar2";
import FileUpload from "./FileUpload";
import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import UploadedFiles from "./UploadedFiles";
import { useAuth } from "./Provider";
import { useNavigate } from "react-router-dom";
import { api_get_all_files } from "../api";
import { toast } from "react-toastify";
import "./css/SpatialData.css";

function SpatialData() {
  const [fileUpload, setFileUpload] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [orderType, setOrderType] = useState("DESC");
  const [totalFiles, setTotalFiles] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState(data);
  const [page, setPage] = useState(1);
  const { authenticated } = useAuth();
  const navigate = useNavigate();

  const getUpdatedFiles = async () => {
    try {
      setIsLoading(true);
      let fileTypes = "inshore,lease_areas";
      const res = await api_get_all_files(
        page,
        rowsPerPage,
        orderType,
        fileTypes
      );

      if (res.data.code === 200) {
        setData(res.data.data.files);
        setTotalFiles(res.data.data.total);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;

    // If there is a search term, filter the data based on FileName
    if (searchTerm) {
      const results = data.filter((file) =>
        file.FileName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setData(results); // Update filtered files
    } else {
      getUpdatedFiles(); // Fetch all data if search term is empty
    }
  };

  const handleKeyDown = (e) => {
    const searchTerm = e.target.value;

    // Check if the Backspace key was pressed and the search term is empty
    if (e.key === "Backspace" && searchTerm === "") {
      getUpdatedFiles(); // Fetch all data
    }
  };

  useEffect(() => {
    if (!authenticated) {
      // Redirect to login page if not authenticated
      navigate("/login");
    }
    getUpdatedFiles();
  }, [authenticated]);

  return (
    <>
      {authenticated && (
        <div class="content">
          <Header2 backgroundColor="#323aa8" />
          <div class="content-wrap">
            <Card className="vra-content-card">
              <Card.Body>
                <Card.Title className="vra-card-heading">
                  <strong> Spactial Data</strong>
                </Card.Title>
                <Card className="vra-content-subcard">
                  <Card.Body className="card-filter-body">
                    <div className="card-filter-section">
                      <Form className="d-flex">
                        <Form.Control
                          className="filter-search-input me-2"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                          onChange={handleSearch}
                          onKeyDown={handleKeyDown}
                        />
                      </Form>
                      <Button
                        className="filter-add-button"
                        onClick={() => setFileUpload(true)}
                      >
                        Upload Files
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                    justifyContent: "space-around",
                  }}
                >
                  {data.length > 0 ? (
                    data
                      .filter(
                        (file) =>
                          file.FileType === "inshore" ||
                          file.FileType === "lease_areas"
                      )
                      .map((row) => (
                        <UploadedFiles
                          key={row.FileID}
                          id={row.FileID}
                          fileName={row.FileName}
                          fileUrl={row.FileURL}
                          fileType={row.FileType}
                          description={row.Description}
                          active={row.Active}
                          date={row.CreatedAt}
                          getUpdatedFiles={getUpdatedFiles}
                        />
                      ))
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        height: "50vh",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      {isLoading ? (
                        <>
                          <CircularProgress />
                        </>
                      ) : (
                        <h4>No files found</h4>
                      )}
                    </div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </div>
          {fileUpload && (
            <Dialog
              open={fileUpload}
              onClose={() => setFileUpload(false)}
              maxWidth="sm"
              fullWidth
              PaperProps={{
                elevation: 3,
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "white",
                  padding: 2,
                },
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "30px 0",
                  marginBottom: "1px",
                  width: "82%",
                  position: "relative",
                }}
              >
                <div
                  style={{ position: "absolute", right: "-12%", top: "-45%" }}
                >
                  <img src="/add-new-user-dophine.png" alt="dolphine" />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignContent: "center",
                    gap: "5px",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <img src="upload-p1.png" alt="upload" />
                    <img
                      src="upload-p2.png"
                      alt="upload"
                      style={{ position: "absolute", left: "57%" }}
                    />
                  </div>
                  <p
                    style={{
                      fontWeight: "700",
                      fontSize: "20px",
                      color: "#17256E",
                      marginTop: "10px",
                    }}
                  >
                    Upload File
                  </p>
                </div>
                <div>
                  <img
                    src="/divider.png"
                    alt="divider"
                    style={{
                      width: "100%",
                      marginLeft: "-73px",
                      height: "1px",
                    }}
                  />
                </div>
              </div>
              <DialogContent>
                <FileUpload
                  cancelUpload={setFileUpload}
                  getUpdatedFiles={getUpdatedFiles}
                />
              </DialogContent>
            </Dialog>
          )}
        </div>
      )}
    </>
  );
}

export default SpatialData;
