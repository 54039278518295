import { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Header2 from "../components/Nevbar2";
import Modal from "../components/Map/CustomModal";
import { useAuth } from "../components/Provider";
import RightSlideBar from "../components/RightSlideBar";
import "leaflet-draw/dist/leaflet.draw.css"; // Import leaflet-draw CSS
import "leaflet-draw"; // Import leaflet-draw JS
import { api_get_animals, api_get_shape_files_info } from "../api";
import { toast, ToastContainer } from "react-toastify";
import SidebarWithTopBar from "../components/SidebarWithTopbar";
import { Typography, Select, MenuItem, IconButton, Box } from "@mui/material";
import { Button } from "react-bootstrap";
import ProcessingSlide from "../components/ProcessingSlide";
import DirectionsBoatFilledOutlinedIcon from "@mui/icons-material/DirectionsBoatFilledOutlined";
import WaterOutlinedIcon from "@mui/icons-material/WaterOutlined";
import HouseboatIcon from '@mui/icons-material/Houseboat';
import GradientOutlinedIcon from "@mui/icons-material/GradientOutlined";
import blueMarker from "../assets/blue-marker.png";

function Map() {
  const [data, setData] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState({
    id: "inshore",
    icon: <DirectionsBoatFilledOutlinedIcon />,
    name: "Inshore",
  });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedSpecies, setSelectedSpecies] = useState("");
  const [animals, setAnimals] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const { openCalculator } = useAuth();
  const [activeIconId, setActiveIconId] = useState(null);
  const [geojsonLayers, setGeojsonLayers] = useState({});
  const mapRef = useRef(null);
  const layersRef = useRef([]);

  // Custom Icon
  const customIcon = new L.Icon({
    iconUrl: blueMarker,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -41],
  });

  // Apply the default marker icon globally
  // L.Marker.prototype.options.icon = customIcon;

  const fetchFileDetails = async (fileType, month, specie) => {
    // console.log("month",month);
    // console.log("specie",specie);
    // console.log("fileType",fileType);

    if (!(month && specie) && fileType === "raster") {
      setData([]);
      return;
    }
    try {
      const res = await api_get_shape_files_info(fileType, month, specie);
      if (res.data.code === 200) {
        setData(res.data.data);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log("Something went wrong:", error);
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
    }
  };

  useEffect(() => {
    // First time render
    handleIconClick({
      id: "inshore",
      icon: <DirectionsBoatFilledOutlinedIcon />,
      name: "Navigation Speed Zone",
    });
    getAnimals();
  }, []);

  const handleFilterSelection = (filter, value) => {
    if (filter === "month") {
      fetchFileDetails("raster", value, selectedSpecies);
    } else if (filter === "specie") {
      fetchFileDetails("raster", selectedMonth, value);
    }
  };

  const handleFileSelect = (fileId, isSelected) => {
    // console.log("fileType selected: ", selectedIcon?.name, isSelected);
    if (selectedIcon?.name === "Raster" && isSelected) {
      addTile(fileId);
    } else if (selectedIcon?.name === "Raster" && !isSelected) {
      removeLayer(fileId);
    } else if (isSelected) {
      addLayer(fileId);
    } else {
      removeLayer(fileId);
    }

    setSelectedFiles((prevSelectedFiles) =>
      isSelected
        ? [...prevSelectedFiles, fileId]
        : prevSelectedFiles.filter((id) => id !== fileId)
    );
  };

  const addTile = (fileId) => {
    let tilePath = `https://vra-static.s3.amazonaws.com/Tiles/${fileId}/{z}/{x}/{y}.png`;
    console.log("tile path of raster: ", tilePath);

    var newLayer = L.tileLayer(tilePath, {
      tms: 1,
      opacity: 0.2,
      minZoom: 1,
      maxZoom: 10,
    });

    // Add the tile layer to the map
    newLayer.addTo(mapRef.current);

    layersRef.current.push({ fileId, layer: newLayer });
  };

  const addLayer = (fileId) => {
    const fileType = getFileTypeById(data, fileId);
    if (fileType) {
      // Conditionally remove centeriods.
      const layerName =
        fileType === "inshore" || fileType === "ports_data"
          ? `vra:${fileType}`
          : `vra:${fileType},vra:${fileType}_points`;
      const viewparams = `file_id:${fileId}`;

      const newLayer = L.tileLayer
        .wms("https://geoserver.geohorizons.co/geoserver/vra/wms?", {
          layers: layerName,
          format: "image/png",
          transparent: true,
          version: "1.1.0",
          viewparams: viewparams,
        })
        .addTo(mapRef.current);

      layersRef.current.push({ fileId, layer: newLayer });
    }
  };

  const removeLayer = (fileId) => {
    const layerIndex = layersRef.current.findIndex(
      (layer) => layer.fileId === fileId
    );

    if (layerIndex !== -1) {
      mapRef.current.removeLayer(layersRef.current[layerIndex].layer);
      layersRef.current.splice(layerIndex, 1);
    }
  };

  const addGeoJson = (
    monthlyGeoJson,
    minExpectedStrikes,
    maxExpectedStrikes,
    id
  ) => {
    if (geojsonLayers[id]) return; // Prevent adding the same layer multiple times

    // Define interval ranges based on min and max
    const interval = (maxExpectedStrikes - minExpectedStrikes) / 5;

    // Color range function based on intervals
    const getColorForExpectedStrikes = (expectedStrikes) => {
      if (expectedStrikes > minExpectedStrikes + 4 * interval) return "#bd0026";
      if (expectedStrikes > minExpectedStrikes + 3 * interval) return "#f03b20";
      if (expectedStrikes > minExpectedStrikes + 2 * interval) return "#fd8d3c";
      if (expectedStrikes > minExpectedStrikes + interval) return "#fecc5c";
      if (expectedStrikes >= minExpectedStrikes) return "#ffffb2";
      return "#ffffff"; // Default color if none of the conditions match
    };

    // Create the GeoJSON layer with circle markers
    const geojsonLayer = L.geoJSON(monthlyGeoJson, {
      pointToLayer: function (feature, latlng) {
        // Get color based on expected strikes
        const fillColor = getColorForExpectedStrikes(
          feature.properties.expected_strikes
        );

        // Define marker options with the dynamic fillColor
        const geojsonMarkerOptions = {
          radius: 5,
          fillColor: fillColor, // "#ff7800"
          color: fillColor, // Border color "rgb(23, 37, 110)"
          weight: 1, // Border weight
          opacity: 0.9, // Border opacity
          fillOpacity: 0.8, // Fill opacity
        };

        // Return a circleMarker instead of a default marker
        return L.circleMarker(latlng, geojsonMarkerOptions);
      },
      onEachFeature: function (feature, layer) {
        // Set up the popup for each feature
        layer.on("click", function () {
          var props = feature.properties;
          var info = `
            <b>Density:</b> ${props.density}<br>
            <b>Latitude:</b> ${props.latitude}<br>
            <b>Longitude:</b> ${props.longitude}<br>
            <b>Region Type:</b> ${props.region_type}<br>
            <b>Expected Strikes:</b> ${props.expected_strikes}
          `;
          layer.bindPopup(info).openPopup();
        });
      },
    }).addTo(mapRef.current);

    // Save the layer in state to track it
    setGeojsonLayers((prevLayers) => ({ ...prevLayers, [id]: geojsonLayer }));

    // Adjust the map view to fit the bounds of the newly added GeoJSON layer
    mapRef.current.fitBounds(geojsonLayer.getBounds());
  };

  const removeGeoJson = (id) => {
    const layer = geojsonLayers[id];
    if (layer) {
      mapRef.current.removeLayer(layer);
      setGeojsonLayers((prevLayers) => {
        const { [id]: _, ...rest } = prevLayers; // Remove the layer from state
        return rest;
      });
    }
  };

  const getFileTypeById = (data, fileId) => {
    const file = data.find((file) => file.FileID === fileId);
    return file ? file.FileType : null;
  };

  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  const getAnimals = () => {
    api_get_animals(1, 100, "ASC")
      .then((res) => {
        if (res.data.code === 200) {
          setAnimals(res.data.data.animals);
        } else {
          console.log(res.data.message);
          toast.error(res.data.message, {
            position: "bottom-right",
            theme: "light",
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  const icons = [
    {
      id: "inshore",
      icon: <DirectionsBoatFilledOutlinedIcon />,
      name: "Navigation Speed Zone",
    },
    { id: "lease_areas", icon: <WaterOutlinedIcon />, name: "Lease Areas" },
    { id: "ports_data", icon: <HouseboatIcon />, name: "Ports Data" },
    { id: "raster", icon: <GradientOutlinedIcon />, name: "Raster" },
  ];

  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
    fetchFileDetails(icon.id);
    setActiveIconId(icon.id);
  };

  useEffect(() => {
    if (!mapRef.current) {
      // Initialize the map
      mapRef.current = L.map("map", {
        center: [38.10311125210718, -72],
        zoom: 6,
        zoomControl: false,
      });

      // Add OpenStreetMap base layer
      L.tileLayer(
        "https://server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}",
        {
          attribution:
            "Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri",
          maxZoom: 13,
        }
      ).addTo(mapRef.current);

      // Create a feature group to hold the editable layers
      const drawnItems = new L.FeatureGroup();
      mapRef.current.addLayer(drawnItems); // Add it to the map

      // Add leaflet-draw controls with edit and delete options enabled
      const drawControl = new L.Control.Draw({
        draw: {
          circle: false,
          marker: false, // Enable marker drawing
          polygon: false, // Enable polygon drawing
          polyline: true, // Enable polyline drawing
          rectangle: false, // Enable rectangle drawing
        },
        edit: {
          featureGroup: drawnItems, // Make this feature group editable
          remove: true, // Enable the delete button
        },
      });
      mapRef.current.addControl(drawControl); // Add draw controls to the map

      // Adjust the position of the control container
      setTimeout(() => {
        const controlContainer = document.querySelector(
          ".leaflet-control-container"
        );
        if (controlContainer) {
          controlContainer.style.position = "absolute";
          controlContainer.style.left = "10px";
          controlContainer.style.bottom = "90px";
          controlContainer.style.zIndex = "1000";
        }

        const drawToolbar = document.querySelector(".leaflet-draw-toolbar");
        if (drawToolbar) {
          drawToolbar.style.position = "absolute";
          drawToolbar.style.left = "0px";
          drawToolbar.style.bottom = "-13px";
          drawToolbar.style.zIndex = "1001";
        }

        // Hide the circle marker button
        const circleMarkerButton = document.querySelector(
          ".leaflet-draw-draw-circlemarker"
        );
        if (circleMarkerButton) {
          circleMarkerButton.style.display = "none";
        }
        const leafletControlAttribution = document.querySelector(
          ".leaflet-control-attribution"
        );
        if (leafletControlAttribution) {
          leafletControlAttribution.style.display = "none";
        }
      }, 100);

      // Event listener for when a new shape is created
      mapRef.current.on(L.Draw.Event.CREATED, (e) => {
        const { layer } = e;
        drawnItems.addLayer(layer); // Add the new layer to the editable group
        console.log("New layer drawn:", layer);

        // Capture the geometry of the drawn layer (line)
        const geom = layer.toGeoJSON().geometry;

        // Extract the distribution points (in this case, vertices of the polyline)
        const latlngPoints = layer.getLatLngs().map((latlng) => ({
          lat: latlng.lat,
          lng: latlng.lng,
        }));

        console.log("Geometry:", geom);
        console.log("Distribution Points:", latlngPoints);

        // Save the geometry and distribution points in localStorage
        localStorage.setItem(
          "selectedLine",
          JSON.stringify({
            geom,
            latlngPoints,
          })
        );

        // Log the saved information
        console.log("Saved line information in localStorage:", {
          geom,
          latlngPoints,
        });
      });

      // Event listener for when shapes are edited
      mapRef.current.on("draw:edited", (e) => {
        const layers = e.layers;
        layers.eachLayer((layer) => {
          console.log("Layer edited:", layer);
        });
      });

      // Event listener for when shapes are deleted
      mapRef.current.on("draw:deleted", (e) => {
        const layers = e.layers;
        layers.eachLayer((layer) => {
          console.log("Layer deleted:", layer);
        });
      });
    }
  }, []);

  return (
    <>
      <div id="map" style={{ height: "calc(100vh)", width: "100%" }}></div>

      <div className="map-header-width">
        <Header2
          map={mapRef.current}
          opacity={0.9}
          marginBottom="20px"
          backgroundColor="#323aa8"
        />
      </div>

      {openCalculator && <Modal />}

      <RightSlideBar>
        <Typography
          variant="h5"
          sx={{
            padding: "10px",
            color: "white",
            opacity: "0.9",
            backgroundColor: "rgb(23, 37, 110)",
            opacity: "0.9",
            borderBottom: "1px solid #ccc",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {selectedIcon ? `${selectedIcon.name}` : "Select an Icon"}
        </Typography>
        <Box
          sx={{
            width: "100%",
            height: "50px",
            overflowY: "auto",
            backgroundColor: "#f0f0f0",
            borderRight: "1px solid #ddd",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: 0,
          }}
        >
          {icons.map((icon) => (
            <IconButton
              key={icon.id}
              onClick={() => handleIconClick(icon)}
              className={activeIconId === icon.id ? "layer-active-btn" : ""}
            >
              {icon.icon}
            </IconButton>
          ))}
        </Box>

        {selectedIcon?.name === "Raster" ? (
          <div style={{ padding: "0 16px" }}>
            {/* <Typography variant="subtitle1">Filter by</Typography>
            <Select
              value={selectedFilter}
              onChange={(e) => {
                const selectedFilterType = e.target.value;
                setSelectedFilter(selectedFilterType);
                setSelectedMonth("");
                setSelectedSpecies("");
              }}
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                Select Filter Type
              </MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="species">Species</MenuItem>
            </Select> */}
            {/* {selectedFilter === "month" && ( */}
            <>
              <Typography variant="subtitle1" style={{ marginTop: "10px", fontWeight: "500" }}>
                Select Month
              </Typography>
              <Select
                value={selectedMonth}
                onChange={(e) => {
                  const selectedMonth = e.target.value;
                  handleFilterSelection("month", selectedMonth);
                  setSelectedMonth(selectedMonth);
                }}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select Month
                </MenuItem>
                {months.map((month) => (
                  <MenuItem key={month.value} value={month.value}>
                    {month.label}
                  </MenuItem>
                ))}
              </Select>
            </>
            {/* )} */}

            {/* {selectedFilter === "species" && ( */}
            <>
              <Typography variant="subtitle1" style={{ marginTop: "5px", fontWeight: "500" }}>
                Select Species
              </Typography>
              <Select
                value={selectedSpecies}
                onChange={(e) => {
                  const selectedSpecie = e.target.value;
                  handleFilterSelection("specie", selectedSpecie);
                  setSelectedSpecies(selectedSpecie);
                }}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select Species
                </MenuItem>
                {animals
                  .filter((animal) => animal.status === "ACTIVE")
                  .map((animal) => (
                    <MenuItem key={animal.id} value={animal.id}>
                      {animal.name}
                    </MenuItem>
                  ))}
              </Select>
            </>
            {/* )} */}
            <Button
              variant=""
              onClick={() => {
                setSelectedFilter("");
                setSelectedMonth("");
                setSelectedSpecies("");
                handleIconClick({
                  id: "raster",
                  icon: <GradientOutlinedIcon />,
                  name: "Raster",
                });
              }}
              style={{ marginTop: "10px" , fontWeight: "500", cursor: "pointer" , border: '1px solid #b7b7b7' }} 
            >
              Clear Filters
            </Button>
          </div>
        ) : null}
        <SidebarWithTopBar
          onIconClick={handleIconClick}
          data={data}
          selectedFiles={selectedFiles}
          onFileSelect={handleFileSelect}
          setSelectedIcon={setSelectedIcon}
        />
      </RightSlideBar>
      {
        <ProcessingSlide
          addGeoJson={addGeoJson}
          removeGeoJson={removeGeoJson}
        />
      }
      <ToastContainer />
    </>
  );
}

export default Map;
