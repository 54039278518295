import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Button } from "react-bootstrap";
import ModalWrapper from "./ModalProvider";
import {
  api_admin_add_specie_data,
  api_get_animals,
  api_get_vessels,
} from "../api";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  userBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 30px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  updateBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "white",
    backgroundColor: "#17256e", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 80px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  cancelBtn: {
    textTransform: "none", // To prevent text capitalization
    // Add any other styles you need for the button
    borderRadius: "10px",
    color: "#17256e",
    backgroundColor: "#f2f2f2", // Red color for delete button
    "&:hover": {
      color: "white",
      backgroundColor: "#101c59", // Darker shade of red on hover
    },
    width: "fit-content",
    padding: "8px 87px",
    marginTop: "10px",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
  input: {
    borderRadius: "10px",
    padding: "6px 10px",
    width: "100%",
    outline: "none",
    border: "none",
    background: "#eee",
    boxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Corrected box-shadow property
    WebkitBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)", // Vendor prefix for webkit browsers
    MozBoxShadow: "1px 1px 7px -2px rgba(0,0,0,0.75)",
  },
}));

export default function AddNewVesselTypeDataForm({
  setAddSpecieData: setAddSpecieData,
  setConfirmSpecieData,
}) {
  const classes = useStyles();
  const [animals, setAnimals] = useState([]);
  const [vessels, setVessels] = useState([]);
  const [specieDataType, setSpecieDataType] = useState("survey");
  const [specieData, setSpecieData] = useState({
    specie: "Fin whale",
    vessel_type: "Large Lmtd Mobility",
    average_speed: 0,
    percent_stationary: 0,
  });

  const handleAddSpecieData = () => {
    api_admin_add_specie_data(specieDataType, specieData)
      .then((res) => {
        if (res.data.code === 200) {
          setAddSpecieData(false);
          setConfirmSpecieData(true);
        }
      })
      .catch((err) => {
        console.log("Something went wrong ", err.response.data.data[0]);
        setAddSpecieData(false);
        toast.error(err.response.data.data[0], {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  const getAnimals = () => {
    api_get_animals(1, 100, "ASC")
      .then((res) => {
        if (res.data.code === 200) {
          setAnimals(res.data.data.animals);
        } else {
          console.log(res.data.message);
          toast.error(res.data.message, {
            position: "bottom-right",
            theme: "light",
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  const getVessels = () => {
    api_get_vessels(1, 100, "ASC")
      .then((res) => {
        if (res.data.code === 200) {
          setVessels(res.data.data.vessels);
        } else {
          console.log(res.data.message);
          toast.error(res.data.message, {
            position: "bottom-right",
            theme: "light",
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getAnimals();
    getVessels();
  }, []);

  return (
    <>
      <ModalWrapper>
        <div
          style={{
            display: "flex",
            gap: "4px",
            padding: "0px 30px",
            marginTop: "10px",
            position: "relative",
          }}
        >
          <div style={{ position: "absolute", right: "-30px", top: "-20px" }}>
            <img src="/add-new-user-dophine.png" alt="dophine"  />
          </div>
          <img
            src="/user-remove.png"
            alt="delete"
            style={{ height: "30px", width: "30px", display: "none" }}
          />
          <p
            style={{
              fontSize: "20px",
              fontWeight: "700",
              color: "#17256E",
              marginTop: "3px",
              marginLeft: "38px",
            }}
            className="heading-m-0"
          >
            Add Specie Data
          </p>
        </div>
        <div style={{ width: "100%" }}>
          <img src="/divider.png" alt="divider" />
        </div>
        <div style={{ padding: "10px 70px" }} className="add-user-modal-wrap">
          <p style={{ fontSize: "14px", fontWeight: "500", maxWidth: "80%" }}>
            Please enter the specie data below to add a new specie to the
            portal.
          </p>
        </div>
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: "20px",
            padding: "0 70px",
            paddingBottom: "5px",
          }}
          justifyContent="space-between"
          className="input-x-wrap dash"
        >
          <Grid item xs={6} style={{ maxWidth: "50%" }}>
            <Typography
              variant="subtitle1"
              className="vra-label"
            >
              Specie Name
            </Typography>
            <select
              placeholder="Select Species"
              id="speciesSelect"
              className={classes.input + ' vra-modal-input'}
              onChange={(e) => {
                setSpecieData({ ...specieData, specie: e.target.value });
              }}
            >
              {animals
                .filter((animal) => animal.status === "ACTIVE")
                .map((animal) => (
                  <option key={animal.id} value={animal.name}>
                    {animal.name}
                  </option>
                ))}
            </select>
          </Grid>
          <Grid item xs={6} style={{ maxWidth: "50%" }}>
            <Typography
              variant="subtitle1"
              className="vra-label"
            >
              Vessel Type
            </Typography>
            <select
              placeholder="Select Species"
              id="speciesSelect"
              className={classes.input + ' vra-modal-input'}
              style={{ textTransform: "Capitalize" }}
              onChange={(e) => {
                setSpecieData({ ...specieData, vessel_type: e.target.value });
              }}
            >
              {vessels.map((vessel) => (
                <option key={vessel.id} value={vessel.vessel_type}>
                  {vessel.vessel_type}
                </option>
              ))}
            </select>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: "20px",
            padding: "0 70px",
            paddingBottom: "5px",
          }}
          justifyContent="space-between"
          className="input-x-wrap dash"
        >
          <Grid item xs={6} style={{ maxWidth: "50%" }}>
            <Typography
              variant="subtitle1"
              className="vra-label"
            >
              Specie Data Type
            </Typography>
            <select
              placeholder="Select Species"
              id="speciesSelect"
              className={classes.input + ' vra-modal-input'}
              style={{ textTransform: "Capitalize" }}
              onChange={(e) => {
                setSpecieDataType(e.target.value);
              }}
            >
              <option value="survey">Survey</option>
              <option value="om">OM</option>
              <option value="construction">Construction</option>
            </select>
          </Grid>
          <Grid item xs={6} style={{ maxWidth: "50%" }}>
            <Typography
              variant="subtitle1"
              className="vra-label"
            >
              Average Speed
            </Typography>
            <input
              type="text"
              placeholder="Average Speed"
              className={classes.input + ' vra-modal-input'}
              onChange={(e) => {
                setSpecieData({
                  ...specieData,
                  average_speed: parseFloat(e.target.value),
                });
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          style={{ padding: "0 70px" }}
          className="input-x-wrap dash"
        >
          <Grid item xs={6} style={{ maxWidth: "50%" }}>
            <Typography
              variant="subtitle1"
              className="vra-label"
            >
              Percent Stationary
            </Typography>
            <input
              type="text"
              placeholder="Percent Stationary"
              className={classes.input + ' vra-modal-input'}
              onChange={(e) => {
                setSpecieData({
                  ...specieData,
                  percent_stationary: parseFloat(e.target.value),
                });
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "5px",
            margin: "30px 0",
          }}
        >
          <Button
            variant="contained"
            className={classes.updateBtn}
            onClick={() => {
              handleAddSpecieData();
            }}
          >
            Add Data
          </Button>
          <Button
            variant="contained"
            className={classes.cancelBtn}
            onClick={() => setAddSpecieData(false)}
          >
            Cancel
          </Button>
        </div>
      </ModalWrapper>
      <ToastContainer />
    </>
  );
}
