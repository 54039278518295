import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Header2 from "./Nevbar2";
import { Button, Dialog, DialogContent, Grid, Pagination } from "@mui/material";
import ModalProvider from "./ModalProvider";
import EditUserForm from "./EditUserForm";
import { api_get_species_data, api_admin_delete_specie_data } from "../api";
import AddNewVesselTypeDataForm from "./AddNewVesselTypeDataForm";
import { deleteButtonStyle, userBtnStyle } from "./TableStyles";
import SpecieDataUpload from "./SpecieDataUpload";

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#ff0000",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
  },
  editButton: {
    borderRadius: "50%",
    color: "white",
    backgroundColor: "#00ff00",
    "&:hover": {
      backgroundColor: "#00cc00",
    },
  },
  button: {
    textTransform: "none",
    borderRadius: "20px",
    color: "black",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#cc0000",
    },
    width: "100px",
    padding: "8px 20px",
  },
  searchBtn: {
    textTransform: "none",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "left",
    color: "black",
    border: "1px solid grey",
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
    width: "200px",
    padding: "8px 20px",
  },
  userBtn: {
    textTransform: "none",
    borderRadius: "20px",
    color: "white",
    backgroundColor: "#17256e",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "#101010",
    },
    width: "200px",
    padding: "8px 20px",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  menu: {
    display: "block",
    marginTop: "5px",
    position: "absolute",
    backgroundColor: "#f9f9f9",
    borderRadius: "10px",
    width: "auto",
    boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)",
    zIndex: "1",
  },
  list: {
    color: "black",
    padding: "5px 25px",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#e0e0e0",
      color: "black",
    },
    display: "block",
    zIndex: "1",
    borderBottom: "1px solid #B5C0D0",
  },
  paginationRoot: {
    "& .MuiPaginationItem-root": {
      color: "white",
    },
  },
}));

const VesselTypeData = () => {
  const classes = useStyles();
  const [deleteSpecieData, setDeleteSpecieData] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [addSpecieData, setAddSpecieData] = useState(false);
  const [confirmAddSpecieData, setConfirmSpecieData] = useState(false);
  const [confirmEditSpecieData, setConfirmEditSpecieData] = useState(false);
  const [editSpecieData, setEditSpecieData] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [orderType, setOrderType] = useState("DESC");
  const [specieDataToUpdate, setSpecieDataToUpdate] = useState(null);
  const [total, setTotal] = useState(0);
  const totalPages = Math.ceil(total / rowsPerPage);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("survey");
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterSelection = (value = filter) => {
    setFilter(value);
    console.log("filter", value);
    if (value !== "") {
      getUpdatedSpecieData(orderType, value);
    } else {
      toast.error("Select filter first", {
        position: "bottom-right",
        theme: "light",
      });
    }
  };

  const getUpdatedSpecieData = async (
    orderType = "DESC",
    filter = "survey"
  ) => {
    setIsLoading(true);

    try {
      const res = await api_get_species_data(
        page,
        rowsPerPage,
        orderType,
        filter
      );

      if (res.data.code === 200) {
        setData(res.data.data.species);
        setTotal(res.data.data.total);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
      console.log("Something went wrong:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleDelete = async () => {
    setIsLoading(true);

    try {
      await api_admin_delete_specie_data({
        id: specieDataToUpdate.id,
        table_name: filter,
      });

      const res = await api_get_species_data(
        page,
        rowsPerPage,
        orderType,
        filter
      );
      setData(res.data.data.species);
      setTotal(res.data.data.total);

      setTimeout(() => {
        setDeleteSpecieData(false);
      }, 3000);
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
        theme: "light",
      });
      console.error("Error reloading species data after update:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateVessel = (updatedUserData) => {
    // Find the index of the user in the data array
    const userIndex = data.findIndex((user) => user.id === updatedUserData.id);

    // If the user is found, update the data array with the updated user data
    if (userIndex !== -1) {
      const newData = [...data];
      newData[userIndex] = updatedUserData;
      setData(newData);
    }
  };

  useEffect(() => {
    if (!search) {
      api_get_species_data(page, rowsPerPage, orderType, filter)
        .then((res) => {
          if (res.data.code === 200) {
            setData(res.data.data.species);
            setTotal(res.data.data.total);
          } else {
            console.log(res.data.message);
          }
        })
        .catch((error) => {
          console.log("Something went wrong");
          toast.error("Something went wrong", {
            position: "bottom-right",
            theme: "light",
          });
        });
    } else {
      handleFilterSelection(filter);
    }
  }, [page]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        if (!search) {
          const res = await api_get_species_data(
            page,
            rowsPerPage,
            orderType,
            filter
          );
          if (res.data.code === 200) {
            setData(res.data.data.species);
            setTotal(res.data.data.total);
          } else {
            console.log(res.data.message);
          }
        } else {
          handleFilterSelection(filter);
        }
      } catch (error) {
        console.log("Something went wrong:", error);
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div
        className="content"
      >
        <Header2 />
        <div className="breadcrumb-div">
          <h5
            style={{
              color: "#17256e",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            Manage Vessel Type Data
          </h5>
          <p>
            Home{" "}
            <span style={{ color: "#17256e", fontWeight: "bold" }}>
              <ArrowRightOutlinedIcon /> Manage Vessel Type Data
            </span>
          </p>
        </div>
        <div className="content-wrap">
          <TableContainer component={Paper} style={{ background: "#f9f9f9" }}>
            <Grid
              container
              justifyContent="space-between"
              style={{ margin: "20px 0 0", padding: "0 20px" }}
            >
              <Grid item>
                <h6
                  style={{
                    color: "#17256e",
                    fontWeight: "bold",
                  }}
                >
                  Species Data{" "}
                  <span
                    style={{
                      color: "white",
                      width: "20px",
                      borderRadius: "5px",
                      background: "#17256e",
                      padding: "2px 6px",
                      marginLeft: "10px",
                    }}
                  >
                    {total}
                  </span>
                </h6>
                <div>
                  <p>
                    Sort by :{" "}
                    <span
                      style={{
                        color: "#17256e",
                        fontWeight: "bold",
                      }}
                    >
                      <select
                        id="sort"
                        name="sort"
                        className={classes.userBtn}
                        onChange={(e) => {
                          setOrderType(e.target.value);
                          getUpdatedSpecieData(e.target.value);
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                          color: "#222831",
                          fontSize: "16px",
                          borderRadius: "12px",
                          fontWeight: "bold",
                          width: "auto",
                          cursor: "pointer",
                          marginLeft: "20px",
                          textAlign: "center",
                          border: "0px",
                          padding: "5px",
                        }}
                      >
                        <option value="DESC" className={classes.userBtn}>
                          Recent
                        </option>
                        <option value="ASC" className={classes.userBtn}>
                          Oldest
                        </option>
                      </select>
                    </span>{" "}
                  </p>
                </div>
              </Grid>
              <Grid item>
                <Grid container justifyContent="space-between" gap="10px">
                  {/* <input
                    className={classes.searchBtn}
                    placeholder="Search"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                  ></input> */}
                  <Grid item>
                    <div
                      ref={dropdownRef}
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <Button
                        variant="contained"
                        className={classes.userBtn}
                        sx={userBtnStyle}
                        onClick={toggleDropdown}
                        style={{ width: "auto" }}
                      >
                        <FilterAltOutlinedIcon className={classes.icon} />
                        Filter-by&nbsp;
                        <span
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {filter}
                        </span>
                      </Button>
                      {isOpen && (
                        <div className={classes.menu}>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("survey")}
                            value={"survey"}
                          >
                            Survey
                          </div>
                          <div
                            className={classes.list}
                            onClick={() => handleFilterSelection("om")}
                            value={"om"}
                          >
                            OM
                          </div>
                          <div
                            className={classes.list}
                            onClick={() =>
                              handleFilterSelection("construction")
                            }
                            value={"construction"}
                          >
                            Construction
                          </div>
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      className={classes.userBtn}
                      onClick={() => setAddSpecieData(true)}
                      sx={userBtnStyle}
                    >
                      Add Vessel Type Data
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={classes.userBtn}
                      sx={userBtnStyle}
                      onClick={() => setFileUpload(true)}
                    >
                      Upload Files
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {total > 0 ? (
              <div class="table-responsive">
                <Table aria-label="specie_data table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Species
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Vessel Type
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Average Speed
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Percent Stationary
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Inserted By
                      </TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell style={{ textTransform: "capitalize" }}>
                          {row.species}
                        </TableCell>
                        <TableCell style={{ textTransform: "capitalize" }}>
                          {row.vesseltype}
                        </TableCell>
                        <TableCell>{row.average_speed}</TableCell>
                        <TableCell>{row.percent_stationary}</TableCell>
                        <TableCell>{row.inserted_by}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="deleteVessel"
                            onClick={() => {
                              setSpecieDataToUpdate(row);
                              setConfirmDelete(true);
                            }}
                            className={classes.deleteButton}
                            sx={deleteButtonStyle}
                          >
                            <DeleteRoundedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height: "50vh",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isLoading ? (
                  <>
                    <CircularProgress />
                  </>
                ) : (
                  <h4>No species data found</h4>
                )}
              </div>
            )}
          </TableContainer>
        </div>
        {totalPages > 1 ? (
          <div
            class="bottom-bar"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#17256e",
                width: "100%",
                height: "100%",
                marginTop: "10px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                padding: "10px",
              }}
            >
              <Pagination
                count={totalPages}
                page={page}
                classes={{ root: classes.paginationRoot }}
                onChange={handlePageChange}
                sx={{ color: "white" }}
              />
            </div>
          </div>
        ) : null}
      </div>
      {deleteSpecieData && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "0px 40px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "5px", top: "0px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => setDeleteSpecieData(false)}
              />
            </div>
            <img
              src="/delete-icon.png"
              alt="delete"
              style={{ height: "80px", width: "80px" }}
            />
            <p
              style={{ fontSize: "20px", fontWeight: "700", color: "#17256E" }}
            >
              Specie Data Successfully Deleted.
            </p>
            <p
              style={{ fontSize: "14px", fontWeight: "400", color: "#17256E" }}
            >
              This specie data is deleted from Portal.
            </p>
          </div>
        </ModalProvider>
      )}

      {confirmDelete && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              position: "relative",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                padding: "0px 40px",
              }}
            >
              <img
                src="/delete-icon.png"
                alt="delete"
                style={{ height: "30px", width: "30px" }}
              />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#17256E",
                  marginTop: "13px",
                }}
              >
                Delete Specie Data
              </p>
            </div>
            <div
              style={{ width: "100%", height: "1px", background: "#17256E" }}
            ></div>
            <div
              style={{
                display: "flex",
                gap: "4px",
                padding: "0px 40px",
                marginTop: "10px",
              }}
            >
              <img
                src="/user-remove.png"
                alt="delete"
                style={{ height: "30px", width: "30px" }}
              />
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#17256E",
                  marginTop: "3px",
                }}
              >
                Are you sure you want to delete this specie data?
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
                padding: "0px 40px",
                margin: "20px 0",
                flexWrap: "wrap"

              }}
            >
              <button
                style={{
                  borderRadius: "5px",
                  padding: "5px 60px",
                  color: "#17256E",
                  background: "#F2F2F2",
                  outline: "none",
                  border: "none",
                  boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  WebkitBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  MozBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                }}
                onClick={() => {
                  setConfirmDelete(false);
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  borderRadius: "5px",
                  padding: "5px 60px",
                  background: "#17256E",
                  color: "white",
                  outline: "none",
                  border: "none",
                  boxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  WebkitBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                  MozBoxShadow: "0px 2px 6px -1px rgba(0,0,0,0.4)",
                }}
                onClick={() => {
                  handleDelete();
                  setDeleteSpecieData(true);
                  setConfirmDelete(false);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </ModalProvider>
      )}
      {(confirmAddSpecieData || confirmEditSpecieData) && (
        <ModalProvider>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "5px",
              padding: "40px 80px",
              paddingBottom: "20px",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "25px", top: "15px" }}>
              <img
                src="/cross-icon.png"
                alt="cross"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
                onClick={() => {
                  setConfirmSpecieData(false);
                  setConfirmEditSpecieData(false);
                  getUpdatedSpecieData();
                }}
              />
            </div>
            <img
              src="/approve-user.png"
              alt="approve"
              style={{ height: "80px", width: "80px" }}
            />
            <p
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: "#17256E",
              }}
            >
              Specie Data Successfully{" "}
              {confirmEditSpecieData ? "Edited" : "Added"}.
            </p>
          </div>
        </ModalProvider>
      )}
      {addSpecieData && (
        <AddNewVesselTypeDataForm
          setAddSpecieData={setAddSpecieData}
          setConfirmSpecieData={setConfirmSpecieData}
        />
      )}
      {editSpecieData && (
        <EditUserForm
          setEditUser={setEditSpecieData}
          setConfirmEditUser={setConfirmEditSpecieData}
          userToUpdate={specieDataToUpdate}
          onUpdateUser={handleUpdateVessel}
        />
      )}
      {fileUpload && (
        <Dialog
          open={fileUpload}
          onClose={() => setFileUpload(false)}
          maxWidth="sm"
          fullWidth
          PaperProps={{
            elevation: 3,
            sx: {
              display: "flex",
              flexDirection: "column",
              backgroundColor: "white",
              padding: 2,
            },
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "30px 0",
              marginBottom: "1px",
              width: "82%",
              position: "relative",
            }}
          >
            <div style={{ position: "absolute", right: "-12%", top: "-45%" }}>
              <img src="/add-new-user-dophine.png" alt="dolphine" class="modal-img-hidden" />
            </div>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                gap: "5px",
              }}
            >
              <div style={{ position: "relative" }}>
                <img src="upload-p1.png" alt="upload" />
                <img
                  src="upload-p2.png"
                  alt="upload"
                  style={{ position: "absolute", left: "57%" }}
                />
              </div>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "20px",
                  color: "#17256E",
                  marginTop: "10px",
                }}
              >
                Upload File
              </p>
            </div>
            <div>
              <img
                src="/divider.png"
                alt="divider"
                style={{
                  width: "100%",
                  marginLeft: "-73px",
                  height: "1px",
                }}
              />
            </div>
          </div>
          <DialogContent>
            <SpecieDataUpload
              cancelUpload={setFileUpload}
              getUpdatedFiles={getUpdatedSpecieData}
            />
          </DialogContent>
        </Dialog>
      )}
      <ToastContainer />
    </>
  );
};

export default VesselTypeData;
