import React, { useState } from "react";
import Top from "./Components/Top";
import Tooltip from "@mui/material/Tooltip";
import { FaQuestionCircle } from "react-icons/fa";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import InfoModal from "./InfoModal";
import { api_get_animals } from "../../api";
import { toast } from "react-toastify";
import { useEffect } from "react";

const label = { inputProps: { "aria-label": "Switch demo" } };

const AnimallPerameterModal = ({ close }) => {
  const [infoModal, setInfoModal] = useState(false);
  const [data, setData] = useState([]);
  const onCalculate = () => {
    setInfoModal(true);
  };

  const getAnimals = () => {
    api_get_animals(1, 100, "ASC")
      .then((res) => {
        console.log("animals resp: ", res);
        if (res.data.code === 200) {
          setData(res.data.data.animals);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          position: "bottom-right",
          theme: "light",
        });
      });
  };

  useEffect(() => {
    getAnimals();
  }, []);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1000,
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "600px",
            height: "480px",
            borderRadius: "10px",
            position: "relative",
            zIndex: 20,
          }}
        >
          <Top titleCenter={true} close={close}>
            Animals Parameters
          </Top>

          <div style={{ color: "#17256E", fontWeight: "bold", marginTop: 10 }}>
            Species
          </div>
          <div>
            <select
              placeholder="Select Species"
              id="speciesSelect"
              style={{
                width: "150px",
                borderRadius: "5px",
                border: "1px solid #CBCBCB",
                padding: "0 10px",
                marginTop: 10,
              }}
            >
              {data
                .filter((animal) => animal.status === "ACTIVE")
                .map((animal) => (
                  <option key={animal.id} value={animal.name}>
                    {animal.name}
                  </option>
                ))}
            </select>
            <Tooltip
              placement="right-start"
              title="Explanation will be inserted here..."
            >
              <IconButton>
                <FaQuestionCircle size={22} />
              </IconButton>
            </Tooltip>
          </div>

          <div
            style={{
              backgroundColor: "#EBEBEB",
              width: "80%",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
              marginTop: 10,
              padding: "10px 20px",
              marginBottom: 10,
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#17256E",
                marginTop: 10,
              }}
            >
              Animal Population
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                fontWeight: "bold",
                color: "#17256E",
                marginTop: 15,
              }}
            >
              <div>
                <div>CalfCow(Percentage)</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    style={{
                      width: "130px",
                      height: "30px",
                      borderRadius: "5px",
                      border: "1px solid #CBCBCB",
                      padding: "0 10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Tooltip
                    placement="right-start"
                    title="Explanation will be inserted here..."
                  >
                    <IconButton>
                      <FaQuestionCircle size={22} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              <div>
                <div>SAGs(Percentage)</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 5,
                  }}
                >
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    style={{
                      width: "130px",
                      height: "30px",
                      borderRadius: "5px",
                      border: "1px solid #CBCBCB",
                      padding: "0 10px",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }}
                  />
                  <Tooltip
                    placement="right-start"
                    title="Explanation will be inserted here..."
                  >
                    <IconButton>
                      <FaQuestionCircle size={22} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              backgroundColor: "#EBEBEB",
              width: "80%",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "5px",
              marginTop: 10,
              padding: "10px 20px",
              marginBottom: 40,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#17256E",
              fontWeight: "bold",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "80%",
                gap: 10,
              }}
            >
              <div style={{ width: "45%" }}>Foraging (Percentage)</div>
              <Slider
                aria-label="Temperature"
                defaultValue={0}
                valueLabelDisplay="auto"
                step={2}
                marks
                min={0}
                max={100}
                style={{ width: "55%" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "80%",
                gap: 10,
              }}
            >
              <div style={{ width: "45%" }}>Foraging (Percentage)</div>
              <Slider
                aria-label="Temperature"
                defaultValue={0}
                valueLabelDisplay="auto"
                step={2}
                marks
                min={0}
                max={100}
                style={{ width: "55%" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "80%",
                gap: 10,
              }}
            >
              <div style={{ width: "45%" }}>Foraging (Percentage)</div>
              <div style={{ width: "55%" }}>
                <Switch {...label} defaultChecked />
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 80,
              marginBottom: "20px",
            }}
          >
            <button
              style={{
                backgroundColor: "#CBCBCB",
                color: "black",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
                marginBottom: "10px",
                cursor: "pointer",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              onClick={close}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: "#17256E",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
                marginBottom: "10px",
                cursor: "pointer",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
              onClick={onCalculate}
            >
              Calculate Expected ncounter
            </button>
          </div>
        </div>
      </div>

      {infoModal && (
        <InfoModal close={() => setInfoModal(false)}>
          Expected number of animal encounter during month along the route is
          0.6211
        </InfoModal>
      )}
    </>
  );
};

export default AnimallPerameterModal;
