import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../components/Navbar';
import img1 from '../pics/img5.jpg';
import '../components/css/form.css';


export default function VerifyEmail() {
  const divStyle = {
    backgroundImage: `url(${img1})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100vh',
  };
  // const [formData, setFormData] = useState({
  //   email: '',
  //   password: '',
  // });

  // const [showPassword, setShowPassword] = useState(false);

  // const togglePasswordVisibility = () => {
  //   setShowPassword(!showPassword);
  // };

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  return (
    <div style={divStyle}>
      <Container>
        <Header backgroundColor="#17256E" />
        <Card
          className='form-card'
        >
          <Card.Body className='form-card-body'
          >
            <Card.Title
              className="text-center form-card-title"
            >
              Verify Your Email
            </Card.Title>
            <div className='form-p-wrap'>
              <p
                className='form-p-text' 
              >
                To complete recovering password, please verify your email
                address.
              </p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mt-4" controlId="formBasicFirstName">
                <Form.Label
                  className='form-label'
                >
                  Email Address
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Email Address"
                  className='form-input'
                />
              </Form.Group>

              <Row className="justify-content-center">
                <Col xs="auto">
                  <Button
                    style={{
                      marginTop: '40px',
                      marginBottom: '40px',
                      background: '#17256E',
                      boxShadow: '0px 4px 4px 0px #00000040',
                    }}
                    className='form-button'
                  >
                    <Link
                      to="/verify-otp"
                      style={{ color: 'white', textDecoration: 'none' }}
                    >
                      <span style={{ color: '#FDFDFE' }}>Verify</span>
                    </Link>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <ToastContainer />
      </Container>
    </div>
  );
}
