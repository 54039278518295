import React from "react";
import img2 from "../pics/whale.png";
import Header from "./Navbar"; // Check the import path
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { Row, Container, Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import "./css/Getstart.css";

const GetStart = () => {
  return (
    <div class="Getstart-wrap">
      <div style={{ position: "relative" }}>
        <Header opacity="0.7" />
        <div>
          <div class="whale-wrap"></div>
        </div>

        <Container>
          <Row style={{ color: "white" }}>
            <Col
              className="banner-section-wrap"
              xl={{ order: 1 }}
              lg={{ order: 1 }}
              md={{ order: 2 }}
              sm={{ order: 2 }}
              xs={{ order: 2 }}
              xl={6}
              lg={5}
            >
              <Col xl={9}>
                <strong class="banner-p">
                  Risk Assessment of offshore wind vessel operation on Protected
                  species
                </strong>
                <hr />
                <p class="banner-sub-p">
                  Vessel Risk Assessment Model quantifies the risk of a strike
                  on large whales or sea turtles by the different vessel types
                  operating to support of offshore wind energy. This model can
                  account for geospatial, temporal, and species-specific
                  parameters for any wind energy areas on the Outer Continental
                  Shelf (OCS) of the United States of America.
                </p>

                <Button variant="secondary" className="banner-btn">
                  <Link
                    to="/login"
                    style={{
                      color: "white",
                      textDecoration: "none",
                    }}
                  >
                    Get Start
                  </Link>
                </Button>
              </Col>
            </Col>

            <Col
              className="banner-heading-wrap"
              xl={4}
              lg={4}
              md={{ order: 1 }}
              sm={{ order: 1 }}
              xs={{ order: 1 }}
              xl={{ order: 2 }}
              lg={{ order: 2 }}
            >
              <Col xl={1} lg={2}>
                <strong>Vessel Risk Assessment</strong>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default GetStart;
