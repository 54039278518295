import { useNavigate } from 'react-router-dom';
import User from '../components/User';
import { useEffect } from 'react';
import { useAuth } from '../components/Provider';
import useUserInfo from '../hooks/useUserInfo';

const Users = () => {
  const { authenticated } = useAuth();
  const userInfo = useUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      navigate('/login'); // Redirect to login page if not authenticated
    }
    if (authenticated && userInfo.user_type === 'USER') {
      navigate('/map');
    }
  }, [authenticated]);
  return <>{authenticated && userInfo.user_type !== 'USER' && <User />}</>;
};

export default Users;
